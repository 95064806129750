import PropTypes from 'prop-types';
import { compose, equals, ifElse, always, prop } from 'ramda';
import { setPropTypes, defaultProps, withHandlers } from 'recompose';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { EFILING_TYPE } from '../e-filing-context';
import withDocumentTypes from '../document-types/with-document-types';
import withEFilingWizardHandlers from '../with-e-filing-wizard-handlers';

const propTypes = {
  filingType: PropTypes.oneOf(Object.keys(EFILING_TYPE)).isRequired,
  initializeFormValues: PropTypes.func.isRequired
};

const isFilingType = filingType => equals(filingType);

const buildYupSchemaFromProps = compose(
  ifElse(
    isFilingType(EFILING_TYPE.SUM),
    always(
      Yup.object().shape({
        documentTitle: Yup.string()
          .trim()
          // Do not allow document titles longer than 250 characters
          // which is what the GraphQL query supports
          .max(250, 'Cannot be longer than 250 characters')
          .required('Required'),
        documentType: Yup.object().nullable(),
        file: Yup.mixed()
          .required('Attachment file must be uploaded')
          .nullable(),
        includeProofOfService: Yup.boolean()
      })
    ),
    always(
      Yup.object().shape({
        documentTitle: Yup.object().nullable(),
        documentType: Yup.object().nullable(),
        file: Yup.mixed()
          .required('Attachment file must be uploaded')
          .nullable()
      })
    )
  ),
  prop('filingType')
);

export default compose(
  setPropTypes(propTypes),
  withDocumentTypes(({ documentTypes, loading, error }) => ({
    documentTypes,
    docTypesLoading: loading,
    docTypesError: error
  })),
  withEFilingWizardHandlers,
  withFormik({
    mapPropsToValues: ({ initializeFormValues, ...rest }) => {
      return initializeFormValues(rest);
    },
    validationSchema: buildYupSchemaFromProps,
    handleSubmit: (values, { props: { nextStep } }) => nextStep(values)
  }),
  defaultProps({ status: {}, acceptedExtensions: ['application/pdf'] }),
  withHandlers({
    onFileDialogOpen: ({ setFieldTouched }) => () => setFieldTouched('file', true),
    onFileChange: ({ setFieldValue }) => ([file]) => {
      return setFieldValue('file', file);
    }
  }),
  withHandlers({
    onSaveDraft: ({ saveEFilingDraft, isValid, validateForm, setStatus, values }) => async () => {
      validateForm();
      if (isValid) {
        await saveEFilingDraft(values);
      } else {
        setStatus({ validated: true });
      }
    }
  })
);
