import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import LineIcon from '../line-icon/line-icon';

ExpansionPanel.propTypes = {
  /**
   * The title on the panel summary.
   * @type {String}
   */
  title: PropTypes.string,

  /**
   * An optional value to display next to the title, as a round badge.
   * @type {String|Number}
   */
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * The actual content of the panel.
   * @type {ReactElement}
   */
  children: PropTypes.element.isRequired,

  /**
   * JSS classes.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired,

  /**
   * CSS classes to apply to the root element of the panel.
   * @type {String}
   */
  className: PropTypes.string,

  /**
   * If `true`, expands the panel, otherwise collapse it. Setting this prop enables control over the panel.
   * @type {Boolean}
   */
  expanded: PropTypes.bool,

  /**
   * If `true`, expands the panel by default.
   * @type {Boolean}
   */
  defaultExpanded: PropTypes.bool,

  /**
   * Callback fired when the expand/collapse state is changed.
   * @type {Function}
   */
  onChange: PropTypes.func
};

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.blue[100]
  },
  details: {
    padding: 0
  },
  badge: {
    top: '40%',
    right: -3
  }
});

const ExpansionPanelSummary = withStyles(theme => ({
  expandIcon: {
    right: 'unset',
    left: '8px'
  },
  content: {
    paddingLeft: theme.spacing.unit * 5
  }
}))(MuiExpansionPanelSummary);

function ExpansionPanel({
  title,
  badge,
  children,
  classes,
  className,
  expanded,
  defaultExpanded,
  onChange
}) {
  return (
    <MuiExpansionPanel
      className={cn(className, classes.root)}
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={onChange}
    >
      <ExpansionPanelSummary
        classes={classes.summary}
        expandIcon={<LineIcon icon="chevron-circle-up" />}
      >
        <Badge badgeContent={badge} color="primary" classes={{ badge: classes.badge }}>
          <Typography variant="subtitle1">{title}</Typography>
        </Badge>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>{children}</ExpansionPanelDetails>
    </MuiExpansionPanel>
  );
}

export default memo(withStyles(styles)(ExpansionPanel));
