import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, withHandlers, pure } from 'recompose';
import cn from 'classnames';
import Checkbox from '../core/checkbox/checkbox';
import EAMSLogo from '../../img/eams-logo.png';
import DTMLogo from '../../img/direct-to-mail-logo.png';
import { EFILING_TYPE } from './e-filing-context';
import './e-filing-type.scss';

const propTypes = {
  name: PropTypes.string,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  type: PropTypes.oneOf(Object.values(EFILING_TYPE))
};

const EFilingType = ({ name, selected, type, onTypeSelected, disabled }) => (
  <div className="flex-column inline-flex items-center">
    <div
      className={cn(
        'e-filing-type b--light-gray ba pointer outline-0 flex items-center justify-center relative',
        { 'shadow-5': selected }
      )}
      role="button"
      tabIndex={0}
      onClick={disabled ? undefined : onTypeSelected}
      onKeyDown={disabled ? undefined : onTypeSelected}
    >
      <Checkbox checked={selected} className="absolute top-1 left-1" readOnly disabled={disabled} />
      {type === EFILING_TYPE.EAMS && <img src={EAMSLogo} alt="EAMS Logo" className="w5" />}
      {type === EFILING_TYPE.SUM && <img src={DTMLogo} alt="Direct-to-Mail Logo" className="w4" />}
    </div>
    <div className="b f6 mv2">{name}</div>
  </div>
);

const enhance = compose(
  setDisplayName('EFilingType'),
  setPropTypes(propTypes),
  withHandlers({
    onTypeSelected: ({ type, onSelected }) => () => onSelected && onSelected(type)
  }),
  pure
);

export default enhance(EFilingType);
