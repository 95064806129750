import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import Query from '../../core/data/query';
import isBlank from '../../../utils/is-blank';
import { ExpansionPanel } from '../../core/collapsible';
import IWPLiensTable from './iwp-liens-table';
import IWPLiensDifferencesTable from './iwp-liens-differences-table';
import wcabCasesLiensQuery from './wcab-cases-liens.graphql';

IWPLiensPanel.propTypes = {
  relatedCase: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    adjCaseNumber: PropTypes.string.isRequired
  })
};

/**
 * Extract the value of a `adjCaseNumber` property from the given `case` object.
 * If `case` is `null` or `undefined`, this function returns `undefined`.
 *
 * @function
 * @param {Object} case The case object to extract the `adjCaseNumber` from.
 * @returns {String} The `adjCaseNumber` extracted from the `case`.
 */
const adjCaseNumberOrUndefined = propOr(undefined, 'adjCaseNumber');

export function IWPLiensPanel({ relatedCase }) {
  return (
    <Query
      query={wcabCasesLiensQuery}
      variables={{ adjCaseNumber: adjCaseNumberOrUndefined(relatedCase) }}
      skip={isBlank(relatedCase)}
    >
      {({ loading, error, data: { caseLiens = [], caseLienDifferences = [] } }) => {
        return (
          <div className="bg-dark-gray ph4 pv4 flex-auto overflow-y-auto w-100">
            <ExpansionPanel title="Liens" badge={caseLiens.length} defaultExpanded={true}>
              <IWPLiensTable
                liens={caseLiens}
                error={error}
                loading={loading}
                className="pa3 bg-white w-100"
              />
            </ExpansionPanel>

            <ExpansionPanel
              title="Liens difference"
              badge={caseLienDifferences.length}
              defaultExpanded={true}
            >
              <IWPLiensDifferencesTable
                loading={loading}
                error={error}
                liensDifferences={caseLienDifferences}
                className="pa3 bg-white w-100"
              />
            </ExpansionPanel>
          </div>
        );
      }}
    </Query>
  );
}

export default memo(IWPLiensPanel);
