import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { ExpansionPanel } from '../../core/collapsible';
import Query from '../../core/data/query';
import isBlank from '../../../utils/is-blank';
import IWPEventsTable from './iwp-events-table';
import IWPParticipantsTable from './iwp-participants-table';
import IWPBodyPartsTable from './iwp-body-parts-table';
import IWPHearingsTable from './iwp-hearings-table';
import wcabCasesOverviewQuery from './wcab-cases-overview.graphql';

IWPOverviewPanel.propTypes = {
  relatedCase: PropTypes.shape({
    adjCaseNumber: PropTypes.string.isRequired
  })
};

IWPOverviewPanel.defaultProps = {
  relatedCase: {}
};

/**
 * Extract the value of a `adjCaseNumber` property from the given `case` object.
 * If `case` is `null` or `undefined`, this function returns `undefined`.
 *
 * @function
 * @param {Object} case The case object to extract the `adjCaseNumber` from.
 * @returns {String} The `adjCaseNumber` extracted from the `case`.
 */
const adjCaseNumberOrUndefined = propOr(undefined, 'adjCaseNumber');

export function IWPOverviewPanel({ relatedCase }) {
  return (
    <Query
      query={wcabCasesOverviewQuery}
      variables={{ adjCaseNumber: adjCaseNumberOrUndefined(relatedCase) }}
      skip={isBlank(relatedCase)}
    >
      {({
        loading,
        error,
        data: { caseEvents = [], caseBodyParts = [], caseHearings = [], caseParticipants = [] }
      }) => {
        return (
          <div className="bg-dark-gray ph4 pv4 flex-auto overflow-y-auto">
            <ExpansionPanel title="Events" badge={caseEvents.length} defaultExpanded={true}>
              <IWPEventsTable
                events={caseEvents}
                loading={loading}
                error={error}
                className="w-100 pa3 bg-white"
              />
            </ExpansionPanel>

            <ExpansionPanel title="Body Parts" badge={caseBodyParts.length} defaultExpanded={true}>
              <IWPBodyPartsTable
                bodyParts={caseBodyParts}
                loading={loading}
                error={error}
                className="w-100 pa3 bg-white"
              />
            </ExpansionPanel>

            <ExpansionPanel title="Hearings" badge={caseHearings.length} defaultExpanded={true}>
              <IWPHearingsTable
                hearings={caseHearings}
                loading={loading}
                error={error}
                className="w-100 pa3 bg-white"
              />
            </ExpansionPanel>

            <ExpansionPanel
              title="Participants"
              badge={caseParticipants.length}
              defaultExpanded={true}
            >
              <IWPParticipantsTable
                participants={caseParticipants}
                loading={loading}
                error={error}
                className="w-100 pa3 bg-white"
              />
            </ExpansionPanel>
          </div>
        );
      }}
    </Query>
  );
}

export default memo(IWPOverviewPanel);
