import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import DataTable from '../../core/table';

IWPLiensDifferencesTable.propTypes = {
  liensDifferences: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      explanation: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  className: PropTypes.string
};

const columns = [
  { Header: 'Lien Claimant', accessor: 'name' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Explanation', accessor: 'explanation' }
];

export function IWPLiensDifferencesTable({ liensDifferences, loading, error, className }) {
  return (
    <DataTable
      data={liensDifferences}
      loading={loading}
      className={className}
      columns={columns}
      theme="light"
      showPagination={false}
      minRows={5}
      noDataText={
        error
          ? 'There was an error retrieving liens from EAMS.'
          : loading
          ? 'Searching liens...'
          : isEmpty(liensDifferences)
          ? 'No liens differences found for selected case.'
          : ''
      }
    />
  );
}

export default memo(IWPLiensDifferencesTable);
