import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import DataTable from '../../core/table';

IWPBodyPartsTable.propTypes = {
  bodyParts: PropTypes.arrayOf(
    PropTypes.shape({
      bodyPart: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  className: PropTypes.string
};

const columns = [{ Header: 'Body Parts', accessor: 'bodyPart' }];

export function IWPBodyPartsTable({ bodyParts, loading, error, className }) {
  return (
    <DataTable
      data={bodyParts}
      loading={loading}
      className={className}
      columns={columns}
      theme="light"
      showPagination={false}
      minRows={5}
      noDataText={
        error
          ? 'There was an error retrieving body parts from EAMS.'
          : loading
          ? 'Searching body parts...'
          : isEmpty(bodyParts)
          ? 'No body parts found for selected case.'
          : ''
      }
    />
  );
}

export default memo(IWPBodyPartsTable);
