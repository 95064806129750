import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { pure, compose, setPropTypes } from 'recompose';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const enhance = compose(
  setPropTypes(propTypes),
  pure
);

const Button = enhance(({ className, children, ...rest }) => (
  <button
    className={cn(
      className,
      'flex justify-center items-center h2 f7 b lh-copy tc pointer ba br-pill ph3 pv1 outline-0 ttu dim'
    )}
    {...rest}
  >
    {children}
  </button>
));

export const DefaultButton = ({ className, ...rest }) => (
  <Button className={cn('white bg-outer-space b--outer-space', className)} {...rest} />
);

export const AlternateButton = ({ className, ...rest }) => (
  <Button className={cn('blue bg-transparent b--blue', className)} {...rest} />
);

export const WhiteButton = ({ className, ...rest }) => (
  <Button className={cn('white bg-transparent b--white', className)} {...rest} />
);
