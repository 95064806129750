import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose, setDisplayName, setPropTypes, pure, withHandlers, withProps } from 'recompose';
import { Form } from 'formik';
import { always, mergeRight, path, isEmpty } from 'ramda';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import WizardStepLayout from '../wizard-step-layout';
import Input from '../../core/input/input';
import Checkbox from '../../core/checkbox/checkbox';
import Dropzone from '../../core/file-upload/dropzone';
import DocumentTypesDropdown from '../document-types/document-types-dropdown';
import { withEFilingContextConsumer } from '../e-filing-context';
import withGeneralInfoCore from '../shared/general-info-step-core';

const propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

const styles = theme => ({
  dropdown: {
    marginBottom: 23
  }
});

const fromContextOrDefault = compose(
  mergeRight({
    caseId: undefined,
    documentTitle: '',
    documentType: undefined,
    includeProofOfService: false,
    file: undefined
  }),
  path(['eFilingContext', 'state'])
);

function GeneralInfoStep({
  title,
  onSaveDraft,
  onPreviousStep,
  onCancel,
  currentStep,
  stepsCount,
  stepError,
  selectedRelatedCase,
  loading,
  acceptedExtensions,
  onFileChange,
  onFileRejected,
  errors,
  status,
  touched,
  handleChange,
  handleBlur,
  submitForm,
  setFieldValue,
  documentTypes,
  docTypesLoading,
  classes,
  values: { documentTitle, documentType, includeProofOfService, file }
}) {
  return (
    <WizardStepLayout
      error={stepError}
      loading={loading}
      title={title}
      showCancelButton={false}
      stepNumber={currentStep}
      stepsCount={stepsCount}
      onCancel={onCancel}
      onBack={onPreviousStep}
      onNext={submitForm}
      onSaveDraft={onSaveDraft}
    >
      <div className="flex flex-column flex-grow-1">
        <div className="flex justify-between h-50 h-25-m">
          <Input
            label="ADJ Number"
            readOnly
            className="mr3"
            value={selectedRelatedCase.adjCaseNumber}
          />
          <Input
            label="First Name"
            readOnly
            className="mr3"
            value={selectedRelatedCase.firstName}
          />
          <Hidden smDown>
            <Input label="MI" readOnly className="mr3" value="-" />
          </Hidden>
          <Input label="Last Name" readOnly value={selectedRelatedCase.lastName} />
        </div>
        <Divider className="mb3" />
        <div>
          <Typography variant="subtitle1" className="mb3">
            Select category and title
          </Typography>
          <Form noValidate autoComplete="off">
            <div className="flex justify-around">
              <div className="flex flex-column flex-row-ns items-center w-100">
                <DocumentTypesDropdown
                  id="e-filing-documentType"
                  className={cn('w-100 mr5-ns', classes.dropdown)}
                  documentTypes={documentTypes}
                  loading={docTypesLoading}
                  label="Document Type"
                  name="documentType"
                  value={documentType}
                  onChange={item => {
                    return setFieldValue('documentType', isEmpty(item) ? undefined : item);
                  }}
                  onBlur={handleBlur}
                  error={(touched.documentType || status.validated) && errors.documentType}
                />
                <Input
                  name="documentTitle"
                  label="Document Title"
                  value={documentTitle}
                  onChange={handleChange}
                  formControlProps={{
                    required: true,
                    fullWidth: true
                  }}
                  onBlur={handleBlur}
                  error={(touched.documentTitle || status.validated) && errors.documentTitle}
                />
              </div>
            </div>
            <Checkbox
              checked={includeProofOfService}
              name="includeProofOfService"
              label="Include a Proof of Service"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form>
        </div>
        <Divider className="mb3" />
        <Dropzone
          className="min-h4 h-100 w-100"
          name="file"
          value={file}
          accept={acceptedExtensions}
          onDropRejected={onFileRejected}
          onDrop={onFileChange}
          onBlur={handleBlur}
          error={(touched.file || status.validated) && errors.file}
        />
      </div>
    </WizardStepLayout>
  );
}

const enhance = compose(
  setDisplayName(GeneralInfoStep.name),
  setPropTypes(propTypes),
  withEFilingContextConsumer(context => ({ eFilingContext: context })),
  withProps(({ eFilingContext }) => {
    return {
      filingType: eFilingContext.state.type,
      initializeFormValues: fromContextOrDefault
    };
  }),
  withGeneralInfoCore,
  withProps(
    ({
      wizardContext: {
        state: { currentStep, stepsCount, error, loading }
      },
      IWProfileContext: {
        state: { selectedRelatedCase }
      }
    }) => {
      return {
        currentStep: currentStep + 1,
        stepsCount: stepsCount - 1,
        selectedRelatedCase,
        stepError: error,
        loading
      };
    }
  ),
  withHandlers({
    onPreviousStep: ({ eFilingContext: { updateContext: updateEFilingContext } }) => () => {
      // Clean the selected type so the selection step is shown
      updateEFilingContext(always({ type: null }));
    }
  }),
  withStyles(styles),
  pure
);

export default enhance(GeneralInfoStep);
