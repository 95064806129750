import React from 'react';
import PropTypes from 'prop-types';
import { withState, withHandlers, setPropTypes, defaultProps, setDisplayName } from 'recompose';
import { is, compose } from 'ramda';
import cn from 'classnames';
import SplitPane from '../../components/core/split-pane/split-pane';
import { getWindowHeight } from '../../utils/dom-helpers';
import './resizable-panels.scss';

ResizablePanels.propTypes = {
  resizing: PropTypes.bool,
  panelSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onDragStarted: PropTypes.func,
  onDragFinished: PropTypes.func,
  onResizerDoubleClick: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

function ResizablePanels({
  resizing,
  panelSize,
  onDragStarted,
  onDragFinished,
  onResizerDoubleClick,
  children
}) {
  return (
    <SplitPane
      className={cn({
        'disable-pointer-events': resizing
      })}
      split="horizontal"
      defaultSize="65vh"
      size={panelSize}
      maxSize={-55}
      minSize={5}
      onDragStarted={onDragStarted}
      onDragFinished={onDragFinished}
      onResizerDoubleClick={onResizerDoubleClick}
    >
      {children[0]}
      {children[1]}
    </SplitPane>
  );
}

const panelSizeInPx = panelSize => {
  if (is(Number, panelSize)) return panelSize;
  if (is(String, panelSize)) {
    const vhPercentage = panelSize.match(/(\d*)vh/)[1];
    return (vhPercentage * getWindowHeight()) / 100;
  }
  return NaN;
};

const enhance = compose(
  setPropTypes({
    panelSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onPanelSizeChanged: PropTypes.func.isRequired
  }),
  setDisplayName(ResizablePanels.name),
  defaultProps({ panelSize: '65vh' }),
  withState('resizing', 'setResizing', false),
  withHandlers({
    onDragStarted: ({ setResizing }) => () => setResizing(true),
    onDragFinished: ({ setResizing, onPanelSizeChanged }) => draggedSize => {
      setResizing(false);
      onPanelSizeChanged(draggedSize);
    },
    onResizerDoubleClick: ({ panelSize, onPanelSizeChanged }) => () => {
      const windowHeight = getWindowHeight();
      const currentPanelSize = panelSizeInPx(panelSize);
      onPanelSizeChanged(currentPanelSize > windowHeight / 2 ? '0.7vh' : '65vh');
    }
  })
);

export default enhance(ResizablePanels);
