import React, { memo } from 'react';
import filingsQuery from '../../components/e-filing/document-submissions/filings.graphql';
import DocumentSubmissionsTable from '../../components/e-filing/document-submissions/document-submissions-table';
import Query from '../../components/core/data/query';

export function FilingSubmissionsTable() {
  return (
    <Query query={filingsQuery}>
      {({ loading, error, data }) => {
        return (
          <DocumentSubmissionsTable
            submissions={data.filings}
            loading={loading}
            noDataText={
              error
                ? 'There was an error fetching submissions'
                : loading
                ? 'Fetching submissions...'
                : 'No filings or submissions here yet'
            }
          />
        );
      }}
    </Query>
  );
}

export default memo(FilingSubmissionsTable);
