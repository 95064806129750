import React, { Suspense, lazy, memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { prop } from 'ramda';
import PrivateRoute from './components/auth/private-route';
import withAuthentication from './components/auth/with-authentication';
import AppLayout from './components/common/app-layout';
import MyCasesPage from './pages/my-cases/my-cases-page';
import SearchCasesPage from './pages/search-cases/search-cases-page';
import FilingSubmissionsPage from './pages/filing-submissions/filing-submissions-page';
import ResetPasswordPage from './pages/authentication/reset-password-page';
import { withIWProfileContextProvider } from './components/injured-worker-profile/iwp-context';
import { withFiltersContextProvider } from './components/cases';

// Lazy loaded routes will be fetched when user navigates to their respective routes
// TODO: Lazy load all pages except MyCasesPage
const LoginPage = lazy(() => import('./pages/authentication/login-page'));
const LogoutPage = lazy(() => import('./pages/authentication/logout-page'));

// FIXME: Unimplemented (empty) modules were removed as requested
// @see https://pragmars.atlassian.net/browse/WEB-234
const links = [
  { label: 'EAMS & EDEX Search', path: '/search' },
  { label: 'My Cases', path: '/mycases' },
  { label: 'Filings & Submissions', path: '/filings' }
];

const IWPContextProvider = withIWProfileContextProvider(prop('children'));
const FiltersContextProvider = withFiltersContextProvider(prop('children'));
const IWPFilingContextProvider = withIWProfileContextProvider(prop('children'));

function App({ isAuthenticated }) {
  return (
    <Suspense fallback={<span>Loading...</span>}>
      <Switch>
        <Route
          exact
          path="/login"
          render={props =>
            isAuthenticated() ? (
              <Redirect to={{ pathname: '/mycases', from: props.location }} />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route exact path="/logout" component={LogoutPage} />
        <PrivateRoute
          exact
          path="/password"
          render={() => (
            <AppLayout links={links}>
              <ResetPasswordPage />
            </AppLayout>
          )}
        />
        <PrivateRoute
          exact
          path="/mycases"
          render={() => (
            <AppLayout links={links}>
              <IWPContextProvider>
                <FiltersContextProvider>
                  <MyCasesPage />
                </FiltersContextProvider>
              </IWPContextProvider>
            </AppLayout>
          )}
        />
        <PrivateRoute
          exact
          path="/search"
          render={() => (
            <AppLayout links={links}>
              <IWPContextProvider>
                <FiltersContextProvider>
                  <SearchCasesPage />
                </FiltersContextProvider>
              </IWPContextProvider>
            </AppLayout>
          )}
        />
        <PrivateRoute
          exact
          path="/filings"
          render={() => (
            <AppLayout links={links}>
              <IWPFilingContextProvider>
                <FilingSubmissionsPage />
              </IWPFilingContextProvider>
            </AppLayout>
          )}
        />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
}

export default withAuthentication(memo(App));
