import React, { memo } from 'react';
import FilingSubmissionsTable from './filing-submissions-table';
import PageHeader from '../../components/common/page-header';

export function FilingSubmissionsPage() {
  return (
    <div className="flex flex-column items-stretch">
      <PageHeader breadcrumbs="Home / Filings & Submissions" label="Filings & Submissions" />
      <div className="w-100 relative overflow-x-hidden ph3 pb3 pt4">
        <FilingSubmissionsTable className="react-table" />
      </div>
    </div>
  );
}

export default memo(FilingSubmissionsPage);
