import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { prop, not } from 'ramda';
import { compose, pure, branch, renderNothing, setPropTypes, setDisplayName } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  caret: {
    transition: 'all 150ms ease-in',
    color: theme.palette.primary.lightText,
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    transform: ({ open }) => (open ? 'rotate(180deg)' : '')
  }
});

MenuCaret.propTypes = {
  /**
   * JSS custom classes.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired,

  /**
   * Whether to show this caret or not.
   * @type {Boolean}
   */
  show: PropTypes.bool
};

function MenuCaret({ classes, show, ...rest }) {
  return <b {...rest} className={cn(classes.caret, 'dib w0 h0 ml1 v-mid')} />;
}

const shouldHideCaret = compose(
  not,
  Boolean,
  prop('show')
);

export default compose(
  setDisplayName('MenuCaret'),
  branch(shouldHideCaret, renderNothing),
  withStyles(styles),
  setPropTypes({
    show: PropTypes.bool
  }),
  pure
)(MenuCaret);
