import { compose, withHandlers } from 'recompose';
import { mergeLeft, adjust } from 'ramda';
import { graphql } from 'react-apollo';
import filingsQuery from './document-submissions/filings.graphql';
import submitDraftMutation from './submit-filing.graphql';
import { findIndexById } from '../../utils/find';

export default compose(
  graphql(submitDraftMutation, { name: 'submitDraftMutation' }),
  withHandlers({
    submitDraft: ({ submitDraftMutation }) => async filingId => {
      const {
        data: { submitFilingDraft }
      } = await submitDraftMutation({
        variables: { filingId },
        update: (
          proxy,
          {
            data: {
              submitFiling: { id, status }
            }
          }
        ) => {
          const data = proxy.readQuery({ query: filingsQuery });
          const submittedDocumentIndex = findIndexById(id, data.filings);

          return proxy.writeQuery({
            query: filingsQuery,
            data: {
              // Update document submissions so it reflects the
              // change of status (from DRAFT -> PENDING)
              filings: adjust(submittedDocumentIndex, mergeLeft({ status }), data.filings)
            }
          });
        }
      });

      return submitFilingDraft;
    }
  })
);
