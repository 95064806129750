import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

FadingLinearProgress.propTypes = {
  /**
   * Whether to show this linear progress bar or not.
   * @type {Boolean}
   */
  when: PropTypes.bool,

  /**
   * Time in milliseconds to wait before showing this progress bar.
   * @default 800
   * @type {Number}
   */
  transitionDelay: PropTypes.number,

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @see https://material-ui.com/api/linear-progress/#linearprogress
   * @type {String}
   */
  color: PropTypes.oneOf(['primary', 'secondary'])
};

FadingLinearProgress.defaultProps = {
  transitionDelay: 200,
  color: 'secondary'
};

function FadingLinearProgress({ when, className, transitionDelay, color, ...rest }) {
  return (
    <div className={className}>
      <Fade
        in={when}
        style={{
          transitionDelay: when ? `${transitionDelay}ms` : '0ms'
        }}
        unmountOnExit
      >
        <LinearProgress color={color} {...rest} />
      </Fade>
    </div>
  );
}

export default pure(FadingLinearProgress);
