import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import LineIcon from '../line-icon/line-icon';

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)',
    margin: '2px',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  margin: {
    margin: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px 0 0`
  },
  root: {
    backgroundColor: theme.palette.blue[100]
  }
});

class Collapsible extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.element,
    className: PropTypes.string,
    classes: PropTypes.object
  };

  state = { expanded: true };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { title, children, className, classes, badge } = this.props;
    const expanded = this.state.expanded;

    return (
      <div className={cn(classes.root, className)}>
        <span className="flex items-center">
          <IconButton
            onClick={this.handleExpandClick}
            className={cn(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            aria-label="Show more"
          >
            <LineIcon icon="chevron-circle-up" />
          </IconButton>
          <Badge badgeContent={badge} color="primary" className={classes.margin}>
            <Typography className={classes.padding} variant="subtitle1">
              {title}
            </Typography>
          </Badge>
        </span>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(Collapsible);
