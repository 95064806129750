import React from 'react';
import PropTypes from 'prop-types';
import {
  pure,
  compose,
  withProps,
  withHandlers,
  setDisplayName,
  defaultProps,
  withState
} from 'recompose';
import {
  useWith,
  intersection,
  pluck,
  map,
  keys,
  filter,
  unapply,
  unless,
  is,
  any,
  always,
  omit,
  when,
  partition,
  propSatisfies,
  flip,
  contains
} from 'ramda';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Input from '../../core/input/input';
import Button from '../../core/button/mui-button';
import LineIcon from '../../core/line-icon';
import isNotEmpty from '../../../utils/is-not-empty';
import isNotBlank from '../../../utils/is-not-blank';
import isBlank from '../../../utils/is-blank';
import { withFiltersContextConsumer } from './cases-filters-context';
import inputs from './cases-filters-inputs';
import evolveFilters from './evolve-filters';

CaseFilterForm.propTypes = {
  /**
   * @type {Function}
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Array of inputs IDs to exclude in the form.
   * @type {Array.<String>}
   */
  exclude: PropTypes.arrayOf(PropTypes.string)
};

CaseFilterForm.defaultProps = {
  exclude: []
};

/**
 * Make any empty or `null` values become `undefined`.
 *
 * @function
 * @example
 *  mapBlankToUndefined({ foo: null, bar: '' }) // { foo: undefined, bar: undefined }
 *
 * @param {Array.<Object>|Object} value The list or object to map values from
 * @returns {Array.<Object>|Object} The original `value` with all of its `null`
 *  or empty values replaced with `undefined`
 */
const mapBlankToUndefined = map(when(isBlank, always(undefined)));

const partitionById = (filterable, items) => {
  return partition(propSatisfies(flip(contains)(items), 'id'), filterable);
};

const intersectionNotEmptyById = useWith(
  compose(
    isNotEmpty,
    intersection
  ),
  [
    pluck('id'),
    compose(
      keys,
      filter(isNotBlank)
    )
  ]
);

/**
 * @function
 */
const anyNotBlank = unapply(any(isNotBlank));
const isDisabled = props => unless(is(Boolean), isDisabled => isDisabled(props));

const validationSchema = Yup.object().shape(
  {
    adjCaseNumber: Yup.number().when(
      ['ssn', 'firstName', 'lastName', 'doi', 'dob', 'employer', 'city', 'zip'],
      {
        // If any of the other filters are defined, make `adjCaseNumber` optional
        is: anyNotBlank,
        then: Yup.number()
          .nullable(true)
          .positive('Must be a valid ADJ')
          .integer()
          .typeError('Must be a valid ADJ')
          .notRequired(),
        otherwise: Yup.number()
          .positive('Must be a valid ADJ')
          .integer()
          .typeError('Must be a valid ADJ')
          .required('Required')
      }
    ),
    ssn: Yup.string().when(
      ['adjCaseNumber', 'firstName', 'lastName', 'doi', 'dob', 'employer', 'city', 'zip'],
      {
        is: anyNotBlank,
        then: Yup.string()
          .nullable(true)
          .notRequired(),
        otherwise: Yup.string()
          .trim()
          .matches(/^\d{3}-?\d{2}-?\d{4}$/, 'Must be a valid SSN')
          .required('Required')
      }
    ),
    firstName: Yup.string().when(['adjCaseNumber', 'ssn'], {
      is: anyNotBlank,
      then: Yup.string()
        .trim()
        .nullable()
        .notRequired(),
      otherwise: Yup.string()
        .trim()
        .required('Required')
    }),
    lastName: Yup.string().when(['adjCaseNumber', 'ssn'], {
      is: anyNotBlank,
      then: Yup.string()
        .trim()
        .nullable()
        .notRequired(),
      otherwise: Yup.string()
        .trim()
        .required('Required')
    }),
    doi: Yup.date(),
    dob: Yup.date(),
    employer: Yup.string()
      .trim()
      .min(2, 'Too short'),
    city: Yup.string()
      .trim()
      .min(2, 'Too short'),
    zip: Yup.string()
      .trim()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
  },
  [
    ['adjCaseNumber', 'ssn'],
    ['firstName', 'adjCaseNumber'],
    ['firstName', 'ssn'],
    ['lastName', 'adjCaseNumber'],
    ['lastName', 'ssn']
  ]
);

function InputSet({ className, inputs, addInputRefs, values, errors, handleChange, handleBlur }) {
  return (
    <div className={className}>
      {inputs.map(
        ({ id, label, component, fixedLabel, disabled, required, type, placeholder }, index) => (
          <Input
            className="mt3"
            key={`${id}-${index}`}
            id={id}
            name={id}
            placeholder={placeholder}
            label={label}
            value={values[id] || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isDisabled(values)(disabled)}
            type={type}
            inputRef={addInputRefs}
            error={errors[id]}
            inputComponent={component}
            labelProps={{
              shrink: fixedLabel
            }}
            formControlProps={{
              required,
              fullWidth: true
            }}
          />
        )
      )}
    </div>
  );
}

function CaseFilterForm({ defaultFilters, inputs, addInputRefs, onSubmit, onReset }) {
  return (
    <Formik
      enableReinitialize
      validateOnChange
      validationSchema={validationSchema}
      initialValues={defaultFilters}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {({ values, errors, handleBlur, handleChange, handleReset, isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <fieldset className="bw0">
            <InputSet
              inputs={inputs.primary}
              values={values}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              addInputRefs={addInputRefs}
            />
            <ExpansionPanel
              defaultExpanded={intersectionNotEmptyById(inputs.secondary, values)}
              className="mb3 mt4"
              elevation={0}
            >
              <ExpansionPanelSummary expandIcon={<LineIcon icon="chevron-circle-down" />}>
                <Typography>Show more filters</Typography>
              </ExpansionPanelSummary>
              <InputSet
                inputs={inputs.secondary}
                values={values}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                addInputRefs={addInputRefs}
              />
            </ExpansionPanel>
          </fieldset>
          <div className="flex justify-around items-center h3">
            <Button type="reset" color="secondary" variant="outlined" onClick={handleReset}>
              Clear
            </Button>
            <Button type="submit" disabled={isSubmitting} color="primary">
              Search
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default compose(
  setDisplayName(CaseFilterForm.name),
  withFiltersContextConsumer(context => ({ filtersContext: context })),
  withState('inputRefs', 'setInputRefs', {}),
  defaultProps({ exclude: [], primary: [] }),
  withProps(({ exclude, primary, filtersContext }) => {
    const currentInputs = inputs.filter(i => !exclude.includes(i.id));
    const [primaryInputs, secondaryInputs] = partitionById(currentInputs, primary);
    return {
      inputs: {
        primary: primaryInputs,
        secondary: secondaryInputs
      },
      defaultFilters: omit(exclude, filtersContext.state)
    };
  }),
  withHandlers({
    addInputRefs: ({ setInputRefs }) => inputRef => {
      return setInputRefs(inputRefs => ({
        ...inputRefs,
        [inputRef.name]: inputRef
      }));
    },
    onSubmit: ({ onSubmit, filtersContext: { updateContext } }) => async (
      values,
      actions,
      ...args
    ) => {
      try {
        // Coerce empty, `null` or `undefined` values to `undefined` so they are
        // ignored in the context
        const filters = mapBlankToUndefined(values);
        updateContext(always(filters));

        // Return a more "friendly" version of the filters that
        // do not contain `undefined` values, dates are passed as epoch time
        // and `adjCaseNumber` is prefixed with `ADJ`
        return await onSubmit(evolveFilters(filters), ...args);
      } finally {
        actions.setSubmitting(false);
      }
    },
    onReset: ({ inputRefs, filtersContext: { updateContext } }) => values => {
      // Re-enable `adjCaseNumber` input if it was disabled
      // and focus on it before clearing the form
      inputRefs.adjCaseNumber.disabled = false;
      inputRefs.adjCaseNumber.focus();
      return updateContext(() => map(always(undefined), values));
    }
  }),
  pure
)(CaseFilterForm);
