import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure, withHandlers, withState } from 'recompose';
import Typography from '@material-ui/core/Typography';
import { withEFilingContextConsumer, EFILING_TYPE } from './e-filing-context';
import WizardStepLayout from './wizard-step-layout';
import EFilingType from './e-filing-type';

const propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

function EFilingTypeSelectionStep({ title, selectedType, selectType, nextStep, onCancel }) {
  return (
    <WizardStepLayout
      title={title}
      onCancel={onCancel}
      onNext={nextStep}
      showStepCount={false}
      showCancelButton={true}
    >
      <div className="flex flex-column flex-grow-1">
        <Typography variant="subtitle1" className="mb2">
          Select filing type
        </Typography>
        <Typography variant="body2" color="textSecondary" className="mb3">
          Please click to select the type of filing you would like for the selected case.
        </Typography>
        <div className="flex justify-around-l justify-between-m justify-between-ns mt-auto mb-auto">
          <EFilingType
            type={EFILING_TYPE.EAMS}
            name="EAMS E-Filing"
            selected={selectedType === EFILING_TYPE.EAMS}
            onSelected={selectType}
          />
          <EFilingType
            type={EFILING_TYPE.SUM}
            name="Direct-to-Mail"
            selected={selectedType === EFILING_TYPE.SUM}
            onSelected={selectType}
          />
        </div>
      </div>
    </WizardStepLayout>
  );
}

const enhance = compose(
  setDisplayName('EFilingTypeSelection'),
  setPropTypes(propTypes),
  withState('selectedType', 'setSelectedType', EFILING_TYPE.EAMS),
  withEFilingContextConsumer(context => ({ eFilingContext: context })),
  withHandlers({
    nextStep: ({ selectedType, eFilingContext: { updateContext } }) => () =>
      updateContext(() => ({ type: selectedType })),
    selectType: ({ setSelectedType }) => type => setSelectedType(type)
  }),
  pure
);

export default enhance(EFilingTypeSelectionStep);
