import React from 'react';
import PropTypes from 'prop-types';
import {
  pure,
  compose,
  withHandlers,
  withProps,
  withState,
  defaultProps,
  setPropTypes
} from 'recompose';
import Button from '@material-ui/core/Button';
import withAlert from '../alert/with-alert';
import LineIcon from '../line-icon/line-icon';
import withCsvExport from '../../csv/with-csv-export';

const propTypes = {
  keyField: PropTypes.string
};

const isCSVExportableColumn = c => typeof c.Header === 'string' && Boolean(c._csvExportable);

const buildCSVHeaders = cols =>
  cols.filter(isCSVExportableColumn).map(c => ({ label: c.Header, field: c.accessor }));

const filterCasesToBeCSVExported = (data, keyField, selectedIDs) =>
  selectedIDs && selectedIDs.size > 0 ? data.filter(d => selectedIDs.has(d[keyField])) : [...data];

const enhance = compose(
  setPropTypes(propTypes),
  defaultProps({
    keyField: 'id',
    separator: ',',
    csvFilename: 'CSV_data.csv'
  }),
  withProps(({ columns, data, keyField, selectedIDs }) => ({
    csvData: filterCasesToBeCSVExported(data, keyField, selectedIDs),
    csvHeaders: buildCSVHeaders(columns)
  })),
  withState('messageText', 'setMessageText', ''),
  withState('showMessage', 'setShowMessage', false),
  withCsvExport,
  withHandlers({
    onOkClick: ({
      exportCSV,
      csvHeaders,
      csvData,
      separator,
      csvFilename,
      setShowMessage
    }) => () => {
      exportCSV(csvHeaders, csvData, { separator, filename: csvFilename });
      setShowMessage(false);
    },
    onCancelClick: ({ setShowMessage }) => () => setShowMessage(false),
    onExportCSV: ({
      csvData,
      csvHeaders,
      exportCSV,
      separator,
      csvFilename,
      setMessageText,
      setShowMessage,
      selectedIDs
    }) => () => {
      if (selectedIDs && selectedIDs.size !== 0) {
        setMessageText(`You are about to export ${selectedIDs.size} rows.`);
        setShowMessage(true);
      } else {
        exportCSV(csvHeaders, csvData, { separator, filename: csvFilename });
      }
    }
  }),
  withAlert(({ showMessage, onOkClick, messageText }) => ({
    showMessage,
    onOkClick,
    messageText,
    messageType: 'warning'
  })),
  pure
);

const exportableTable = BaseComponent =>
  enhance(({ data, columns, csvData, csvHeaders, csvFilename, onExportCSV, ...rest }) => (
    <div>
      <div className="flex justify-start mb1">
        <Button aria-label="Print" color="secondary" className="mr3">
          <LineIcon size="lg" className="mr2" icon="print" />
          <span className="pt1">Print</span>
        </Button>
        <Button aria-label="Export" color="secondary" onClick={onExportCSV}>
          <LineIcon size="lg" className="mr2" icon="external-link" />
          <span className="pt1">Export</span>
        </Button>
      </div>
      <BaseComponent data={data} columns={columns} {...rest} />
    </div>
  ));

export default exportableTable;
