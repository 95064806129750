import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { compose, branch, renderComponent, setPropTypes } from 'recompose';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Input from './input';
import LineIcon from '../line-icon';

/**
 * Displays either an "eye" icon or an "eye-slash" icon based
 * on a `slash` boolean property.
 */
const EyeIcon = compose(
  setPropTypes({
    /**
     * Whether to display an "eye" (`false`) or an
     * "eye-slash" (`true`) icon.
     * @type {Boolean}
     */
    slash: PropTypes.bool
  }),
  branch(
    prop('slash'),
    renderComponent(() => <LineIcon icon="eye-slash" />),
    renderComponent(() => <LineIcon icon="eye" />)
  )
)();

/**
 * Renders an HTML input meant to be used for requesting
 * passwords. Contains an action icon to reveal/hide its contents.
 * You don't need to manually set a `type` prop when using this component.
 *
 * @see https://material-ui.com/api/input/#props
 * @class PasswordInput
 * @extends {PureComponent}
 */
class PasswordInput extends PureComponent {
  state = {
    showPassword: false
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    return this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    return (
      <Input
        {...this.props}
        type={this.state.showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment className="h-100" position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={this.handleClickShowPassword}
              onMouseDown={this.handleMouseDownPassword}
            >
              <EyeIcon slash={this.state.showPassword} />
            </IconButton>
          </InputAdornment>
        }
      />
    );
  }
}

export default PasswordInput;
