import PropTypes from 'prop-types';
import { map } from 'ramda';
import { compose, setDisplayName, setPropTypes, pure, withProps } from 'recompose';
import Dropdown from '../../core/dropdown';

const propTypes = {
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  loading: PropTypes.bool
};

const createDropdownOptions = map(docType => ({ value: docType, label: docType.name }));

const enhance = compose(
  setDisplayName('DocumentTypesDropdown'),
  setPropTypes(propTypes),
  withProps(({ documentTypes, loading, value, onChange }) => {
    return {
      placeholder: 'None',
      isLoading: loading,
      options: createDropdownOptions(documentTypes)
    };
  }),
  pure
);

export default enhance(Dropdown);
