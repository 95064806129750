import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { assoc, propEq, map, when } from 'ramda';
import Dialog from '../core/alert/dialog';
import Mutation from '../core/data/mutation';
import addUserCaseMutation from './add-user-case.graphql';
import searchCasesQuery from './search-cases.graphql';
import myCasesQuery from './my-cases.graphql';
import { FiltersConsumer } from './cases-filters/cases-filters-context';
import evolveFilters from './cases-filters/evolve-filters';

AddUserCaseDialog.propTypes = {
  /**
   * Whether to display this alert or not.
   * @type {Boolean}
   */
  open: PropTypes.bool,

  /**
   * Callback fired when the dialog requests to be closed. Receives the
   * event source of the callback.
   * @type {Function}
   */
  onClose: PropTypes.func,

  /**
   * Callback fired when the "CANCEL" option/button is clicked on.
   * Receives the event source of the callback.
   * @type {Function}
   */
  onCancel: PropTypes.func,

  /**
   * Callback fired after adding a case to the user instance ("My Cases").
   * @type {Function}
   */
  onCompleted: PropTypes.func,

  /**
   * Callback fired in the event of an error in adding case. Receives the GraphQL error instance.
   * @type {Function}
   */
  onError: PropTypes.func,

  /**
   * The EAMS/EDEX or WCAB case this dialog should refer to. This is the case instance about to
   * be added to "My Cases".
   * @type {Object}
   */
  wcabOrSearchCase: PropTypes.shape({
    __typename: PropTypes.string,
    adjCaseNumber: PropTypes.string
  }),

  /**
   * Case related to `wcabOrSearchCase` which may have been used to fetch `wcabOrSearchCase` in
   * the first place. This is required only if the case being added to "My Cases" was fetched using
   * the ADJ case number of the related case.
   * @type {Object}
   */
  relatedCase: PropTypes.shape({
    adjCaseNumber: PropTypes.string
  }),

  /**
   * Markup or string composing the body of the alert.
   * @type {React.ReactNode}
   */
  children: PropTypes.node
};

AddUserCaseDialog.defaultProps = {
  open: false,
  wcabOrSearchCase: {},
  relatedCase: {}
};

/**
 * Checks whether the given `case` object descriptor has a `SearchCaseInfo` `__typename`.
 *
 * @function
 * @param {Object} case The case to inspect.
 * @returns {Boolean} `true` if `case` is a `SearchCaseInfo` case; `false`, otherwise.
 */
const isSearchCase = propEq('__typename', 'SearchCaseInfo');

/**
 * Updates all items in a list of search `cases` that have a matching
 * `adjCaseNumber` by adding or replacing an `owned` property set to
 * the provided value.
 *
 * @param {String} adjCaseNumber The ADJ case number to filter by
 * @param {Boolean} owned The value that should be set as `owned`
 * @param {Array} cases A list of cases to update
 * @returns {Array} A new, updated list of cases
 */
const updateOwnedProp = (adjCaseNumber, owned, cases) =>
  map(when(propEq('adjCaseNumber', adjCaseNumber), assoc('owned', owned)), cases);

function AddUserCaseDialog({
  open,
  onClose,
  onCancel,
  onError,
  onCompleted,
  wcabOrSearchCase,
  relatedCase,
  children
}) {
  return (
    <FiltersConsumer>
      {({ state: filters }) => {
        return (
          <Mutation
            onError={onError}
            onCompleted={onCompleted}
            mutation={addUserCaseMutation}
            variables={{ id: wcabOrSearchCase.id }}
            update={(
              proxy,
              {
                data: {
                  addUserCase: { adjCaseNumber, owned }
                }
              }
            ) => {
              // If the case that was just added came from an Edex/EAMS search...
              const { query, variables, queryName } = isSearchCase(wcabOrSearchCase)
                ? // ...update cache from `search-cases.graphql` query, extracting variables from the filters menu
                  // (add case event came from clicking a "+"" sign on the grid)
                  {
                    query: searchCasesQuery,
                    variables: evolveFilters(filters),
                    queryName: 'searchCases'
                  }
                : // ... otherwise, it was added from the IWP header, which means it came from a `my-cases.graphql`
                  // query with a `publicSearch: true` filter
                  {
                    query: myCasesQuery,
                    variables: {
                      adjCaseNumber: relatedCase.adjCaseNumber || adjCaseNumber,
                      publicSearch: true
                    },
                    queryName: 'wcabCases'
                  };
              const data = proxy.readQuery({ query, variables });
              return proxy.writeQuery({
                query,
                data: {
                  // Update all search cases with an `adjCaseNumber` that matches
                  // the one just added to "My Cases" to be shown as `owned`
                  [queryName]: updateOwnedProp(adjCaseNumber, owned, data[queryName])
                }
              });
            }}
          >
            {(addCase, { loading }) => {
              return (
                <Dialog
                  open={open}
                  keepMounted={false}
                  onClose={onClose}
                  onCancel={onCancel}
                  onAccept={addCase}
                  loading={loading}
                  type="warning"
                  title="Add to My Cases?"
                >
                  {children}
                </Dialog>
              );
            }}
          </Mutation>
        );
      }}
    </FiltersConsumer>
  );
}

export default memo(AddUserCaseDialog);
