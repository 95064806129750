import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  pure,
  setPropTypes,
  withState,
  withHandlers,
  lifecycle,
  shallowEqual
} from 'recompose';
import cn from 'classnames';
import IWPDetails from './iwp-details';
import IWPHeader from './iwp-header';
import { withIWProfileContextConsumer } from './iwp-context';
import IWPRelatedCases from './iwp-related-cases/iwp-related-cases';
import './injured-worker-profile.scss';

const propTypes = {
  currentCase: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    adjCaseNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  })
};

function InjuredWorkerProfile({
  currentCase,
  selectedRelatedCase,
  expanded,
  onExpandedChange,
  onRelatedCaseSelected
}) {
  return (
    <div
      className={cn('injured-worker-profile h-100 ttu flex flex-column', {
        'bg-dark-gray': Boolean(currentCase),
        'bg-light-blue': !Boolean(currentCase)
      })}
    >
      {currentCase ? (
        <>
          <IWPHeader
            currentCase={currentCase}
            expanded={expanded}
            onExpandedChange={onExpandedChange}
          />
          <IWPRelatedCases
            currentCase={currentCase}
            open={expanded}
            selectedRelatedCase={selectedRelatedCase}
            onRelatedCaseSelected={onRelatedCaseSelected}
            selectFirstByDefault={true}
          />
          <IWPDetails relatedCase={selectedRelatedCase} />
        </>
      ) : (
        <div className="f3 fw5 pv3 ph5 tc">NO CASE SELECTED</div>
      )}
    </div>
  );
}

const enhance = compose(
  setDisplayName(InjuredWorkerProfile.name),
  setPropTypes(propTypes),
  withIWProfileContextConsumer(context => ({ context })),
  withState('expanded', 'setExpanded', ({ context: { state: { headerExpanded } } }) =>
    Boolean(headerExpanded)
  ),
  withState(
    'selectedRelatedCase',
    'setSelectedRelatedCase',
    ({
      context: {
        state: { selectedRelatedCase }
      }
    }) => selectedRelatedCase
  ),
  withHandlers({
    onExpandedChange: ({ context, expanded, setExpanded }) => () => {
      setExpanded(!expanded);
      context.updateContext(() => ({
        headerExpanded: !expanded
      }));
    },
    onRelatedCaseSelected: ({
      context: { updateContext },
      setSelectedRelatedCase
    }) => selectedRelatedCase => {
      setSelectedRelatedCase(selectedRelatedCase);
      updateContext(() => ({ selectedRelatedCase }));
    }
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (!shallowEqual(this.props.currentCase, nextProps.currentCase)) {
        this.props.setSelectedRelatedCase(null);
      }
    }
  }),
  pure
);

export default enhance(InjuredWorkerProfile);
