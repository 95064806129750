import { compose, values, any } from 'ramda';
import isNotBlank from '../../../utils/is-not-blank';
import ADJNumberFormat from './adj-number-format';

const anyDefined = compose(
  any(isNotBlank),
  values
);

const INPUTS = Object.freeze([
  {
    id: 'adjCaseNumber',
    label: 'ADJ',
    disabled: ({ adjCaseNumber, ...rest }) => anyDefined(rest),
    required: true,
    component: ADJNumberFormat
  },
  {
    id: 'ssn',
    label: 'SSN',
    disabled: ({ ssn, ...rest }) => anyDefined(rest),
    required: true
  },
  {
    id: 'firstName',
    label: 'First Name',
    disabled: ({ adjCaseNumber, ssn }) => anyDefined({ adjCaseNumber, ssn }),
    required: true
  },
  {
    id: 'lastName',
    label: 'Last Name',
    disabled: ({ adjCaseNumber, ssn }) => anyDefined({ adjCaseNumber, ssn }),
    required: true
  },
  {
    id: 'dob',
    label: 'DOB',
    type: 'date',
    fixedLabel: true,
    disabled: ({ adjCaseNumber, ssn }) => anyDefined({ adjCaseNumber, ssn })
  },
  {
    id: 'doi',
    label: 'DOI',
    type: 'date',
    fixedLabel: true,
    disabled: ({ adjCaseNumber, ssn }) => anyDefined({ adjCaseNumber, ssn })
  },
  {
    id: 'city',
    label: 'City',
    disabled: ({ adjCaseNumber, ssn }) => anyDefined({ adjCaseNumber, ssn })
  },
  {
    id: 'zip',
    label: 'ZIP',
    disabled: ({ adjCaseNumber, ssn }) => anyDefined({ adjCaseNumber, ssn })
  },
  {
    id: 'employer',
    label: 'Employer Name',
    disabled: ({ adjCaseNumber, ssn }) => anyDefined({ adjCaseNumber, ssn })
  }
]);

export default INPUTS;
