import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import Select, { createFilter } from 'react-select';
import { Typography, TextField, MenuItem, Paper, withStyles } from '@material-ui/core';

Dropdown.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOf([PropTypes.bool, PropTypes.string])
};

const customFilter = createFilter({
  trim: true
});

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0
  },
  cssLabel: {
    '&$cssFocused': {
      color: theme.palette.secondary.main
    }
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.main
    }
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        classes: {
          underline: props.selectProps.classes.cssUnderline
        },
        inputProps: {
          className: cn(
            { pointer: !props.selectProps.isSearchable },
            props.selectProps.classes.input
          ),
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

function Dropdown({ id, classes, theme, className, error, label, options, ...rest }) {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  };

  return (
    <Select
      id={id}
      classes={classes}
      className={className}
      styles={selectStyles}
      options={options}
      filterOption={customFilter}
      components={components}
      isSearchable={false}
      textFieldProps={{
        error,
        label,
        InputLabelProps: {
          FormLabelClasses: {
            root: classes.cssLabel,
            focused: error ? '' : classes.cssFocused
          },
          shrink: true
        }
      }}
      {...rest}
    />
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  pure
)(Dropdown);
