import { graphql } from 'react-apollo';
import { pickBy, complement, equals } from 'ramda';
import { compose, withHandlers } from 'recompose';
import saveFilingDraftMutation from './save-filing-draft.graphql';
import filingsQuery from './document-submissions/filings.graphql';

/**
 * Checks if the given `value` is not `undefined`.
 * @param {*} value The value to check
 * @returns {Boolean} `true` if `value` is something other than `undefined`;
 *  `false`, otherwise.
 */
const isNotUndefined = complement(equals(undefined));

/**
 * Cleans an object of `undefined` values.
 *
 * @example
 *
 * removeAllUndefinedProps({ foo: undefined, bar: 42 });
 * > { bar: 42 }
 *
 * @param {Object} obj The object to pick properties from
 * @returns {Object} A new object with `undefined` properties removed
 */
const removeAllUndefinedProps = pickBy(isNotUndefined);

export default compose(
  graphql(saveFilingDraftMutation, { name: 'saveFilingDraftMutation' }),
  withHandlers({
    saveDraft: ({ saveFilingDraftMutation }) => async variables => {
      const {
        data: { saveFilingDraft }
      } = await saveFilingDraftMutation({
        // Avoid sending `undefined` properties that would NULL
        // existing values on previously saved draft
        variables: removeAllUndefinedProps(variables),
        refetchQueries: [
          {
            query: filingsQuery
          }
        ]
      });
      return saveFilingDraft;
    }
  })
);
