import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import withAuthentication from './with-authentication';

PrivateRoute.propTypes = {
  /**
   * A React component to render only when the location matches.
   * @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/Route.md#component
   * @type {ReactElement}
   */
  component: PropTypes.element,

  /**
   * A function that returns `true` if user is allowed to navigate to the current route and `false`, otherwise.
   * User will be redirected to `/login` if it can't access the resource.
   * @type {Function}
   */
  isAuthenticated: PropTypes.func.isRequired,

  /**
   * This allows for convenient inline rendering and wrapping without undesired remounting.
   * @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/Route.md#render-func
   * @type {Function}
   */
  render: PropTypes.func
};

function PrivateRoute({ component: Component, isAuthenticated, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated() ? (
          render ? (
            render(props)
          ) : (
            <Component {...rest} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', from: props.location }} />
        );
      }}
    />
  );
}

export default withAuthentication(memo(PrivateRoute));
