import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import DialogIcon from './dialog-icon';
import LineIcon from '../line-icon';
import Button from '../button/mui-button';
import LinearProgress, { Overlay } from '../loading';

AlertDialog.propTypes = {
  /**
   * Whether to display this alert or not.
   * @default false
   * @type {Boolean}
   */
  open: PropTypes.bool,

  /**
   * Type of alert to display. This determines the icon shown next to the title.
   * @type {String}
   */
  type: PropTypes.oneOf(['success', 'error', 'warning']),

  /**
   * Text or markup shown at the top of the dialog.
   * @type {React.ReactNode}
   */
  title: PropTypes.node,

  /**
   * Body of the dialog.
   * @type {React.ReactNode}
   */
  children: PropTypes.node,

  /**
   * Callback fired when the dialog requests to be closed. Receives the
   * event source of the callback.
   * @type {Function}
   */
  onClose: PropTypes.func,

  /**
   * Callback fired when the "OK" option/button is clicked on.
   * Receives the event source of the callback.
   * @type {Function}
   */
  onAccept: PropTypes.func,

  /**
   * Callback fired when the "CANCEL" option/button is clicked on.
   * Receives the event source of the callback.
   * @type {Function}
   */
  onCancel: PropTypes.func,

  /**
   * Always keep the children in the DOM. This property can be useful in SEO
   * situation or when you want to maximize the responsiveness of the Modal.
   * @see https://material-ui.com/api/modal/#props
   * @default true
   * @type {Boolean}
   */
  keepMounted: PropTypes.bool
};

AlertDialog.defaultProps = {
  open: false,
  keepMounted: true
};

const styles = {
  paper: {
    borderRadius: 0
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function AlertDialog({
  open,
  classes,
  title,
  details,
  children,
  type,
  onClose,
  onAccept,
  onCancel,
  loading,
  keepMounted
}) {
  return (
    <Dialog
      classes={classes}
      open={open}
      TransitionComponent={Transition}
      keepMounted={keepMounted}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <LinearProgress when={loading} color="secondary" className="h2" />
      <Overlay unmountOnExit={true} when={loading} className="h-100 w-100" />
      <DialogTitle id="alert-dialog-slide-title">
        <DialogIcon type={type} />
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{children}</DialogContentText>
      </DialogContent>

      {details && (
        <DialogContent>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<LineIcon icon="chevron-circle-down" />}>
              <Typography>Technical details</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <code>{details}</code>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </DialogContent>
      )}

      <DialogActions className="mr3 mb3">
        {onCancel && (
          <Button onClick={onCancel} color="secondary" variant="text">
            Cancel
          </Button>
        )}
        <Button onClick={onAccept} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default compose(
  withStyles(styles),
  pure
)(AlertDialog);
