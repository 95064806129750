/**
 * Builds a Data URI representing the CSV content of the file, encoded in UTF-8.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs}
 *
 * @param {String} data - The contents of the CSV file.
 * @return {String} A Data URI containing the CSV contents.
 */
const buildCSVURI = data => encodeURI(`data:text/csv;charset=utf-8,\uFEFF${data}`);

export default buildCSVURI;
