import { isEmpty, isNil, either } from 'ramda';

/**
 * Checks whether the given `value` is empty, `null`
 * or `undefined`. The definition for "empty"
 * depends on the type of `value`.
 *
 * @function
 * @see https://ramdajs.com/docs/#isEmpty
 * @see https://ramdajs.com/docs/#isNil
 * @param {*} value The value to check
 * @returns {Boolean} `true` if `value` is empty, `null` or
 *  `undefined`; `false`, otherwise.
 */
const isBlank = either(isEmpty, isNil);

export default isBlank;
