import React from 'react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloProvider } from 'react-apollo';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import { MuiThemeProvider, createGenerateClassName } from '@material-ui/core/styles';
import jss from './styles/jss';
import theme from './styles/theme';
import App from './app';
import authenticateHttpLink from './components/auth/auth-http-link';
import WithServiceWorker from './with-service-worker';
import '../src/styles/global.scss';

const generateClassName = createGenerateClassName();

const httpLink = createUploadLink({
  uri: '/api/graphql',
  includeExtensions: true
});

const client = new ApolloClient({
  link: authenticateHttpLink(httpLink),
  cache: new InMemoryCache()
});

const WrappedApp = (
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <MuiThemeProvider theme={theme}>
      <WithServiceWorker />
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </MuiThemeProvider>
  </JssProvider>
);

render(WrappedApp, document.getElementById('root'));
