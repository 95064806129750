import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import ReactTable from 'react-table';
import cn from 'classnames';
import TablePagination from './table-pagination';
import LinearProgress, { Overlay } from '../loading';
import 'react-table/react-table.css';
import './data-table.scss';

DataTable.propTypes = {
  // For an extensive list of all accepted props and their types
  // @see https://github.com/react-tools/react-table/blob/master/src/propTypes.js
  data: PropTypes.array,
  loading: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark'])
};

DataTable.defaultProps = {
  data: [],
  loading: false,
  theme: 'dark'
};

function LoadingComponent({ className, loading }) {
  return (
    <div
      style={{ pointerEvents: loading ? 'all' : 'none' }}
      className={cn('db absolute left-0 right-0 top-0 bottom-0', className)}
    >
      <LinearProgress when={loading} color="secondary" />
      <Overlay unmountOnExit={false} when={loading} className="relative h-100 w-100" />
    </div>
  );
}

function PaginationComponent({ data, ...rest }) {
  return <TablePagination items={data.length} {...rest} />;
}

export function DataTable({ data, className, loading, theme, ...rest }) {
  return (
    <ReactTable
      data={data}
      className={cn(`table-${theme}`, className)}
      loading={loading}
      LoadingComponent={LoadingComponent}
      PaginationComponent={PaginationComponent}
      {...rest}
    />
  );
}

// Using `React.memo` instead of `recompose/pure` here breaks
// compatibility with `recompose/toClass`
// @see https://github.com/acdlite/recompose/issues/761#issuecomment-443927653
export default pure(DataTable);
