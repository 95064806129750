import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  menuItem: {
    transition: 'all 150ms linear',
    clear: 'both',
    height: 'fit-content'
  },
  menuHeader: {
    color: theme.palette.primary.lightText,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      cursor: 'auto'
    }
  }
});

PopperMenuItem.propTypes = {
  /**
   * JSS custom classes.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired,

  /**
   * Whether to render a header item or not
   * @default false
   * @type {Boolean}
   */
  header: PropTypes.bool
};

PopperMenuItem.defaultProps = {
  header: false
};

function PopperMenuItem({ classes, header, className, ...rest }) {
  const itemStyles = cn(className, {
    'b f7 fw1 lh-title pv1 fw3': header,
    [classes.menuHeader]: header,
    'b f6 fw1 lh-solid br1 relative fw4': !header,
    [classes.menuItem]: !header
  });
  return <MenuItem className={itemStyles} {...rest} />;
}

export default compose(
  withStyles(styles),
  pure
)(PopperMenuItem);
