import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { mergeRight, compose, evolve, when, always, isNil } from 'ramda';
import { Form, Formik } from 'formik';
import Button from '../../core/button/mui-button';
import Input from '../../core/input/input';

NonCaseParticipantForm.propTypes = {
  /**
   * Callback called when current action is cancelled (i.e.: form is not submitted).
   * @type {Function}
   */
  onCancel: PropTypes.func.isRequired,

  /**
   * Callback called on form submissions. Receives the actual values being
   * submitted and filled by the user. Those values represent a mail recipient
   * containing `name`, `address`, `address2`, `city`, `state` and `province` fields.
   * @type {Function}
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Default initial recipient values for the mail recipient form.
   * @type {Object}
   */
  defaultNonCaseParticipant: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  })
};

NonCaseParticipantForm.defaultProps = {
  defaultNonCaseParticipant: {}
};

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required('Required'),
  address: Yup.string()
    .trim()
    .required('Required'),
  address2: Yup.string()
    .trim()
    .nullable(),
  city: Yup.string()
    .trim()
    .required('Required'),
  state: Yup.string()
    .required('Required')
    .trim()
    .max(2, 'Must be two characters long')
    .uppercase(),
  zip: Yup.string()
    .trim()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
    .required('Required')
});

/**
 * Set default values for a non case participant record.
 *
 * @function
 * @param {Object} nonCaseParticipant Properties here will override default values.
 * @returns {Object} The non case participant object with default values set.
 */
const toInitialNonCaseParticipantValues = compose(
  evolve({ address2: when(isNil, always('')) }),
  mergeRight({
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: ''
  })
);

function NonCaseParticipantForm({ defaultNonCaseParticipant, onCancel, onSubmit }) {
  return (
    <Formik
      enableReinitialize
      initialValues={toInitialNonCaseParticipantValues(defaultNonCaseParticipant)}
      validationSchema={validationSchema}
      onSubmit={async (values, actions, ...args) => {
        try {
          actions.setSubmitting(true);
          await onSubmit(values, ...args);

          // Reset form after successfully submitting
          actions.resetForm(toInitialNonCaseParticipantValues({}));
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleBlur, handleChange, isSubmitting }) => {
        return (
          <Form
            key={JSON.stringify(defaultNonCaseParticipant)}
            className="flex flex-column flex-wrap"
            noValidate
            autoComplete="off"
          >
            <Input
              name="name"
              label="Name"
              className="mb2"
              error={touched.name && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              formControlProps={{
                required: true,
                fullWidth: true
              }}
            />
            <Input
              name="address"
              label="Address"
              className="mb2"
              error={touched.address && errors.address}
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              formControlProps={{
                required: true,
                fullWidth: true
              }}
            />
            <Input
              name="address2"
              label="Address (line two)"
              className="mb2"
              error={touched.address2 && errors.address2}
              value={values.address2}
              onChange={handleChange}
              onBlur={handleBlur}
              formControlProps={{
                fullWidth: true
              }}
            />
            <div className="flex flex-column flex-row-ns">
              <Input
                name="city"
                label="City"
                className="mb2 mb0-ns mr5-ns"
                error={touched.city && errors.city}
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                formControlProps={{
                  required: true
                }}
              />
              <div className="flex flex-row">
                <Input
                  name="state"
                  label="State"
                  className="mb2 mb0-ns mr5"
                  error={touched.state && errors.state}
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formControlProps={{
                    required: true
                  }}
                />
                <Input
                  name="zip"
                  label="ZIP Code"
                  error={touched.zip && errors.zip}
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  formControlProps={{
                    required: true
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row justify-center items-center mt4">
              <Button
                className="mr3"
                variant="outlined"
                color="secondary"
                size="small"
                type="reset"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" size="small" disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default memo(NonCaseParticipantForm);
