import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { propEq } from 'ramda';
import cn from 'classnames';
import Query from '../../core/data/query';
import isBlank from '../../../utils/is-blank';
import CasesTable from '../../cases/cases-table';
import myCasesQuery from '../../cases/my-cases.graphql';
import './iwp-related-cases-table.scss';

IWPRelatedCasesTable.propTypes = {
  currentCase: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    adjCaseNumber: PropTypes.string.isRequired
  }).isRequired,
  onRelatedCaseSelected: PropTypes.func.isRequired,
  selectedRelatedCase: PropTypes.shape({
    adjCaseNumber: PropTypes.string.isRequired
  })
};

/**
 * Checks whether the given `case` object descriptor has a `SearchCaseInfo` `__typename`.
 *
 * @function
 * @param {Object} case The case to inspect.
 * @returns {Boolean} `true` if `case` is a `SearchCaseInfo` case; `false`, otherwise.
 */
const isSearchCase = propEq('__typename', 'SearchCaseInfo');

function IWPRelatedCasesTable({
  currentCase,
  className,
  onRelatedCaseSelected,
  selectedRelatedCase
}) {
  return (
    <Query
      query={myCasesQuery}
      variables={{
        adjCaseNumber: currentCase.adjCaseNumber,
        publicSearch: isSearchCase(currentCase)
      }}
      skip={isBlank(currentCase)}
    >
      {({ loading, error, data }) => {
        return (
          <CasesTable
            showActions={false}
            showPagination={false}
            selectFirstByDefault={true}
            className={cn('max-h-14', className)}
            cases={data.wcabCases}
            loading={loading}
            error={error}
            selectedCase={selectedRelatedCase}
            onCaseSelected={onRelatedCaseSelected}
            minRows={0}
          />
        );
      }}
    </Query>
  );
}

export default memo(IWPRelatedCasesTable);
