import PropTypes from 'prop-types';
import { withHandlers, setPropTypes, compose } from 'recompose';
import buildCSVData from './csv-data';
import buildCSVURI from './csv-uri';

const propTypes = {
  onExport: PropTypes.func
};

export default compose(
  setPropTypes(propTypes),
  withHandlers({
    exportCSV: ({ onExport }) => (headers, data, { filename, separator } = {}) => {
      const csvData = buildCSVData(headers, data, separator);
      onExport && onExport(csvData);
      const link = document.createElement('a');
      link.setAttribute('href', buildCSVURI(csvData));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  })
);
