import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import compose from 'recompose/compose';
import cn from 'classnames';
import LineIcon from '../../core/line-icon';
import { withStyles, withTheme } from '@material-ui/core/styles';

DialogIcon.propTypes = {
  /**
   * JJS custom styles.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired,
  /**
   * Material UI theme.
   * @type {Object}
   */
  theme: PropTypes.object.isRequired,
  /**
   * The type of dialog this icon is for.
   * @type {String}
   */
  type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired
};

/**
 * LineIcon icon names for each type of dialog.
 * @type {Object}
 */
const ICONS = Object.freeze({
  success: 'check-circle',
  warning: 'exclamation-triangle',
  error: 'exclamation-circle'
});

const styles = theme => ({
  lineIcon: {
    color: theme.palette.common.white
  }
});

export function DialogIcon({ theme, classes, type }) {
  return (
    <LineIcon
      icon={ICONS[type]}
      style={{
        backgroundColor: theme.palette[type].main
      }}
      className={cn(classes.lineIcon, 'f2 o-50 br-100 pa2 mr3')}
    />
  );
}

export default compose(
  withTheme(),
  withStyles(styles),
  pure
)(DialogIcon);
