import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { is } from 'ramda';
import { Mutation } from 'react-apollo';
import DataErrorAlert from './data-error-alert';

GraphQLMutation.propTypes = {
  /**
   * A function returning the UI you want to render based on your query result.
   * @see https://www.apollographql.com/docs/react/essentials/mutations.html#props
   * @type {Function}
   */
  children: PropTypes.func.isRequired,

  /**
   * A GraphQL query document parsed into an AST by graphql-tag.
   * @see https://www.apollographql.com/docs/react/essentials/mutations.html#props
   * @type {Object}
   */
  mutation: PropTypes.object.isRequired,

  /**
   * An object containing all of the variables your query needs to execute.
   * @see https://www.apollographql.com/docs/react/essentials/mutations.html#props
   * @type {Object}
   */
  variables: PropTypes.object,

  /**
   * A function used to update the cache after a mutation occurs.
   * @see https://www.apollographql.com/docs/react/essentials/mutations.html#props
   * @type {Function}
   */
  update: PropTypes.func,

  /**
   * A function that allows you to specify which queries you want to refetch after a mutation has occurred.
   * @see https://www.apollographql.com/docs/react/essentials/mutations.html#props
   * @type {Function}
   */
  refetchQueries: PropTypes.func,

  /**
   * A callback executed once your mutation completes successfully. Receives the `data` attribute
   * of the GraphQL response.
   * @see https://www.apollographql.com/docs/react/essentials/mutations.html#props
   * @type {Function}
   */
  onCompleted: PropTypes.func,

  /**
   * A callback executed in the event of an error. Receives the GraphQL error instance.
   * @see https://www.apollographql.com/docs/react/essentials/mutations.html#props
   * @type {Function}
   */
  onError: PropTypes.func,

  /**
   * Whether to avoid showing an alert on mutation error or not. It could be either
   * a boolean value or a boolean returning function. If a function is given, it
   * receives the GraphQL `error` object as its only argument,
   * @default false
   * @type{Boolean|Function}
   */
  disableAlert: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};

GraphQLMutation.defaultProps = {
  disableAlert: false
};

const callOrBoolean = (fn, ...args) => (is(Function, fn) ? fn(...args) : Boolean(fn));

/**
 * A wrapper of `react-apollo`'s `Mutation` component with error handling capabilities and a simplified API.
 * Displays an `Alert` dialog if the `error` callback property is set to a truthy value.
 * Children render function will still receive the `error` property but should not
 * directly
 * @see https://www.apollographql.com/docs/react/essentials/mutations.html#api
 */
export function GraphQLMutation({
  mutation,
  variables,
  update,
  refetchQueries,
  disableAlert,
  onCompleted,
  onError,
  children
}) {
  return (
    <Mutation
      mutation={mutation}
      variables={variables}
      refetchQueries={refetchQueries}
      update={update}
      onCompleted={onCompleted}
      onError={onError}
    >
      {(mutate, { data = {}, loading, error }) => {
        const openAlert = !callOrBoolean(disableAlert, error);
        return (
          <>
            <DataErrorAlert open={openAlert} error={error} />
            {children(mutate, { data, error, loading })}
          </>
        );
      }}
    </Mutation>
  );
}

export default memo(GraphQLMutation);
