import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  defaultProps,
  withProps,
  pure,
  branch,
  renderComponent
} from 'recompose';
import cn from 'classnames';
import { propSatisfies } from 'ramda';
import LinearProgress, { Overlay } from '../core/loading';
import LineIcon from '../core/line-icon/line-icon';
import Button from '../core/button/mui-button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import WizardStepError from './wizard-step-error';

WizardStepLayout.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  showCancelButton: PropTypes.bool,
  showStepCount: PropTypes.bool,
  stepNumber: PropTypes.number,
  stepsCount: PropTypes.number,
  onNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  defaultActionText: PropTypes.string,
  cancelActionText: PropTypes.string,
  backActionText: PropTypes.string,
  saveDraftActionText: PropTypes.string,
  onSaveDraft: PropTypes.func,
  /**
   * Disable all action controls on the wizard.
   * @type {Boolean}
   */
  disabled: PropTypes.bool
};

WizardStepLayout.defaultProps = {
  loading: false,
  showCancelButton: false,
  showStepCount: true,
  disabled: false
};

const styles = theme => ({
  progress: {
    height: 5
  },
  closeIcon: {
    color: theme.palette.grey[200]
  },
  actions: {
    backgroundColor: theme.palette.blue[100],
    margin: 0
  }
});

function WizardStepLayout({
  loading,
  title,
  children,
  showCancelButton,
  showStepCount,
  stepNumber,
  stepsCount,
  onNext,
  onCancel,
  onBack,
  defaultActionText,
  cancelActionText,
  backActionText,
  saveDraftActionText,
  disabled,
  onSaveDraft,
  classes
}) {
  return (
    <div className="flex flex-column h-100">
      <LinearProgress className={classes.progress} when={loading} />
      <Overlay className="flex flex-column h-100" when={loading}>
        <DialogTitle className="pr2" disableTypography={true}>
          <div className="flex items-center justify-between">
            <Typography variant="h6">{title}</Typography>
            <IconButton onClick={onCancel}>
              <LineIcon icon="close" className={classes.closeIcon} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="flex items-stretch">{children}</DialogContent>
        <DialogActions className={cn(classes.actions, 'pv3')} disableActionSpacing={true}>
          {showStepCount && (
            <span
              className={cn({ 'mr-auto': !onSaveDraft }, 'f3 ml3')}
            >{`${stepNumber}/${stepsCount}`}</span>
          )}
          {onSaveDraft && (
            <Button
              className="ml3 mr-auto"
              variant="outlined"
              color="secondary"
              onClick={onSaveDraft}
              disabled={disabled}
            >
              {saveDraftActionText}
            </Button>
          )}
          {showCancelButton && onCancel && (
            <Button
              className="mh2"
              onClick={onCancel}
              variant="outlined"
              color="secondary"
              disabled={disabled}
            >
              {cancelActionText}
            </Button>
          )}
          {onBack && (
            <Button
              className="mh2"
              variant="outlined"
              color="secondary"
              onClick={onBack}
              disabled={disabled}
            >
              {backActionText}
            </Button>
          )}
          {onNext && (
            <Button className="mh2" color="primary" onClick={onNext} disabled={disabled}>
              {defaultActionText}
            </Button>
          )}
        </DialogActions>
      </Overlay>
    </div>
  );
}

const enhance = compose(
  setDisplayName(WizardStepLayout.name),
  setPropTypes({
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Error)]),
    loading: PropTypes.bool,
    title: PropTypes.string,
    showCancelButton: PropTypes.bool,
    showStepCount: PropTypes.bool,
    stepNumber: PropTypes.number,
    stepsCount: PropTypes.number,
    onNext: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    onSaveDraft: PropTypes.func,
    defaultActionText: PropTypes.string
  }),
  defaultProps({
    error: false,
    defaultActionText: 'Next'
  }),
  withProps({
    backActionText: 'Back',
    cancelActionText: 'Cancel',
    saveDraftActionText: 'Save as draft'
  }),
  branch(
    // Show an error component instead of the step if `error` prop was set
    // to a truthy value
    propSatisfies(Boolean, 'error'),
    renderComponent(({ error, onCancel }) => <WizardStepError error={error} onDone={onCancel} />)
  ),
  withStyles(styles),
  pure
);

export default enhance(WizardStepLayout);
