import React from 'react';
import PropTypes from 'prop-types';
import {
  pure,
  compose,
  withProps,
  defaultProps,
  setDisplayName,
  setPropTypes,
  branch,
  lifecycle
} from 'recompose';
import { path, prop, equals, pathEq } from 'ramda';
import DataTable, { exportableTable, selectTable, BooleanCell } from '../../core/table';
import deepEqual from 'fast-deep-equal';
import isDefined from '../../../utils/is-defined';

const getRowInfoId = path(['original', 'id']);
const getSelectedCaseId = prop('id');
const areSameId = (x, y) => isDefined(x) && isDefined(y) && equals(x, y);

const propTypes = {
  cases: PropTypes.array,
  showActions: PropTypes.bool,
  onCaseSelected: PropTypes.func,
  selectFirstByDefault: PropTypes.bool,
  className: PropTypes.string,
  selectable: PropTypes.bool,
  additionalColumns: PropTypes.arrayOf(PropTypes.object)
};

const columns = Object.freeze([
  { Header: 'FIRST NAME', accessor: 'firstName', _csvExportable: true },
  { Header: 'LAST NAME', accessor: 'lastName', _csvExportable: true },
  { Header: 'CITY', accessor: 'city', _csvExportable: true },
  { Header: 'ZIP', accessor: 'zip', _csvExportable: true },
  {
    Header: 'ADJ CASE Nº',
    accessor: 'adjCaseNumber',
    _csvExportable: true
  },
  { Header: 'VENUE', accessor: 'venue', _csvExportable: true },
  { Header: 'EMPLOYER NAME', accessor: 'employer', _csvExportable: true },
  { Header: 'INJURY DATE', accessor: 'injuryDate', _csvExportable: true },
  {
    Header: 'ARCHIVED',
    accessor: 'archived',
    Cell: ({ value }) => <BooleanCell value={value} />,
    _csvExportable: true
  },
  {
    Header: 'DEU',
    accessor: 'deu',
    Cell: ({ value }) => <BooleanCell value={value} />,
    _csvExportable: true
  }
]);

const isCheckbox = pathEq(['target', 'type'], 'checkbox');

const enhance = compose(
  setPropTypes(propTypes),
  setDisplayName('CasesTable'),
  defaultProps({
    cases: [],
    showActions: true,
    selectable: true,
    additionalColumns: []
  }),
  withProps(({ cases, additionalColumns }) => ({
    columns: [...additionalColumns, ...columns],
    // The `keyField` is used by the `selectTable` HOC to identify different selectable
    // records. A single case (identified by an ADJ number) might be comprised of
    // multiple records and appear in many rows in the table. Selecting a row for a particular
    // case, should select all rows with the same ADJ number
    keyField: 'adjCaseNumber',
    data: cases,
    defaultPageSize: 10,
    rowsPerPageText: 'Cases per page',
    getTrProps: ({ selectedCase, onCaseSelected }, rowInfo) => ({
      onClick: (e, handleOriginal) => {
        if (!isCheckbox(e)) {
          onCaseSelected && onCaseSelected(rowInfo.original);
          handleOriginal && handleOriginal();
        }
      },
      className: areSameId(getRowInfoId(rowInfo), getSelectedCaseId(selectedCase))
        ? 'bg-cornflower'
        : ''
    })
  })),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (
        this.props.selectFirstByDefault &&
        nextProps.data.length > 0 &&
        !deepEqual(this.props.data, nextProps.data)
      ) {
        this.props.onCaseSelected(nextProps.data[0]);
      }
    }
  }),
  branch(
    prop('selectable'),
    compose(
      selectTable,
      lifecycle({
        componentDidUpdate(prevProps) {
          if (!deepEqual(prevProps.data, this.props.data)) {
            this.props.resetState();
          }
        }
      })
    )
  ),
  branch(prop('showActions'), exportableTable),
  pure
);

export default enhance(DataTable);
