import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, withHandlers, pure } from 'recompose';
import { isEmpty, prop, propEq, any, propSatisfies, both } from 'ramda';
import { PlusCheckCell } from '../../components/core/table';
import Query from '../../components/core/data/query';
import LineIcon from '../../components/core/line-icon';
import CasesTable from '../../components/cases/cases-table';
import AddUserCaseDialog from '../../components/cases/add-user-case-dialog';
import searchCasesQuery from '../../components/cases/search-cases.graphql';
import isDefined from '../../utils/is-defined';
import isBlank from '../../utils/is-blank';

const isResponseTooLargeError = both(
  isDefined,
  propSatisfies(any(propEq('type', 'response-too-large')), 'graphQLErrors')
);

SearchCasesTable.propTypes = {
  /**
   * Search criteria. Will be passed as `variables` to the underlying GraphQL query.
   * @type {Object}
   */
  filters: PropTypes.object
};

const extractAdjCaseNumber = prop('adjCaseNumber');

function SearchCasesTable({
  filters,
  selectedCase,
  onCaseSelected,
  csvFilename,
  caseToAdd,
  onAddCase,
  onCancelDialog
}) {
  return (
    <>
      <AddUserCaseDialog
        onCompleted={onCancelDialog}
        onError={onCancelDialog}
        onCancel={onCancelDialog}
        open={isDefined(caseToAdd)}
        wcabOrSearchCase={caseToAdd}
      >
        You are about to add <span className="b">{extractAdjCaseNumber(caseToAdd)}</span> to{' '}
        <i>My Cases</i>. Would you like to receive future updates for this case?
      </AddUserCaseDialog>
      <Query
        query={searchCasesQuery}
        variables={filters}
        skip={isBlank(filters)}
        disableAlert={isResponseTooLargeError}
      >
        {({ loading, error, data }) => {
          return (
            <CasesTable
              cases={data.searchCases}
              loading={loading}
              selectable={false}
              selectedCase={selectedCase}
              onCaseSelected={onCaseSelected}
              csvFilename={csvFilename}
              noDataText={
                error ? (
                  isResponseTooLargeError(error) ? (
                    'More than 50 rows returned from EAMS. Please refine your search.'
                  ) : (
                    'There was an error searching cases from EAMS'
                  )
                ) : loading ? (
                  'Searching cases...'
                ) : isEmpty(data.searchCases) ? (
                  'No results found for these filters.'
                ) : (
                  <span>
                    Use filters {<LineIcon icon="filter" size="lg" />} to search cases from EAMS
                  </span>
                )
              }
              additionalColumns={[
                {
                  accessor: 'owned',
                  resizable: false,
                  sortable: false,
                  className: 'flex justify-center items-center',
                  Cell: ({ row: { _original: searchCase } }) => {
                    return (
                      <PlusCheckCell
                        checked={searchCase.owned}
                        tooltip="Save to My Cases"
                        onClick={() => onAddCase(searchCase)}
                      />
                    );
                  }
                }
              ]}
            />
          );
        }}
      </Query>
    </>
  );
}

const enhance = compose(
  withState('caseToAdd', 'setCaseToAdd', undefined),
  withState('addingCase', 'setAddingCase', false),
  withHandlers({
    onCancelDialog: ({ setCaseToAdd }) => () => setCaseToAdd(undefined),
    onAddCase: ({ setCaseToAdd }) => searchCase => {
      setCaseToAdd(searchCase);
    }
  }),
  pure
);

export default enhance(SearchCasesTable);
