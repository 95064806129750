import { setContext } from 'apollo-link-context';
import { getAuthToken } from './authenticator';

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  };
});

export default httpLink => authLink.concat(httpLink);
