import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import compose from 'recompose/compose';
import setPropTypes from 'recompose/setPropTypes';
import setDisplayName from 'recompose/setDisplayName';
import withProps from 'recompose/withProps';
import withHandlers from 'recompose/withHandlers';
import { isNil } from 'ramda';
import Modal from '../core/modal';
import EFilingTypeSelectionStep from './e-filing-type-selection-step';
import DTMWizard from './dtm/dtm-wizard';
import EAMSWizard from './eams/eams-wizard';
import { withIWProfileContextConsumer } from '../injured-worker-profile/iwp-context';
import { withEFilingContextConsumer, EFILING_TYPE } from './e-filing-context';
import withMobileDialog from '@material-ui/core/withMobileDialog';

EFilingModal.propTypes = {
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  selectedRelatedCase: PropTypes.object,
  filingType: PropTypes.oneOf([EFILING_TYPE.DTM, EFILING_TYPE.EAMS, EFILING_TYPE.SUM])
};

EFilingModal.defaultProps = {
  modalOpen: false
};

function EFilingModal({ modalOpen, fullScreen, onClose, selectedRelatedCase, filingType }) {
  return (
    <Modal
      open={modalOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      disableContent={true}
    >
      {isNil(filingType) ? (
        <EFilingTypeSelectionStep
          title={`Electronic Filing - ${selectedRelatedCase && selectedRelatedCase.adjCaseNumber}`}
          onCancel={onClose}
        />
      ) : filingType === EFILING_TYPE.EAMS ? (
        <EAMSWizard hideEFilingModal={onClose} />
      ) : (
        <DTMWizard hideEFilingModal={onClose} />
      )}
    </Modal>
  );
}

const enhance = compose(
  setDisplayName(EFilingModal.name),
  setPropTypes({
    hideEFilingModal: PropTypes.func.isRequired,
    modalOpen: PropTypes.bool
  }),
  withIWProfileContextConsumer(context => ({ IWProfileContext: context })),
  withEFilingContextConsumer(context => ({ EFilingContext: context })),
  withProps(
    ({
      IWProfileContext: {
        state: { selectedRelatedCase }
      },
      EFilingContext: {
        state: { type }
      }
    }) => {
      return {
        filingType: type,
        selectedRelatedCase
      };
    }
  ),
  withHandlers({
    onClose: ({ hideEFilingModal, EFilingContext: { clearContext } }) => () => {
      // Clear eFiling context so next time the modal is opened, a fresh
      // filing can be begin anew
      clearContext();

      // Close the modal
      return hideEFilingModal();
    }
  }),
  withMobileDialog(),
  pure
);

export default enhance(EFilingModal);
