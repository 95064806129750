import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import cn from 'classnames';

const styles = theme => ({
  root: {
    boxShadow: 'none'
  },
  disabled: {
    borderColor: theme.palette.action.disabled
  },
  containedPrimary: {
    border: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      boxShadow: 'none'
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: 'none'
    },
    '&$disabled': {
      borderColor: theme.palette.action.disabled
    }
  },
  containedSecondary: {
    color: theme.palette.common.white,
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.secondary.dark,
      boxShadow: 'none'
    },
    '&:focus': {
      borderColor: theme.palette.secondary.main,
      boxShadow: 'none'
    }
  }
});

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained', 'fab', 'extendedFab'])
};

RegularButton.defaultProps = {
  variant: 'contained'
};

function RegularButton({ classes, variant, color, className, ...rest }) {
  return (
    <Button
      {...rest}
      color={color}
      classes={classes}
      variant={variant}
      className={cn(className, 'h2 f7 b lh-copy tc ba br-pill ph3 pv1 outline-0')}
    />
  );
}

export default compose(
  withStyles(styles),
  pure
)(RegularButton);
