import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  pure,
  withState,
  withHandlers,
  withProps
} from 'recompose';
import { isNil, propSatisfies, map, when, propEq, mergeDeepLeft } from 'ramda';
import cn from 'classnames';
import { Typography, Button } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import WizardStepLayout from '../wizard-step-layout';
import CaseParticipantRolesTable from './case-participant-roles-table';
import withEFilingWizardHandlers from '../with-e-filing-wizard-handlers';
import withUpsertNonCaseParticipantMutation from './with-upsert-non-case-participant-mutation';
import NonCaseParticipantForm from './non-case-participant-form';
import LineIcon from '../../core/line-icon';
import isDefined from '../../../utils/is-defined';

const propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

/**
 * Checks whether the given object contains an `id` properties and it's
 * not either `null` or `undefined`.
 *
 * @function
 * @param {Object} obj The object to check.
 * @returns {Boolean} `true` if `obj` contains a non nil `id` property; `false`, otherwise.
 */
const hasId = propSatisfies(isDefined, 'id');

function CaseParticipantsSelectionStep({
  title,
  onSaveDraft,
  onPreviousStep,
  onCancel,
  caseId,
  filingId,
  stepError,
  stepDisabled,
  currentStep,
  stepsCount,
  loading,
  selectedCaseParticipantRoles,
  nonCaseParticipant,
  setSelectedCaseParticipantRoles,
  onNextStep,
  participantsError,
  activeViewIndex,
  onEditNonCaseParticipant,
  onCancelNonCaseParticipant,
  onAddNonCaseParticipant,
  onSubmitNonCaseParticipant,
  handleChangeViewIndex
}) {
  return (
    <WizardStepLayout
      loading={loading}
      error={stepError}
      title={title}
      showCancelButton={false}
      stepNumber={currentStep}
      stepsCount={stepsCount}
      onCancel={onCancel}
      onBack={onPreviousStep}
      onNext={onNextStep}
      onSaveDraft={onSaveDraft}
      disabled={stepDisabled}
    >
      <SwipeableViews index={activeViewIndex} onChangeIndex={handleChangeViewIndex}>
        <div className="flex flex-column flex-grow-1">
          <Typography variant="subtitle1">Select case participants</Typography>
          <div
            className={cn('f7 pink mt2 mb2 b mw5', {
              hidden: !participantsError
            })}
          >
            You must include at least one participant
          </div>
          <CaseParticipantRolesTable
            className="pb2"
            caseId={caseId}
            filingId={filingId}
            onEdit={onEditNonCaseParticipant}
            defaultCaseParticipantRoles={selectedCaseParticipantRoles}
            onSelectedCaseParticipantRolesChange={setSelectedCaseParticipantRoles}
          />
          <Button className="tl pt1" color="secondary" onClick={onAddNonCaseParticipant}>
            <LineIcon className="pr2" icon="plus-square" size="s" />
            <span className="pt1">Add recipient</span>
          </Button>
        </div>
        <div className="flex flex-column flex-grow-1">
          <Typography variant="subtitle1" className="mb2">
            Mail recipient
          </Typography>
          <NonCaseParticipantForm
            onSubmit={onSubmitNonCaseParticipant}
            onCancel={onCancelNonCaseParticipant}
            defaultNonCaseParticipant={nonCaseParticipant}
          />
        </div>
      </SwipeableViews>
    </WizardStepLayout>
  );
}

const enhance = compose(
  setDisplayName(CaseParticipantsSelectionStep.name),
  setPropTypes(propTypes),
  withState('participantsError', 'setParticipantsError', false),
  withEFilingWizardHandlers,
  withState('activeViewIndex', 'setActiveViewIndex', 0),
  withState('nonCaseParticipant', 'setNonCaseParticipant'),
  withState(
    'selectedCaseParticipantRoles',
    'setSelectedCaseParticipantRoles',
    ({
      eFilingContext: {
        state: { caseParticipantRoles }
      }
    }) => {
      return isNil(caseParticipantRoles) ? [] : [...caseParticipantRoles];
    }
  ),
  withProps(
    ({
      IWProfileContext: {
        state: {
          selectedRelatedCase: { id }
        }
      },
      wizardContext: {
        state: { currentStep, stepsCount, error, loading }
      },
      eFilingContext: {
        state: { filingId }
      },
      activeViewIndex
    }) => {
      return {
        caseId: id,
        filingId,
        currentStep: currentStep + 1,
        stepsCount: stepsCount - 1,
        stepError: error,
        // Disable wizard controls if we are showing the non case participant
        // mail recipient form
        stepDisabled: activeViewIndex === 1,
        loading
      };
    }
  ),
  withUpsertNonCaseParticipantMutation,
  withHandlers({
    isValid: ({ selectedCaseParticipantRoles }) => () => selectedCaseParticipantRoles.length > 0
  }),
  withHandlers({
    onEditNonCaseParticipant: ({
      setNonCaseParticipant,
      setActiveViewIndex
    }) => nonCaseParticipant => {
      // Load non case participant
      setNonCaseParticipant(nonCaseParticipant);

      // Make recipient form the active view
      // (0: Case participants table; 1: Mail recipient form)
      return setActiveViewIndex(1);
    },
    handleChangeViewIndex: ({ setActiveViewIndex }) => index => setActiveViewIndex(index),
    onAddNonCaseParticipant: ({ setNonCaseParticipant, setActiveViewIndex }) => () => {
      // Clear any previously selected non case participant so it
      // shows an empty form
      setNonCaseParticipant({});

      // Make recipient form the active view
      // (0: Case participants table; 1: Mail recipient form)
      return setActiveViewIndex(1);
    },
    onCancelNonCaseParticipant: ({ setActiveViewIndex }) => () => {
      // Return to case participant table view
      // (0: Case participants table; 1: Mail recipient form)
      return setActiveViewIndex(0);
    },
    onSubmitNonCaseParticipant: ({
      caseId,
      filingId,
      setActiveViewIndex,
      upsertNonCaseParticipant,
      selectedCaseParticipantRoles,
      setSelectedCaseParticipantRoles,
      wizardContext: { updateContext }
    }) => async nonCaseParticipant => {
      updateContext(() => ({ loading: true }));
      try {
        // Save non case participant mail recipient and update Apollo cache
        // This should also make the case participant table include a new row
        const upsertedNonCaseParticipant = await upsertNonCaseParticipant({
          ...nonCaseParticipant,
          filingId,
          caseId
        });

        if (!hasId(nonCaseParticipant)) {
          // If a non case document participant has just been created (no `id` was passed to the mutation)
          // automatically select it on the case participants table
          setSelectedCaseParticipantRoles([
            ...selectedCaseParticipantRoles,
            upsertedNonCaseParticipant
          ]);
        } else {
          // ...otherwise, update the array containing selected cases with the new information
          // that was just edited
          setSelectedCaseParticipantRoles(
            map(
              when(
                propEq('id', upsertedNonCaseParticipant.id),
                mergeDeepLeft(upsertedNonCaseParticipant)
              ),
              selectedCaseParticipantRoles
            )
          );
        }
        // Return back to case participant selection
        return setActiveViewIndex(0);
      } catch (err) {
        updateContext(() => ({ error: err }));
      } finally {
        updateContext(() => ({ loading: false }));
      }
    },
    onSaveDraft: ({
      isValid,
      saveEFilingDraft,
      selectedCaseParticipantRoles,
      setParticipantsError,
      eFilingContext: { updateContext: updateEFilingContext }
    }) => async () => {
      if (isValid()) {
        try {
          await saveEFilingDraft({
            caseParticipantRoles: isNil(selectedCaseParticipantRoles)
              ? []
              : [...selectedCaseParticipantRoles]
          });
          setParticipantsError(false);
        } catch (err) {
          updateEFilingContext(() => ({ error: err }));
        }
      } else {
        setParticipantsError(true);
      }
    },
    onNextStep: ({
      isValid,
      nextStep,
      selectedCaseParticipantRoles,
      setParticipantsError
    }) => async () => {
      if (isValid()) {
        await nextStep({
          caseParticipantRoles: [...selectedCaseParticipantRoles]
        });
      } else {
        setParticipantsError(true);
      }
    },
    onPreviousStep: ({ previousStep, selectedCaseParticipantRoles }) => () =>
      previousStep({
        caseParticipantRoles: [...selectedCaseParticipantRoles]
      })
  }),
  pure
);

export default enhance(CaseParticipantsSelectionStep);
