import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import setPropTypes from 'recompose/setPropTypes';
import setDisplayName from 'recompose/setDisplayName';
import defaultProps from 'recompose/defaultProps';
import withHandlers from 'recompose/withHandlers';
import {
  compose,
  pick,
  isEmpty,
  unless,
  is,
  of,
  values,
  head,
  trim,
  useWith,
  identity,
  join,
  append,
  filter
} from 'ramda';
import formatDate from 'date-fns/format';
import Chip from '@material-ui/core/Chip';
import LineIcon from '../../core/line-icon';
import isNotBlank from '../../../utils/is-not-blank';

/**
 * Checks whether or not a given `obj` contains at least one of the properties
 * defined in `prop`
 *
 * @function
 * @param {Array.<String>} props A set of properties to check
 * @param {Object} obj The object to check properties on
 * @returns {Boolean} `true` if `obj` contains one or more of the properties
 *  in `props`; `false`, otherwise.
 */
const doesNotHaveProps = compose(
  isEmpty,
  filter(Boolean),
  pick
);

/**
 * Wraps the given `value` in an `Array` unless it is already one,
 * it's `null` or `undefined`.
 *
 * @function
 * @param {*} value The value to cast.
 * @returns {Array} Either `null`, `undefined`, `value` or `[value]`.
 */
const castArray = unless(is(Array), of);

const wildcardExpressionFrom = compose(
  trim,
  join('* '),
  append(' '),
  filter(isNotBlank)
);

const joinNonBlank = useWith(join, [identity, filter(isNotBlank)]);

const FilterEditIcon = <LineIcon icon="pencil" className="ph1" />;

const FilterChip = compose(
  setDisplayName('FilterChip'),
  setPropTypes({
    /**
     * An objects describing applied search filters and their values.
     * @type {Object}
     */
    filters: PropTypes.object,

    /**
     * Name (or list of names) of filter this chip will render its value for.
     * @type {String|Array.<*>}
     */
    chipFor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,

    /**
     * Render function that receives and object containing the values of the filters
     * declared by `chipFor` and is expected to return a `String` to display as label
     * for the chip.
     * @type {Function}
     */
    label: PropTypes.func,

    /**
     * Callback called when this chip's edit icon is clicked.
     * @type {Function}
     */
    onEdit: PropTypes.func
  }),
  defaultProps({
    // By default, if not provided, the `label` would be rendered from the
    // value of the first prop defined in `chipFor`.
    // For example, if `chipFor={['firstName', 'lastName']}` and `label` is not
    // provided, the chip will display the value from `filters.firstName`
    label: compose(
      head,
      values
    )
  }),
  // Don't render chip if `filters` don't contain a value for the active filter
  branch(({ chipFor, filters }) => {
    return doesNotHaveProps(castArray(chipFor), filters);
  }, renderNothing),
  withHandlers({
    onDelete: ({ onEdit, chipFor }) => (...args) => {
      // Propagate arguments given by the original `onDelete` handler,
      // adding the name (or list of names) of filters being edited
      return onEdit(chipFor, ...args);
    }
  }),
  pure
)(({ onDelete, label, chipFor, filters }) => {
  return (
    <Chip
      label={label(pick(castArray(chipFor), filters))}
      color="secondary"
      variant="outlined"
      className="mr2"
      deleteIcon={FilterEditIcon}
      onDelete={onDelete}
    />
  );
});

FilterSelection.propTypes = {
  /**
   * CSS classes applied to the root container.
   * @type {String}
   */
  className: PropTypes.string,

  /**
   * An objects describing applied search filters and their values.
   * @type {Object}
   */
  filters: PropTypes.object,

  /**
   * Callback fired when clicking on the edit icon of a filter chip.
   * @type {Function}
   */
  onEdit: PropTypes.func
};

FilterSelection.defaultProps = {
  filters: {}
};

function FilterSelection({ filters, onEdit, className }) {
  return (
    <div className={className}>
      <FilterChip chipFor="adjCaseNumber" filters={filters} onEdit={onEdit} />
      <FilterChip
        chipFor="ssn"
        filters={filters}
        onEdit={onEdit}
        label={({ ssn }) => `SSN ${ssn}`}
      />
      <FilterChip
        chipFor={['firstName', 'lastName']}
        filters={filters}
        onEdit={onEdit}
        label={({ firstName, lastName }) =>
          `Name matches ${wildcardExpressionFrom([firstName, lastName])}`
        }
      />
      <FilterChip
        chipFor="dob"
        filters={filters}
        onEdit={onEdit}
        label={({ dob }) => `Birthdate is ${formatDate(dob, 'MMM D, YYYY')}`}
      />
      <FilterChip
        chipFor="doi"
        filters={filters}
        onEdit={onEdit}
        label={({ doi }) => `Injury date is ${formatDate(doi, 'MMM D, YYYY')}`}
      />
      <FilterChip chipFor="employer" filters={filters} onEdit={onEdit} />
      <FilterChip
        chipFor={['city', 'zip']}
        filters={filters}
        onEdit={onEdit}
        label={({ city, zip }) => joinNonBlank(', ', [city, zip])}
      />
    </div>
  );
}

export default pure(FilterSelection);
