import createContext from '../context/create-context';

const defaultState = {
  currentStep: 0,
  error: false,
  loading: false
};

const { withContextProvider, withContextConsumer } = createContext(defaultState);

export {
  withContextConsumer as withWizardContextConsumer,
  withContextProvider as withWizardContextProvider
};
