import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { toLower, unless, isNil } from 'ramda';
import pure from 'recompose/pure';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

DocumentSubmissionStatusCell.propTypes = {
  /**
   * JSS classes and styles.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired,
  /**
   * The status to display. The overall appearance of the cell
   * depends on it.
   * @type {String}
   */
  value: PropTypes.oneOf(['SUCCESS', 'SUBMITTED', 'FAILED', 'PENDING', 'PROCESSING', 'DRAFT'])
};

const styles = theme => ({
  chipRoot: {
    borderRadius: 2,
    height: '1.2rem',
    paddingTop: 3,
    minWidth: '5.4rem'
  },
  draft: {
    color: theme.palette.primary.main,
    border: '1px solid rgba(49, 56, 62, 0.5)',
    backgroundColor: 'transparent'
  },
  success: {
    backgroundColor: theme.palette.success.main
  },
  failed: {
    backgroundColor: theme.palette.error.main
  },
  processing: {
    fontStyle: 'italic',
    backgroundColor: theme.palette.warning.main
  },
  pending: {
    fontStyle: 'italic',
    backgroundColor: theme.palette.warning.main
  },
  submitted: {
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    border: 0,
    backgroundColor: 'transparent'
  }
});

const TOOLTIP_LABELS = Object.freeze({
  draft: 'Resume draft',
  success: 'Completed',
  failed: 'Filing error',
  processing: 'In Progress',
  pending: 'In Progress',
  submitted: 'In Progress'
});

/**
 * Lower cases given `String` unless it's `null` or `undefined`,
 * in which case the value is returned as-is.
 * @function
 * @param {String} word The value to lowercase
 * @returns {String} The value of `word` lower cased
 */
const toLowerIfNotNil = unless(isNil, toLower);

export function DocumentSubmissionStatusCell({ value, classes }) {
  const status = toLowerIfNotNil(value);
  return (
    <Tooltip placement="right" title={TOOLTIP_LABELS[status]}>
      <Chip className={cn(classes.chipRoot, classes[status], 'pointer f7')} label={value} />
    </Tooltip>
  );
}

export default compose(
  withStyles(styles),
  pure
)(DocumentSubmissionStatusCell);
