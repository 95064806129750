import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import cn from 'classnames';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  loaderWrapper: {
    transition: 'all .3s ease',
    backgroundColor: 'rgb(255, 255, 255, 0.6)',
    content: ''
  }
};

Overlay.propTypes = {
  /**
   * JSS custom styles
   * @type {Object}
   */
  classes: PropTypes.object.isRequired,

  /**
   * Whether to show the overlay or not.
   * @type {Boolean}
   */
  when: PropTypes.bool,

  /**
   * Component sub-tree that will be covered by this overlay.
   * @type {node}
   */
  children: PropTypes.node,

  /**
   * Time in milliseconds to wait before showing this overlay.
   * @default 200
   * @type {Number}
   */
  transitionDelay: PropTypes.number,

  /**
   * Whether to unmount children component after fading ends or not
   * @see https://material-ui.com/api/fade/#fade
   * @default true
   * @type {Boolean}
   */
  unmountOnExit: PropTypes.bool
};

Overlay.defaultProps = {
  transitionDelay: 200,
  unmountOnExit: true
};

export function Overlay({ className, classes, when, transitionDelay, unmountOnExit, children }) {
  return (
    <div className={className}>
      <Fade
        in={when}
        style={{
          transitionDelay: when ? `${transitionDelay}ms` : '0ms'
        }}
        unmountOnExit={unmountOnExit}
      >
        <div className={cn(classes.loaderWrapper, 'w-100 h-100 absolute z-9999')} />
      </Fade>
      {children}
    </div>
  );
}

export default compose(
  withStyles(styles),
  pure
)(Overlay);
