import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, setPropTypes, setDisplayName } from 'recompose';
import { Wizard } from '../../core/wizard/wizard';
import { withIWProfileContextConsumer } from '../../injured-worker-profile/iwp-context';
import EAMSDocTypeSelectionStep from './eams-doc-type-selection-step';
import GeneralInfoStep from './general-info-step';

const propTypes = {
  hideEFilingModal: PropTypes.func.isRequired
};

function EAMSWizard({ hideEFilingModal, selectedRelatedCase }) {
  return (
    <Wizard onFinished={hideEFilingModal}>
      <EAMSDocTypeSelectionStep
        title={`EAMS E-Filing - ${selectedRelatedCase && selectedRelatedCase.adjCaseNumber}`}
        onCancel={hideEFilingModal}
      />
      <GeneralInfoStep
        title={`Unstructured Form Filing - ${selectedRelatedCase &&
          selectedRelatedCase.adjCaseNumber}`}
        onCancel={hideEFilingModal}
      />
    </Wizard>
  );
}

const enhance = compose(
  setDisplayName('EAMSWizard'),
  setPropTypes(propTypes),
  withIWProfileContextConsumer(context => ({ IWProfileContext: context })),
  withProps(({ IWProfileContext: { state: { selectedRelatedCase } } }) => ({
    selectedRelatedCase
  }))
);

export default enhance(EAMSWizard);
