import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Link, withRouter } from 'react-router-dom';
import cn from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    }
  },
  titleWrapper: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 24
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  title: {
    fontFamily: 'Sansation',
    color: theme.palette.common.white
  },
  tabs: {
    flexGrow: 2
  },
  tabRoot: {
    textTransform: 'initial'
  },
  tabWrapper: {
    [theme.breakpoints.down('lg')]: {
      padding: 13
    },
    [theme.breakpoints.down('md')]: {
      padding: 14
    },
    [theme.breakpoints.down('xl')]: {
      padding: 15
    }
  },
  tabColor: {
    color: theme.palette.primary.lightText
  },
  tabSelected: {
    color: theme.palette.common.white
  },
  tabIndicator: {
    height: 4
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  }
});

class ApplicationBar extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    logo: PropTypes.element,
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    endOptions: PropTypes.element,
    drawerOptions: PropTypes.element,
    drawerIcon: PropTypes.element,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  };

  state = {
    drawerOpen: false
  };

  navigateTo = (event, path) => {
    return this.props.history.push(path);
  };

  handleDrawerOpen = () => {
    return this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    return this.setState({ drawerOpen: false });
  };

  render() {
    const {
      classes,
      title,
      logo,
      location,
      links,
      drawerIcon,
      drawerOptions,
      endOptions
    } = this.props;
    const tabsClasses = {
      root: classes.tabRoot,
      wrapper: classes.tabWrapper,
      textColorInherit: classes.tabColor,
      selected: classes.tabSelected
    };
    return (
      <AppBar color="primary" position="absolute" className={classes.appBar}>
        <Drawer
          variant="temporary"
          classes={{
            paper: cn(classes.drawerPaper, !this.state.drawerOpen && classes.drawerPaperClose)
          }}
          onClose={this.handleDrawerClose}
          open={this.state.drawerOpen}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.handleDrawerClose}
            onKeyDown={this.handleDrawerClose}
          >
            <List>
              {links.map(link => (
                <ListItem
                  key={`drawer-list-item--${link.path}`}
                  button
                  onClick={event => this.navigateTo(event, link.path)}
                >
                  <ListItemText primary={link.label} />
                </ListItem>
              ))}
            </List>
            {drawerOptions && <Divider />}
            {drawerOptions}
          </div>
        </Drawer>
        <Toolbar disableGutters={!this.state.drawerOpen} className="justify-between">
          {drawerIcon && (
            <Hidden smUp>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={cn(
                  classes.menuButton,
                  this.state.drawerOpen && classes.menuButtonHidden
                )}
              >
                {drawerIcon}
              </IconButton>
            </Hidden>
          )}
          <Link to="/" className={cn(classes.titleWrapper, 'flex items-center no-underline')}>
            {logo}
            <Typography variant="h6" color="inherit" noWrap className={classes.title}>
              {title}
            </Typography>
          </Link>
          <Hidden xsDown>
            <Tabs
              centered
              classes={{ indicator: classes.tabIndicator }}
              value={location.pathname}
              onChange={this.navigateTo}
              indicatorColor="secondary"
              textColor="inherit"
            >
              {links.map(link => (
                <Tab
                  key={`tab--${link.path}`}
                  value={link.path}
                  classes={tabsClasses}
                  label={link.label}
                />
              ))}
            </Tabs>
          </Hidden>
          <div className="flex">{endOptions}</div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(ApplicationBar);
