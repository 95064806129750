import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuCaret from './menu-caret';

class PopperMenu extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    icon: PropTypes.element,
    caret: PropTypes.bool
  };

  state = {
    open: false
  };

  handleToggle = () => {
    return this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    return this.setState({ open: false });
  };

  render() {
    const { icon, caret, children } = this.props;
    const { open } = this.state;

    return (
      <div className="h-100">
        <Button
          className="h-100"
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          {icon}
          <MenuCaret show={caret} open={open} />
        </Button>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper square={true}>
                <ClickAwayListener onClickAway={this.handleClose}>{children}</ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default PopperMenu;
