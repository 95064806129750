import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure, withHandlers, withProps } from 'recompose';
import { Form } from 'formik';
import { mergeRight, path } from 'ramda';
import Divider from '@material-ui/core/Divider';
import WizardStepLayout from '../wizard-step-layout';
import Input from '../../core/input/input';
import DocumentTypesDropdown from '../document-types/document-types-dropdown';
import DocumentTitleDropdown from './document-title-dropdown';
import FileUpload from '../../core/file-upload/dropzone';
import { withEFilingContextConsumer } from '../e-filing-context';
import withGeneralInfoCore from '../shared/general-info-step-core';

const propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

const fromEFilingContextOrDefault = compose(
  mergeRight({
    caseId: undefined,
    documentTitle: undefined,
    documentType: undefined,
    file: undefined
  }),
  path(['eFilingContext', 'state'])
);

function GeneralInfoStep({
  title,
  onSaveDraft,
  onPreviousStep,
  onCancel,
  currentStep,
  stepsCount,
  selectedRelatedCase,
  stepError,
  loading,
  acceptedExtensions,
  onFileDialogOpen,
  onFileChange,
  onFileRejected,
  errors,
  status,
  touched,
  submitForm,
  setFieldValue,
  documentTypes,
  docTypesLoading,
  values: { documentTitle, documentType, file }
}) {
  return (
    <WizardStepLayout
      loading={loading}
      title={title}
      showCancelButton={false}
      stepNumber={currentStep + 1}
      stepsCount={stepsCount}
      error={stepError}
      onCancel={onCancel}
      onBack={onPreviousStep}
      onNext={submitForm}
      onSaveDraft={onSaveDraft}
    >
      <div className="flex flex-column flex-grow-1">
        <div className="flex justify-between">
          <div className="mr2 flex-1">
            <Input
              label="ADJ Case Number"
              fixedLabel={true}
              readOnly
              value={selectedRelatedCase.adjCaseNumber}
            />
          </div>
          <div className="mr2 flex-1">
            <Input
              label="First Name"
              fixedLabel={true}
              readOnly
              value={selectedRelatedCase.firstName}
            />
          </div>
          <div className="mr2 flex-1">
            <Input label="MI" fixedLabel={true} readOnly value="" />
          </div>
          <div className="mr2 flex-1">
            <Input
              label="Last Name"
              fixedLabel={true}
              readOnly
              value={selectedRelatedCase.lastName}
            />
          </div>
        </div>
        <Divider />
        <div>
          <div className="f5 mb2">Select the type and title of your document</div>
          <Form>
            <div className="flex justify-around items-center">
              <div className="mr6 flex-1">
                <DocumentTypesDropdown
                  documentTypes={documentTypes}
                  loading={docTypesLoading}
                  id="e-filing-documentType"
                  label="Document Type"
                  name="documentType"
                  value={documentType}
                  onChange={item => {
                    setFieldValue('documentType', item);
                    setFieldValue('documentTitle', null);
                  }}
                  errorText={(touched.documentType || status.validated) && errors.documentType}
                />
              </div>
            </div>
            <div className="flex justify-justify-start f6">
              <div className="mr6 flex-1">
                <DocumentTitleDropdown
                  documentTitles={documentType ? documentType.value.titles : []}
                  loading={docTypesLoading}
                  id="e-filing-documentTitle"
                  label="Document Title"
                  name="documentTitle"
                  value={documentTitle}
                  onChange={item => setFieldValue('documentTitle', item)}
                  errorText={(touched.documentTitle || status.validated) && errors.documentTitle}
                />
              </div>
            </div>
          </Form>
        </div>
        <Divider />
        <FileUpload
          name="file"
          value={file}
          acceptedExtensions={acceptedExtensions}
          label="Upload your attached documents:"
          onChange={onFileChange}
          onDialogOpen={onFileDialogOpen}
          onFileRejected={onFileRejected}
          errorText={(touched.file || status.validated) && errors.file}
        />
      </div>
    </WizardStepLayout>
  );
}

const enhance = compose(
  setDisplayName(GeneralInfoStep.name),
  setPropTypes(propTypes),
  withEFilingContextConsumer(context => ({ eFilingContext: context })),
  withProps(
    ({
      eFilingContext: {
        state: { type }
      },
      wizardContext: {
        state: { currentStep, stepsCount, error, loading }
      },
      IWProfileContext: {
        state: { selectedRelatedCase }
      }
    }) => ({
      filingType: type,
      initializeFormValues: fromEFilingContextOrDefault,
      currentStep,
      stepsCount,
      selectedRelatedCase,
      stepError: error,
      loading
    })
  ),
  withGeneralInfoCore,
  withHandlers({
    onPreviousStep: ({ previousStep, values: { documentTitle, documentType, file } }) => () => {
      previousStep({
        documentTitle,
        documentType,
        file
      });
    }
  }),
  pure
);

export default enhance(GeneralInfoStep);
