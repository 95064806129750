import createContext from '../core/context/create-context';

const defaultState = {
  headerExpanded: true,
  selectedCase: null,
  selectedRelatedCase: null
};

const { withContextProvider, withContextConsumer } = createContext(defaultState);

export {
  withContextConsumer as withIWProfileContextConsumer,
  withContextProvider as withIWProfileContextProvider
};
