import { getAuthToken } from './authenticator';
import throwWith from '../../utils/throw-with';

/**
 * Resets the current password for the authorized user by
 * calling a remote API endpoint. Both `password` and `confirmPassword`
 * must match.
 *
 * @param {String} password The new password
 * @param {String} confirmPassword Must match `password.`
 * @returns {Boolean} `true` if the operation was successful (otherwise, an exception is raised).
 * @throws {Error} If the password could not be reset or a network problem occurred.
 */
async function resetPassword(password, confirmPassword) {
  const token = getAuthToken();
  const response = await fetch('/api/password', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined
    },
    method: 'POST',
    body: JSON.stringify({ password, confirmPassword })
  });

  const { success, message, status } = await response.json();
  return success ? true : throwWith(message, { status });
}

export default resetPassword;
