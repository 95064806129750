import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import Query from '../../components/core/data/query';
import LineIcon from '../../components/core/line-icon';
import myCasesQuery from '../../components/cases/my-cases.graphql';
import CasesTable from '../../components/cases/cases-table';
import isBlank from '../../utils/is-blank';

export function MyCasesTable({ filters, selectedCase, onCaseSelected, csvFilename }) {
  return (
    <Query query={myCasesQuery} variables={filters} skip={isBlank(filters)}>
      {({ loading, error, data }) => {
        return (
          <CasesTable
            cases={data.wcabCases}
            loading={loading}
            selectedCase={selectedCase}
            onCaseSelected={onCaseSelected}
            csvFilename={csvFilename}
            noDataText={
              error ? (
                'There was an error fetching cases'
              ) : loading ? (
                'Searching My Cases...'
              ) : isEmpty(data.wcabCases) ? (
                'No results found for these filters.'
              ) : (
                <span>Use filters {<LineIcon icon="filter" size="lg" />} to search My Cases</span>
              )
            }
          />
        );
      }}
    </Query>
  );
}

export default memo(MyCasesTable);
