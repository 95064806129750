import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { propEq, when, always, isNil } from 'ramda';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import pure from 'recompose/pure';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';

const zeroIfNil = when(isNil, always(0));

const styles = {
  toolbar: {
    paddingLeft: 0
  }
};

export class CustomTablePagination extends PureComponent {
  static propTypes = {
    /**
     * The total number of rows.
     * @type {Number}
     */
    items: PropTypes.number.isRequired,
    /**
     * The zero-based index of the current page.
     * @type {Number}
     */
    page: PropTypes.number.isRequired,
    /**
     * The number of rows per page.
     * @type {Number}
     */
    pageSize: PropTypes.number.isRequired,
    /**
     * Callback fired when the page is changed.
     * @type {Function}
     */
    onPageChange: PropTypes.func.isRequired,
    /**
     * Callback fired when the number of rows per page is changed.
     * @type {Function}
     */
    onPageSizeChange: PropTypes.func.isRequired,
    /**
     * Aria label for previous page action icon.
     * @type {String}
     */
    previousText: PropTypes.string,
    /**
     * Aria label for next page action icon.
     * @type {String}
     */
    nextText: PropTypes.string,
    /**
     * Customize the rows per page text.
     * @type {String}
     */
    rowsPerPageText: PropTypes.string
  };

  // Ties `onPageChange` and `onPageSizeChange` react-table callbacks
  // to material-ui own `TablePagination` API
  // @see https://material-ui.com/api/table-pagination/#tablepagination
  // @see https://github.com/react-tools/react-table/blob/master/src/pagination.js
  onChangePage = (event, page) => {
    return this.props.onPageChange(page);
  };

  onChangeRowsPerPage = (event, rowsPerPage) => {
    return this.props.onPageSizeChange(rowsPerPage.props.value);
  };

  render() {
    const { items, page, pageSize, previousText, nextText, rowsPerPageText, classes } = this.props;
    return (
      <TablePagination
        component="div"
        classes={classes}
        count={zeroIfNil(items)}
        onChangePage={this.onChangePage}
        onChangeRowsPerPage={this.onChangeRowsPerPage}
        backIconButtonProps={{
          title: previousText,
          'aria-label': previousText
        }}
        nextIconButtonProps={{
          title: nextText,
          'aria-label': nextText
        }}
        page={page}
        rowsPerPage={pageSize}
        labelRowsPerPage={rowsPerPageText}
      />
    );
  }
}

// Do not show pagination options if there are no items to display
export default compose(
  branch(propEq('items', 0), renderNothing),
  withStyles(styles),
  pure
)(CustomTablePagination);
