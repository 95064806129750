import React from 'react';
import PropTypes from 'prop-types';
import { is } from 'ramda';
import { pure } from 'recompose';
import { Query } from 'react-apollo';
import DataErrorAlert from './data-error-alert';

GraphQLQuery.propTypes = {
  /**
   * A function returning the UI you want to render based on your query result.
   * @see https://www.apollographql.com/docs/react/essentials/queries.html#props
   * @type {Function}
   */
  children: PropTypes.func.isRequired,

  /**
   * A GraphQL query document parsed into an AST by graphql-tag.
   * @see https://www.apollographql.com/docs/react/essentials/queries.html#props
   * @type {Object}
   */
  query: PropTypes.object.isRequired,

  /**
   * An object containing all of the variables your query needs to execute
   * @see https://www.apollographql.com/docs/react/essentials/queries.html#props
   * @type {Object}
   */
  variables: PropTypes.object,

  /**
   * If `true`, the query will be skipped entirely.
   * @type {Boolean}
   */
  skip: PropTypes.bool,

  /**
   * Whether to avoid showing an alert on query error or not. It could be either
   * a boolean value or a boolean returning function. If a function is given, it
   * receives the GraphQL `error` object as its only argument,
   * @default false
   * @type{Boolean|Function}
   */
  disableAlert: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};

GraphQLQuery.defaultProps = {
  disableAlert: false
};

const callOrBoolean = (fn, ...args) => (is(Function, fn) ? fn(...args) : Boolean(fn));

/**
 * A `react-apollo` `Query` component with error handling capabilities and a simplified API.
 * Displays an `Alert` dialog if the `error` callback property is set to a truthy value.
 * Children render function will still receive the `error` property but should not
 * directly handle it.
 * @see https://www.apollographql.com/docs/react/essentials/queries.html#api
 */
function GraphQLQuery({ query, variables, skip, disableAlert, children }) {
  return (
    <Query query={query} skip={skip} variables={variables}>
      {({ data = {}, error, loading, networkStatus }) => {
        const openAlert = !callOrBoolean(disableAlert, error);
        return (
          <>
            <DataErrorAlert open={openAlert} error={error} />
            {children({ data, error, loading, networkStatus })}
          </>
        );
      }}
    </Query>
  );
}

export default pure(GraphQLQuery);
