import { compose, withHandlers } from 'recompose';
import { useWith, unless, is, of, flip, adjust, pick, propSatisfies, evolve } from 'ramda';
import { graphql } from 'react-apollo';
import filingsQuery from '../document-submissions/filings.graphql';
import caseParticipantRolesQuery from './case-participant-roles.graphql';
import upsertNonCaseParticipantMutation from './upsert-non-case-participant.graphql';
import isDefined from '../../../utils/is-defined';
import safeConcat from '../../../utils/safe-concat';
import { findIndexById } from '../../../utils/find';

/**
 * Extracts properties related to a non case participant descriptor
 * from the given object.
 *
 * @function
 * @param {Object} obj The object to extract the values from.
 * @returns {Object} A new object containing the non case participants
 *  properties, exclusively.
 */
const extractNonCaseParticipantVariables = pick([
  'id',
  'name',
  'address',
  'address2',
  'filingId',
  'city',
  'state',
  'zip'
]);

/**
 * Checks whether the given object contains an `id` properties and it's
 * not either `null` or `undefined`.
 *
 * @function
 * @param {Object} obj The object to check.
 * @returns {Boolean} `true` if `obj` contains a non nil `id` property; `false`, otherwise.
 */
const hasId = propSatisfies(isDefined, 'id');

/**
 * Pushes a single element or a list of elements to the end of
 * another list without mutating it. Treats `null` or `undefined` arguments
 * as if they were empty arrays.
 *
 * @function
 * @param {Object|Array.<Object>} item The element to push.
 * @param {Array} list The list to push the `item` (or items) onto.
 * @returns {Array} A new list with all its original items plus all the ones contained by `item`.
 */
const append = useWith(flip(safeConcat), [unless(is(Array), of)]);

export default compose(
  graphql(upsertNonCaseParticipantMutation),
  withHandlers({
    upsertNonCaseParticipant: ({ mutate }) => async variables => {
      const {
        data: { upsertNonCaseParticipant }
      } = await mutate({
        variables: extractNonCaseParticipantVariables(variables),
        update: (proxy, { data: { upsertNonCaseParticipant: nonCaseParticipant } }) => {
          // If the mutation receives an `id`, it will perform an update of an existing
          // non case participant - in this case, no cache adjustments are needed
          if (!hasId(variables)) {
            // If no `id` was provided to the mutation, a new non case participant was
            // created and it's automatically added to the list of selected recipients for the active filing
            // A cache update is required to reflect this change
            const { filings } = proxy.readQuery({ query: filingsQuery });
            const activeFilingIndex = findIndexById(variables.filingId, filings);

            proxy.writeQuery({
              query: filingsQuery,
              data: {
                // Update document submission so it now contains
                // the recently created non case participant
                filings: adjust(
                  activeFilingIndex,
                  evolve({ nonCaseParticipants: append(nonCaseParticipant) }),
                  filings
                )
              }
            });

            const { documentParticipantRoles, nonCaseDocumentParticipants } = proxy.readQuery({
              query: caseParticipantRolesQuery,
              variables: { caseId: variables.caseId, filingId: variables.filingId }
            });

            proxy.writeQuery({
              query: caseParticipantRolesQuery,
              variables: { caseId: variables.caseId, filingId: variables.filingId },
              data: {
                // Update document participant table so it now lists
                // the recently created non case participant
                documentParticipantRoles,
                nonCaseDocumentParticipants: append(nonCaseParticipant, nonCaseDocumentParticipants)
              }
            });
          }
        }
      });

      return upsertNonCaseParticipant;
    }
  })
);
