import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  setPropTypes,
  pure,
  withHandlers,
  withState,
  withProps
} from 'recompose';
import { prop } from 'ramda';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import LineIcon from '../core/line-icon';
import AddUserCaseDialog from '../cases/add-user-case-dialog';
import withEFilingModal from '../e-filing/with-efiling-modal';
import { withIWProfileContextConsumer } from './iwp-context';
import isDefined from '../../utils/is-defined';
import './iwp-header.scss';

const propTypes = {
  currentCase: PropTypes.shape({
    adjCaseNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }),
  expanded: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired
};

const extractAdjCaseNumber = prop('adjCaseNumber');

export function IWPHeader({
  currentCase,
  selectedRelatedCase,
  expanded,
  onExpandedChange,
  onFileClick,
  onCancelDialog,
  onCompletedDialog,
  caseToAdd
}) {
  const { adjCaseNumber, firstName, lastName } = selectedRelatedCase || currentCase;
  return (
    <>
      <AddUserCaseDialog
        onError={onCancelDialog}
        onCompleted={onCompletedDialog}
        onCancel={onCancelDialog}
        open={isDefined(caseToAdd)}
        wcabOrSearchCase={caseToAdd}
        relatedCase={currentCase}
      >
        To file for this case, you must first add{' '}
        <span className="b">{extractAdjCaseNumber(caseToAdd)}</span> to your cases. Would you like
        to add this case and continue filing?
      </AddUserCaseDialog>
      <div className="bg-light-blue f3 fw5 tl pt3 ph4 flex flex-none justify-between">
        <div className="flex items-center">
          <LineIcon
            icon={expanded ? 'chevron-circle-up' : 'chevron-circle-down'}
            className="pointer mr2"
            onClick={onExpandedChange}
          />
          <span className="outer-space">{`${lastName}, ${firstName}:`}</span>
          <span className="blue ml2">{adjCaseNumber}</span>
        </div>
        <Tooltip enterDelay={1000} title="File this case">
          <Button color="secondary" tabIndex={0} onClick={onFileClick}>
            <LineIcon size="lg" icon="file-text" className="mr2" />
            <span className="pt1">File</span>
          </Button>
        </Tooltip>
      </div>
    </>
  );
}

const enhance = compose(
  setDisplayName(IWPHeader.name),
  setPropTypes(propTypes),
  withState('caseToAdd', 'setCaseToAdd', undefined),
  withEFilingModal,
  withIWProfileContextConsumer(context => ({ context })),
  withProps(({ context: { state: { selectedRelatedCase } } }) => {
    return { selectedRelatedCase };
  }),
  withHandlers({
    onCancelDialog: ({ setCaseToAdd }) => () => {
      // Clearing the selected case to be added, closes the confirmation dialog
      return setCaseToAdd(undefined);
    },
    onCompletedDialog: ({ setCaseToAdd, showEFilingModal }) => () => {
      // Clearing the selected case to be added, closes the confirmation dialog
      setCaseToAdd(undefined);

      // Open E-filing wizard modal
      return showEFilingModal();
    },
    onFileClick: ({
      showEFilingModal,
      setCaseToAdd,
      context: {
        state: { selectedRelatedCase }
      }
    }) => () => {
      if (!selectedRelatedCase) return;

      // If the case is already owned...
      return selectedRelatedCase.owned
        ? // ...show the E-filing wizard modal
          showEFilingModal()
        : // ...otherwise, open a confirmation dialog to allow
          // users to add the case to "My Cases"
          setCaseToAdd(selectedRelatedCase);
    }
  }),
  pure
);

export default enhance(IWPHeader);
