import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import DataTable from '../../core/table';

IWPLiensTable.propTypes = {
  liens: PropTypes.arrayOf(
    PropTypes.shape({
      reservationNumber: PropTypes.string,
      claimantName: PropTypes.string,
      fileDate: PropTypes.string,
      status: PropTypes.string,
      lienAmount: PropTypes.number,
      activationDate: PropTypes.string,
      payAmount: PropTypes.number,
      dwcProcessStatus: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  className: PropTypes.string
};

const columns = [
  { Header: 'Reservation Number', accessor: 'reservationNumber' },
  { Header: 'Claimant Name', accessor: 'claimantName' },
  { Header: 'File Date', accessor: 'fileDate' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Lien Amount', accessor: 'lienAmount' },
  { Header: 'Activation Date', accessor: 'activationDate' },
  { Header: 'Pay Amount', accessor: 'payAmount' },
  { Header: 'DWC Process Status', accessor: 'dwcProcessStatus' }
];

export function IWPLiensTable({ liens, loading, error, className }) {
  return (
    <DataTable
      data={liens}
      loading={loading}
      className={className}
      columns={columns}
      theme="light"
      showPagination={false}
      minRows={5}
      noDataText={
        error
          ? 'There was an error retrieving liens from EAMS.'
          : loading
          ? 'Searching liens...'
          : isEmpty(liens)
          ? 'No liens found for selected case.'
          : ''
      }
    />
  );
}

export default memo(IWPLiensTable);
