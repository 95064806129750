import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import cn from 'classnames';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { propSatisfies, path, cond, always, T, either, prop } from 'ramda';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import ButtonBase from '@material-ui/core/ButtonBase';
import LineIcon from '../line-icon';
import isNotEmpty from '../../../utils/is-not-empty';

UploadDropzone.propTypes = {
  /**
   * JSS additional classes.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired,

  /**
   * CSS classes to apply to the root element of the dropzone.
   * @type {String}
   */
  className: PropTypes.string,

  /**
   * Allow specific types of files.
   * @see https://react-dropzone.netlify.com/#proptypes
   * @type {String|Array.<String>}
   */
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),

  /**
   * `name` attribute of the `input` tag.
   * @see https://react-dropzone.netlify.com/#proptypes
   * @type {String}
   */
  name: PropTypes.string,

  /**
   * Callback called when a file is dropped in the dropzone or was selected
   * from the file browser dialog.
   * @see https://react-dropzone.netlify.com/#proptypes
   * @type {Function}
   */
  onDrop: PropTypes.func
};

const styles = theme => ({
  root: {
    borderColor: theme.palette.secondary.main
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled
  },
  reject: {
    borderColor: theme.palette.error.main
  },
  error: {
    borderColor: theme.palette.error.main
  },
  accept: {
    borderColor: theme.palette.success.main
  },
  icon: {
    color: theme.palette.text.primary
  }
});

const whichDropzoneIcon = cond([
  [propSatisfies(Boolean, 'isDragAccept'), always('check')],
  [
    either(propSatisfies(Boolean, 'error'), propSatisfies(Boolean, 'isDragReject')),
    always('close')
  ],
  [propSatisfies(isNotEmpty, 'acceptedFiles'), always('file-pdf-o')],
  [T, always('cloud-upload')]
]);

const whichDropzoneLabel = cond([
  [propSatisfies(Boolean, 'isDragAccept'), always('You can drop this file here')],
  [propSatisfies(Boolean, 'isDragReject'), always('This file is of an unsupported type')],
  [propSatisfies(isNotEmpty, 'acceptedFiles'), path(['acceptedFiles', 0, 'name'])],
  [propSatisfies(Boolean, 'error'), prop('error')],
  [T, always('Drop documents here or click to select document file')]
]);

function DropzoneIcon({ isDragAccept, isDragReject, acceptedFiles, error, className, size }) {
  return (
    <LineIcon
      className={className}
      icon={whichDropzoneIcon({ isDragAccept, isDragReject, acceptedFiles, error })}
      size={size}
    />
  );
}

function DropzoneLabel({ isDragAccept, isDragReject, acceptedFiles, error, className }) {
  return (
    <>
      <Typography className={className}>
        {whichDropzoneLabel({ isDragAccept, isDragReject, acceptedFiles, error })}
      </Typography>
      {isNotEmpty(acceptedFiles) && !isDragReject && !isDragAccept && !Boolean(error) && (
        <Typography variant="caption" className="mt1">
          (Drop or select another file to replace this document)
        </Typography>
      )}
    </>
  );
}

function UploadDropzone({
  classes,
  className,
  accept,
  onDrop,
  onDropRejected,
  name,
  value,
  disabled,
  onBlur,
  error
}) {
  const hasError = Boolean(error);
  return (
    <FormControl className="flex h-100" fullWidth disabled={disabled} error={hasError}>
      <Dropzone
        multiple={false}
        disabled={disabled}
        accept={accept}
        onDropAccepted={onDrop}
        onDropRejected={onDropRejected}
        inputProps={{
          onBlur
        }}
        name={name}
      >
        {({
          getRootProps,
          getInputProps,
          isDragAccept,
          isDragReject,
          isDragActive,
          acceptedFiles
        }) => {
          return (
            <div
              {...getRootProps()}
              className={cn('flex bw1 b--dashed br2', className, classes.root, {
                [classes.disabled]: disabled,
                [classes.active]: isDragActive,
                [classes.reject]: isDragReject,
                [classes.accept]: isDragAccept,
                [classes.error]: hasError
              })}
            >
              <ButtonBase className="flex flex-column justify-center items-center w-100 pa2">
                <DropzoneIcon
                  className={cn(classes.icon, 'pb1')}
                  error={error}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  acceptedFiles={value ? [value] : acceptedFiles}
                  size="m"
                />
                <input {...getInputProps()} />
                <DropzoneLabel
                  className="tc"
                  error={error}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  acceptedFiles={value ? [value] : acceptedFiles}
                />
              </ButtonBase>
            </div>
          );
        }}
      </Dropzone>
    </FormControl>
  );
}

export default compose(
  withStyles(styles),
  pure
)(UploadDropzone);
