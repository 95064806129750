import createContext from '../core/context/create-context';

const EFILING_TYPE = {
  EAMS: 'EAMS',
  SUM: 'SUM',
  DTM: 'DTM'
};

const EAMS_DOC_TYPE = {
  // TODO: Update with types defined on the API
  DOR: 'dor',
  UF: 'uf'
};

const defaultState = {
  type: null
};

const { withContextProvider, withContextConsumer } = createContext(defaultState);

export {
  EFILING_TYPE,
  EAMS_DOC_TYPE,
  withContextConsumer as withEFilingContextConsumer,
  withContextProvider as withEFilingContextProvider
};
