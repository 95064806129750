import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure } from 'recompose';
import magnifyingGlass from '../../../img/magnifying-glass.png';

CasesTablePlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

function CasesTablePlaceholder({ title, text }) {
  return (
    <div className="flex flex-column justify-center items-center mt6">
      <img src={magnifyingGlass} alt="No filters applied" />
      <div className="outer-space w-40 mt3">
        <span className="b f4">{title}</span>
        {text && <p className="f6">{text}</p>}
      </div>
    </div>
  );
}

const enhance = compose(
  setDisplayName(CasesTablePlaceholder.name),
  setPropTypes({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }),
  pure
);

export default enhance(CasesTablePlaceholder);
