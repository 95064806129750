import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LineIcon from '../line-icon/line-icon';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

class PlusCheckCell extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    tooltip: PropTypes.string.isRequired
  };

  static defaultProps = {
    checked: false,
    loading: false
  };

  handleClick = event => {
    const onClick = this.props.onClick;
    // Prevent clicking on row table by stopping event propagation
    event.stopPropagation();
    return onClick && onClick(event);
  };

  render() {
    const { checked, tooltip, loading } = this.props;
    return checked ? (
      <LineIcon icon="check" size="s" />
    ) : loading ? (
      <CircularProgress color="primary" thickness={4} size={20} />
    ) : (
      <Tooltip title={tooltip}>
        <LineIcon icon="plus" size="s" onClick={this.handleClick} />
      </Tooltip>
    );
  }
}

export default PlusCheckCell;
