import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure, lifecycle } from 'recompose';
import { withWizardContextProvider, withWizardContextConsumer } from './wizard-context';
import { when, always, nth, isNil } from 'ramda';

const propTypes = {
  onFinished: PropTypes.func
};

const childAtOrNull = (index, children) =>
  compose(
    when(isNil, always(null)),
    nth(index),
    React.Children.toArray
  )(children);

function Wizard({
  context: {
    state: { currentStep }
  },
  children
}) {
  return childAtOrNull(currentStep, children);
}

Wizard.propTypes = propTypes;

const enhance = compose(
  setDisplayName('Wizard'),
  setPropTypes(propTypes),
  withWizardContextProvider,
  withWizardContextConsumer(context => ({ context })),
  lifecycle({
    componentDidMount() {
      const {
        context: { updateContext },
        children
      } = this.props;
      return updateContext(() => ({
        stepsCount: React.Children.count(children)
      }));
    },
    componentWillReceiveProps(nextProps) {
      const {
        context: {
          state: { currentStep }
        },
        children
      } = nextProps;

      if (currentStep >= React.Children.count(children)) {
        this.props.onFinished();
      }
    }
  }),
  pure
);

const enhancedWizard = enhance(Wizard);

export { enhancedWizard as Wizard };
