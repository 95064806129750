import { compose, unless, isNil, evolve, reject, toUpper } from 'ramda';
import parseDate from 'date-fns/parse';
import isBlank from '../../../utils/is-blank';
import prefixWithADJ from './adj-prefix';

/**
 * Parses a stringified `YYYY-MM-DD` date and returns its Epoch time
 * value in milliseconds.
 *
 * @function
 * @param {String} The date to parse as a string
 * @returns {Number} Epoch time in milliseconds
 */
const toEpochTime = unless(
  isNil,
  compose(
    date => date.getTime(),
    parseDate
  )
);

/**
 * Generates a new object from the given `filters` object where `adjCaseNumber` property
 * is prefixed with "ADJ" and date properties (`doi` and `dob`) are converted to epoch time (milliseconds).
 *
 * @function
 * @param {Object} filters The filters descriptor object.
 * @returns {Object} The modified (evolved) filters.
 */
const evolveFilters = compose(
  reject(isBlank),
  evolve({
    doi: toEpochTime,
    dob: toEpochTime,
    adjCaseNumber: compose(
      unless(isNil, toUpper),
      prefixWithADJ
    )
  })
);

export default evolveFilters;
