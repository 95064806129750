import React from 'react';
import pure from 'recompose/pure';
import { cond, equals, always, T } from 'ramda';
import PropTypes from 'prop-types';

DocumentSubmissionTypeCell.propTypes = {
  /**
   * Type of document submission. Each type will
   * be mapped to a human readable description.
   * @type {String}
   */
  value: PropTypes.oneOf(['DTM', 'EAMS', 'SUM'])
};

/**
 * Maps types of filing/submission to displayable, user friendly names.
 * @function
 * @param {String} type The code of the filing type
 * @returns {String} A readable name for the given `type` code or `-` (a dash)
 *  if unknown.
 */
const typeNameFor = cond([
  [equals('DTM'), always('Mailing')],
  [equals('EAMS'), always('EAMS')],
  [equals('SUM'), always('Mailing')],
  [T, always('-')]
]);

function DocumentSubmissionTypeCell({ value }) {
  return <span>{typeNameFor(value)}</span>;
}

export default pure(DocumentSubmissionTypeCell);
