import PropTypes from 'prop-types';
import {
  unless,
  always,
  both,
  isNil,
  is,
  pluck,
  compose,
  uniq,
  partition,
  propEq,
  ifElse,
  map
} from 'ramda';
import { withHandlers, setPropTypes } from 'recompose';
import { withWizardContextConsumer } from '../core/wizard/wizard-context';
import { withEFilingContextConsumer } from './e-filing-context';
import { withIWProfileContextConsumer } from '../injured-worker-profile/iwp-context';
import withSaveDraftMutation from './with-save-draft-mutation';
import isDefined from '../../utils/is-defined';
import isBlank from '../../utils/is-blank';

const propTypes = {
  setLoading: PropTypes.func,
  IWProfileContext: PropTypes.shape({
    state: PropTypes.shape({
      selectedRelatedCase: PropTypes.shape({
        id: PropTypes.any.isRequired
      })
    }).isRequired
  }).isRequired,
  eFilingContext: PropTypes.shape({
    state: PropTypes.shape({
      error: PropTypes.oneOfType([PropTypes.shape({ message: PropTypes.string }), PropTypes.bool])
    }).isRequired,
    updateContext: PropTypes.func.isRequired
  }).isRequired,
  wizardContext: PropTypes.shape({
    updateContext: PropTypes.func.isRequired
  }).isRequired
};

/**
 * Split an array containing both case and non case document participants into two
 * arrays, effectively separating both types. The first array will contain `DocumentParticipantRole`
 * items while the second array will contain `NonCaseDocumentParticipant` items, exclusively.
 *
 * @function
 * @example
 *
 *  partitionParticipants([{ id: 42, __typename: 'DocumentParticipantRole' }, { id: 33, __typename: 'NonCaseDocumentParticipant' }]);
 *  // [[ { id: 42, __typename: 'DocumentParticipantRole' } ], [ { id: 33, __typename: 'NonCaseDocumentParticipant' } ]]
 *
 * @param {Array.<Object>} participants A list of mixed participants (elements must contain a `__typename` property).
 * @returns {Array.<Array.<Object>>} An array containing two arrays, the first of which will contain
 *  `DocumentParticipantRole`, while the second one will contain `NonCaseDocumentParticipant`.
 */
const partitionParticipants = unless(
  isNil,
  partition(propEq('__typename', 'DocumentParticipantRole'))
);

/**
 * Extracts the value of `id` from each object in a list and returns them in an array
 * containing no duplicates.
 *
 * @function
 * @example
 *
 *  extractIds([{ id: 2 }, { id: 3 }, { id: 2 }]) // [2, 3]
 *
 * @param {Array.<Object>} list An array of objects containig an `id` property
 * @returns {Array.<*>} An array of unique ids extracted from `list`
 */
const extractIds = compose(
  uniq,
  pluck('id')
);

const extractParticipantIds = ifElse(
  isBlank,
  // If input is `null`, `undefined` or an empty array
  // return a new empty array each time...
  () => [],
  // ...otherwise, split participants and pluck their ids
  compose(
    map(extractIds),
    partitionParticipants
  )
);

/**
 * Determines whether the current `variables` holds a `file` that needs
 * to be uploaded as part of the on going filing submission. A file
 * does NOT need to be uploaded if it was already uploaded in a previous save
 * draft call.
 * @param {Object} variables The current mutation variables
 * @param {Object} state The previously sent variables context
 * @returns {Boolean} `true` if the given `file` was already uploaded;
 *  `false`, otherwise.
 */
const shouldUploadFile = (variables, state) =>
  variables.file !== undefined &&
  // File should not be empty
  variables.file.size > 0 &&
  (state.filingId === undefined || state.file !== variables.file);

const isObject = both(isDefined, is(Object));

export default compose(
  withWizardContextConsumer(context => ({ wizardContext: context })),
  withEFilingContextConsumer(context => ({ eFilingContext: context })),
  withIWProfileContextConsumer(context => ({ IWProfileContext: context })),
  withSaveDraftMutation,
  setPropTypes(propTypes),
  withHandlers({
    saveEFilingDraft: ({
      saveDraft,
      wizardContext: { updateContext: updateWizardContext },
      IWProfileContext: {
        state: {
          selectedRelatedCase: { id: caseId }
        }
      },
      eFilingContext: { state, updateContext: updateEFilingContext }
    }) => async variables => {
      const draftInput = {
        ...state,
        ...variables,
        caseId
      };
      try {
        updateWizardContext(() => ({ loading: true }));
        // Split selected participants ids into actual document participants
        // and custom non case participants
        const [caseParticipantIds, nonCaseParticipantIds] = extractParticipantIds(
          draftInput.caseParticipantRoles
        );
        const { id: filingId, filePageCount } = await saveDraft({
          ...draftInput,
          file: shouldUploadFile(variables, state) ? draftInput.file : undefined,
          documentTypeId: isObject(draftInput.documentType)
            ? draftInput.documentType.value.id
            : undefined,
          documentTitleId: isObject(draftInput.documentTitle)
            ? draftInput.documentTitle.value.id
            : undefined,
          caseParticipantIds,
          nonCaseParticipantIds
        });
        updateEFilingContext(() => ({
          filingId,
          // The file page count is not returned on each call
          // to `saveDraft`, since not all calls upload an actual file.
          // If none is returned, keep the value present on the state
          filePageCount: isNil(filePageCount) ? state.filePageCount : filePageCount,
          ...variables
        }));
      } finally {
        updateWizardContext(() => ({ loading: false }));
      }
    }
  }),
  withHandlers({
    nextStep: ({
      saveEFilingDraft,
      wizardContext: { updateContext: updateWizardContext }
    }) => async variables => {
      try {
        await saveEFilingDraft(variables);
        updateWizardContext(({ currentStep }) => ({
          error: false,
          currentStep: currentStep + 1
        }));
      } catch (err) {
        // Set an error on the wizard context - this will trigger a re-render
        // of current step and show an appropriate alert message
        updateWizardContext(() => ({ error: err }));
      }
    },
    previousStep: ({
      eFilingContext: { updateContext: updateEFilingContext },
      wizardContext: { updateContext: updateWizardContext }
    }) => async variables => {
      // Update e-filing context with the current form state (regardless of its validity)
      updateEFilingContext(always(variables));
      updateWizardContext(({ currentStep }) => ({
        currentStep: currentStep - 1
      }));
    }
  })
);
