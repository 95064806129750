import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import DataTable from '../../core/table';

IWPParticipantsTable.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      adjCaseNum: PropTypes.string,
      participantName: PropTypes.string,
      role: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  className: PropTypes.string
};

const columns = [
  { Header: 'Participant Role', accessor: 'role' },
  { Header: 'Participant Name', accessor: 'name' },
  { Header: 'Address', accessor: 'address' },
  { Header: 'Phone Number', accessor: 'phone' }
];

export function IWPParticipantsTable({ participants, loading, error, className }) {
  return (
    <DataTable
      data={participants}
      loading={loading}
      className={className}
      columns={columns}
      theme="light"
      showPagination={false}
      minRows={5}
      defaultPageSize={40}
      noDataText={
        error
          ? 'There was an error retrieving participants from EAMS.'
          : loading
          ? 'Searching participants...'
          : isEmpty(participants)
          ? 'No participants found for selected case.'
          : ''
      }
    />
  );
}

export default memo(IWPParticipantsTable);
