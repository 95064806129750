import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, withHandlers, pure, setPropTypes, withState } from 'recompose';
import './page-header-action.scss';

const propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
};

function PageHeaderActionComponent({ isSelected, onClick, children, icon }) {
  return (
    <div className="tr dib">
      <div className="flex">
        <button
          onClick={onClick}
          className={`action-icon pa0 bn tr pointer outline-0 flex justify-center blue ${
            isSelected ? 'bg-white' : 'bg-transparent'
          }`}
        >
          {icon}
        </button>
      </div>
    </div>
  );
}

export const PageHeaderAction = compose(
  setDisplayName('PageHeaderAction'),
  setPropTypes(propTypes),
  pure
)(PageHeaderActionComponent);

export const ActionType = Object.freeze({
  HISTORY: 1,
  FILTER: 2
});

export const withActionHandler = (defaultActionType = null) =>
  compose(
    withState('currentAction', 'setCurrentAction', defaultActionType),
    withHandlers({
      onActionClick: ({ currentAction, setCurrentAction }) => actionName => {
        const nextAction = actionName === currentAction ? null : actionName;
        return setCurrentAction(nextAction);
      }
    })
  );

export default { PageHeaderAction, ActionType, withActionHandler };
