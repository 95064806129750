import React from 'react';
import PropTypes from 'prop-types';
import {
  pure,
  compose,
  withProps,
  withHandlers,
  defaultProps,
  setDisplayName,
  setPropTypes,
  branch,
  renderNothing,
  lifecycle
} from 'recompose';
import { complement, isNil, propSatisfies, pathEq, filter, pluck, all, unapply } from 'ramda';
import Button from '@material-ui/core/Button';
import { graphql } from 'react-apollo';
import deepEqual from 'fast-deep-equal';
import caseParticipantRolesQuery from './case-participant-roles.graphql';
import DataTable, { selectTable } from '../../core/table';
import safeConcat from '../../../utils/safe-concat';

const propTypes = {
  caseId: PropTypes.string.isRequired,
  defaultCaseParticipantRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  ),
  className: PropTypes.string,
  onSelectedCaseParticipantRolesChange: PropTypes.func
};

const withData = graphql(caseParticipantRolesQuery, {
  options: ({ caseId, filingId }) => ({
    variables: { caseId, filingId }
  })
});

/**
 * Given a `list` of objects with an `id` property and a set of `id` values,
 * discard all items which do not contain an `id` present in `ids`.
 * @param {Set<String|Number>} ids A set of identifiers
 * @param {Array<Object>} list Objects containing an `id` property
 * @returns {Array<Object>} A copy of the original list containing
 *  only elements with an `id` present in `ids`.
 */
const filterObjWithIds = (setOfIds, list) =>
  isNil(list) ? list : filter(propSatisfies(id => setOfIds.has(id), 'id'), list);

/**
 * Extracts the value of `id` from each object in a list and returns them in an array.
 *
 * @function
 * @param {Array.<Object>} list An array of objects containing an `id` property
 * @returns {Array.<*>} An array of ids extracted from `list`
 */
const extractIds = pluck('id');

/**
 * Checks if all given arguments are falsy or not.
 *
 * @function
 * @param {...*} args The arguments to check
 * @returns {Boolean} `true` if all arguments are falsy; `false`, otherwise.
 */
const allFalsy = unapply(all(complement(Boolean)));

const renderIfNonCaseDocumentParticipant = branch(
  complement(pathEq(['original', '__typename'], 'NonCaseDocumentParticipant')),
  renderNothing
);

const enhance = compose(
  setPropTypes(propTypes),
  setDisplayName('CaseParticipantRolesTable'),
  withData,
  defaultProps({
    data: {},
    defaultCaseParticipantRoles: []
  }),
  withProps(
    ({
      data: { documentParticipantRoles, nonCaseDocumentParticipants, loading, error },
      defaultCaseParticipantRoles,
      onEdit
    }) => {
      return {
        columns: [
          { Header: 'Case Participant Role', className: 'tl', accessor: 'name' },
          {
            sortable: false,
            resizable: false,
            className: 'flex justify-center items-center',
            // Make EDIT cell's width small enough to contain the action button
            // with no extra padding
            maxWidth: 64,
            Cell: renderIfNonCaseDocumentParticipant(row => (
              <Button
                color="secondary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  return onEdit && onEdit(row.original);
                }}
              >
                Edit
              </Button>
            ))
          }
        ],
        data: loading
          ? undefined
          : // Join non case participant and document participant recipients in
            // the same list, making sure we show non case/custom recipients first
            safeConcat(nonCaseDocumentParticipants, documentParticipantRoles),
        loading,
        showPagination: true,
        defaultPageSize: 8,
        rowsPerPageText: 'Recipients per page',
        defaultSelectedIds: extractIds(defaultCaseParticipantRoles),
        noDataText: error
          ? 'There was an error fetching participants'
          : loading
          ? 'Fetching participants...'
          : 'No case participants here'
      };
    }
  ),
  withHandlers({
    onSelectedIdsChange: ({ onSelectedCaseParticipantRolesChange, data }) => selectedIds => {
      return (
        // Call callback with full participant roles instances
        // instead of just their ids
        onSelectedCaseParticipantRolesChange &&
        onSelectedCaseParticipantRolesChange(filterObjWithIds(selectedIds, data))
      );
    }
  }),
  selectTable,
  withHandlers({
    getTrProps: ({ data, setSelectedIDs, setSelectAll, selectedIDs, onChange }) => (
      state,
      rowInfo
    ) => {
      return {
        // Make case participant roles selectable by clicking on row
        onClick: e => {
          // Do not select rows is clicking on action button
          e.stopPropagation();
          e.preventDefault();
          const id = rowInfo.original.id;
          const copy = new Set(selectedIDs);
          selectedIDs.has(id) ? copy.delete(id) : copy.add(id);
          setSelectedIDs(copy);
          onChange && onChange(copy);
          return setSelectAll(copy.size === data.length);
        }
      };
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        loading,
        error,
        defaultSelectedIds,
        setSelectedIDs,
        onSelectedIdsChange
      } = this.props;
      if (allFalsy(loading, error, deepEqual(defaultSelectedIds, prevProps.defaultSelectedIds))) {
        const selectedIds = new Set(defaultSelectedIds);
        setSelectedIDs(selectedIds);
        return onSelectedIdsChange(new Set(defaultSelectedIds));
      }
    }
  }),
  pure
);

export default enhance(DataTable);
