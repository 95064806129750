import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import cn from 'classnames';
import Modal from '../modal';
import { DefaultButton, AlternateButton } from '../button';
import './alert.scss';

export const MESSAGE_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning'
};

Alert.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.node,
  text: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(MESSAGE_TYPE)).isRequired,
  onOkClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func
};

Alert.defaultProps = {
  show: false
};

const iconClass = {
  [MESSAGE_TYPE.success]: 'la-check-circle',
  [MESSAGE_TYPE.warning]: 'la-exclamation-triangle',
  [MESSAGE_TYPE.error]: 'la-exclamation-circle'
};
Object.keys(iconClass).forEach(key => (iconClass[key] = `la ${iconClass[key]}`));

export function Alert({ title, show, text, onOkClick, onCancelClick, type }) {
  return (
    <Modal className={`alert ${type}`} open={show} onClose={onCancelClick}>
      <div
        aria-hidden={show ? 'false' : 'true'}
        className="center flex flex-column h-100 items-center justify-around w-100 pa4"
      >
        <i className={cn(iconClass[type], 'icon o-50 br-100 pa3 m')} />
        <div className="title f2 fw6 mt4">{title}</div>
        <div className="text f-ns fw3 tc">{text}</div>
        <div className="flex items-center justify-center mt4">
          {onCancelClick && (
            <AlternateButton onClick={onCancelClick} className="alert-button button-cancel">
              CANCEL
            </AlternateButton>
          )}
          <DefaultButton onClick={onOkClick} className="alert-button button-ok">
            OK
          </DefaultButton>
        </div>
      </div>
    </Modal>
  );
}

export default pure(Alert);
