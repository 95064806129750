import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose, pure, setDisplayName, withProps, setPropTypes } from 'recompose';
import LineIcon from '../core/line-icon';
import Button from '../core/button/mui-button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import DialogIcon from '../core/alert/dialog-icon';
import { withStyles } from '@material-ui/core/styles';

WizardStepError.propTypes = {
  /**
   * Event fired after user closes the error dialog.
   * @type {Function}
   */
  onDone: PropTypes.func.isRequired,

  /**
   * The title displayed at the top of the dialog.
   * @type {String}
   */
  title: PropTypes.string.isRequired,

  /**
   * Description of the raised error and possible causes and fixes.
   * @type {String}
   */
  message: PropTypes.string,

  /**
   * Technical details related to the error. This will be originally
   * hidden in a collapsible panel. Any relevant, more important
   * information should be included in the `message` rather than here.
   * @type {String}
   */
  details: PropTypes.string,

  /**
   * JSS additional classes.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  actions: {
    backgroundColor: theme.palette.blue[100],
    margin: 0
  }
});

function WizardStepError({ onDone, title, message, details, classes }) {
  return (
    <div className="flex flex-column h-100">
      <DialogTitle>
        <DialogIcon type="error" />
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogContent>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<LineIcon icon="angle-down" />}>
            <Typography>Technical details</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <code>{details}</code>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </DialogContent>
      <DialogActions className={cn(classes.actions, 'pv3')} disableActionSpacing={true}>
        <Button className="mh2" color="primary" onClick={onDone}>
          Done
        </Button>
      </DialogActions>
    </div>
  );
}

const enhance = compose(
  setDisplayName(WizardStepError.name),
  setPropTypes({
    onDone: PropTypes.func.isRequired,
    error: PropTypes.shape({ message: PropTypes.string }).isRequired
  }),
  withProps(({ error }) => {
    return {
      title: 'There was an error updating your submission',
      message:
        'This could be due to a temporary connection issue, so please wait a few moments and retry the operation. If the problem persists, contact the system administrator.',
      details: error.message
    };
  }),
  withStyles(styles),
  pure
);

export default enhance(WizardStepError);
