import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ifElse, either, pathEq, F } from 'ramda';
import AlertDialog from '../alert/dialog';
import isDefined from '../../../utils/is-defined';

/**
 * Checks whether the given GraphQL `error` object represents
 * a non-authorized data access or not (probably due to expired credentials).
 *
 * @function
 * @param {Object} error The `error` object as present in a GraphQL response
 * @returns {Boolean} `true` if `error` defines a non-authorized API access; `false`,
 *  otherwise.
 */
const isUnauthorized = ifElse(
  isDefined,
  either(pathEq(['networkError', 'statusCode'], 401), pathEq(['graphQLErrors', '0', 'code'], 1)),
  F
);

const shouldOpenDialog = props => props.open && isDefined(props.error);

class DataErrorAlert extends PureComponent {
  static propTypes = {
    /**
     * Whether to display this alert or not. The dialog won't
     * open if `error` is either `null` or `undefined`, regardless
     * of the value of this prop.
     * @type {Boolean}
     */
    open: PropTypes.bool,
    /**
     * Error descriptor object as returned by a Apollo Client
     * as a result of a GraphQL query or mutation.
     *
     * @type {Object}
     */
    error: PropTypes.shape({
      networkError: PropTypes.shape({
        statusCode: PropTypes.number
      }),
      graphQLErrors: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.number
        })
      )
    })
  };

  static defaultProps = {
    open: false
  };

  static getDerivedStateFromProps(props, state) {
    // The alert won't open twice for the same `error` after it was dismissed
    return props.error === state.error
      ? null
      : { alertOpen: shouldOpenDialog(props), error: props.error };
  }

  state = {
    alertOpen: shouldOpenDialog(this.props)
  };

  closeAlertDialog = () => {
    return this.setState({ alertOpen: false });
  };

  handleAlertDialogAccept = () => {
    const { error, history } = this.props;
    return isUnauthorized(error)
      ? // Logout users if a user authorization problem was returned as result
        // of a GraphQL query or mutation
        history.push('/logout')
      : // ... or close alert dialog if the operation failed
        this.closeAlertDialog();
  };

  render() {
    const error = this.props.error;
    const isUnauthorizedError = isUnauthorized(error);
    return (
      <AlertDialog
        open={this.state.alertOpen}
        keepMounted={false}
        onClose={this.closeAlertDialog}
        onAccept={this.handleAlertDialogAccept}
        type="error"
        title={
          isUnauthorizedError
            ? 'Your credentials expired'
            : 'There was an error processing your request'
        }
        details={error && error.message}
      >
        {isUnauthorizedError
          ? 'Please log in again.'
          : 'This might be a temporary connection issue. Please try again or contact the system administrator if the issue persists.'}
      </AlertDialog>
    );
  }
}

export default withRouter(DataErrorAlert);
