import PropTypes from 'prop-types';
import { compose, withProps, pure, setPropTypes, setDisplayName } from 'recompose';
import { graphql } from 'react-apollo';
import documentTypesQuery from './document-types.graphql';

const propTypes = {
  filingType: PropTypes.string.isRequired
};

export default mapProps =>
  compose(
    setDisplayName('withDocumentTypes'),
    setPropTypes(propTypes),
    graphql(documentTypesQuery, {
      options: ({ filingType }) => ({
        variables: { filingType }
      })
    }),
    withProps(({ data: { documentTypes, loading, error }, onChange, value }) =>
      mapProps({
        documentTypes: loading || error ? [] : documentTypes,
        loading,
        error
      })
    ),
    pure
  );
