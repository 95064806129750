import PropTypes from 'prop-types';
import { map } from 'ramda';
import { compose, setDisplayName, setPropTypes, pure, withProps } from 'recompose';
import Dropdown from '../../core/dropdown/dropdown';

const propTypes = {
  documentTitles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  ),
  loading: PropTypes.bool
};

const toDropdownOption = docTitle => ({
  value: docTitle.id,
  label: docTitle.title
});
const createDropdownOptions = map(toDropdownOption);

const enhance = compose(
  setDisplayName('DocumentTitleDropdown'),
  setPropTypes(propTypes),
  withProps(({ documentTitles, loading }) => ({
    placeholder: 'None',
    isLoading: loading,
    options: createDropdownOptions(documentTitles)
  })),
  pure
);

export default enhance(Dropdown);
