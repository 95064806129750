import React, { PureComponent } from 'react';
import { objOf } from 'ramda';

const wrapSelf = objOf('context');

function createContext(defaultState) {
  const { Provider, Consumer } = React.createContext(defaultState);

  /**
   * HOC that wraps a component with a React Context provider.
   *
   * @param {Object} defaultValue - The default state of the context
   */
  const withContextProvider = WrappedComponent =>
    class ContextProvider extends PureComponent {
      state = {
        state: defaultState,
        updateContext: this.updateContext.bind(this),
        clearContext: this.clearContext.bind(this)
      };

      clearContext() {
        return this.setState({ state: {} });
      }

      updateContext(updater) {
        return this.setState(({ state }) => ({
          state: { ...state, ...updater(state) }
        }));
      }

      render() {
        return (
          <Provider value={this.state}>
            <WrappedComponent {...this.props} />
          </Provider>
        );
      }
    };

  /**
   * HOC that wraps a component with a React Context consumer.
   * Receives a function to map the context to custom props.
   *
   * @param {Function} mapContextToProps - A function to be called with the
   *  context. Should return an object with prop(s) to be passed to the base component.
   */
  const withContextConsumer = (mapContextToProps = wrapSelf) => WrappedComponent => props => (
    <Consumer>
      {context => {
        const contextToProps = mapContextToProps(context);
        return <WrappedComponent {...props} {...contextToProps} />;
      }}
    </Consumer>
  );

  return { withContextProvider, withContextConsumer, Provider, Consumer };
}

export default createContext;
