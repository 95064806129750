import { create } from 'jss';
import { jssPreset } from '@material-ui/core/styles';

// We create a dummy comment node to serve as a placeholder
// where all material-ui styles will be defined.
// This changes CSS injection order so third-party styles (like tachyons) have priority
// over material-ui own base styles.
// @see https://material-ui.com/customization/css-in-js/#js-createcomment
const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);
const jss = create(jssPreset());
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = 'jss-insertion-point';

export default jss;
