import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

FormControlCheckbox.propTypes = {
  /**
   * The control's label.
   * @type {String}
   */
  label: PropTypes.string,

  /**
   * Indicates a validation error on this control.
   * @type {Boolean|String}
   */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

  /**
   * Classes applied to the root `FormControl` element.
   * @type {String}
   */
  className: PropTypes.string,

  /**
   * `FormControl` element properties. Definining `className` and `error`
   * here have precedence over homonymous root properties.
   * @type {Object}
   */
  formControlProps: PropTypes.object
};

function FormControlCheckbox({ error, label, className, formControlProps, ...rest }) {
  return (
    <FormControl className={className} error={Boolean(error)} {...formControlProps}>
      <FormControlLabel control={<Checkbox color="primary" {...rest} />} label={label} />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}

export default pure(FormControlCheckbox);
