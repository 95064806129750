import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure, withHandlers, withProps } from 'recompose';
import { head, find, propEq } from 'ramda';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import IWPLiensPanel from './iwp-liens/iwp-liens-panel';
import IWPOverviewPanel from './iwp-overview/iwp-overview-panel';
import { withIWProfileContextConsumer } from './iwp-context';

const tabs = Object.freeze([
  { value: 1, label: 'overview', component: IWPOverviewPanel },
  { value: 3, label: 'liens', component: IWPLiensPanel }
]);

IWPDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  onTabChange: PropTypes.func,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  relatedCase: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    adjCaseNumber: PropTypes.string.isRequired
  })
};

IWPDetails.defaultProps = {
  selectedTab: head(tabs).value
};

const styles = theme => ({
  tabIndicator: {
    height: 4
  }
});

export function IWPDetails({ classes, onTabChange, selectedTab, relatedCase }) {
  const { component: TabComponent } = find(propEq('value', selectedTab), tabs);
  return (
    <div className="details f6 tl flex-auto flex flex-column">
      <Tabs
        classes={{ indicator: classes.tabIndicator }}
        value={selectedTab}
        onChange={onTabChange}
        indicatorColor="secondary"
        textColor="inherit"
      >
        {tabs.map(t => (
          <Tab key={`tab--${t.value}`} value={t.value} label={t.label} />
        ))}
      </Tabs>
      <TabComponent relatedCase={relatedCase} />
    </div>
  );
}

const enhance = compose(
  setDisplayName(IWPDetails.name),
  withIWProfileContextConsumer(context => ({ context })),
  setPropTypes({
    context: PropTypes.shape({
      updateContext: PropTypes.func.isRequired,
      state: PropTypes.shape({
        selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      }).isRequired
    }).isRequired,
    relatedCase: PropTypes.shape({
      __typename: PropTypes.string.isRequired,
      adjCaseNumber: PropTypes.string.isRequired
    })
  }),
  withProps(({ context: { state: { selectedTab } } }) => {
    return { selectedTab };
  }),
  withHandlers({
    onTabChange: ({ context: { updateContext } }) => (event, selectedTab) => {
      return updateContext(() => ({ selectedTab }));
    }
  }),
  withStyles(styles),
  pure
);

export default enhance(IWPDetails);
