import React, { PureComponent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LineIcon from './components/core/line-icon';
import * as serviceWorker from './service-worker';

/**
 * Latest application build version. Will be shown in the
 * notification snackbar on service worker update.
 * @type {String}
 */
const APP_VERSION = process.env.APP_VERSION;

const styles = theme => ({
  snackbar: {
    margin: theme.spacing.unit
  }
});

/**
 * Registers a service worker to serve assets from local cache in
 * production and displays a notification snackbar when a new version
 * is available.
 * @class
 * @extends PureComponent
 */
class WithServiceWorker extends PureComponent {
  state = {
    openSnackbar: false
  };

  constructor(...args) {
    super(...args);
    serviceWorker.register({
      // Register `onUpdate` callback to show snackbar notification
      // on new version
      onUpdate: this.notifyUpdate
    });
  }

  notifyUpdate = () => {
    return this.setState({ openSnackbar: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    return this.setState({ openSnackbar: false });
  };

  forceReload() {
    // Reload the current page, without using the cache
    window.location.reload(true);
  }

  render() {
    const classes = this.props.classes;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className={classes.snackbar}
        open={this.state.openSnackbar}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={
          <span id="message-id">
            New version <code>{APP_VERSION}</code> available!
          </span>
        }
        action={[
          <Button key="update" color="secondary" size="small" onClick={this.forceReload}>
            UPDATE
          </Button>,
          <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
            <LineIcon icon="close" />
          </IconButton>
        ]}
      />
    );
  }
}

export default withStyles(styles)(WithServiceWorker);
