import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import pure from 'recompose/pure';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  header: {
    height: '6.5rem',
    backgroundColor: theme.palette.blue[100]
  }
});

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node
};

export function PageHeader({ classes, breadcrumbs, label, children }) {
  return (
    <header className={cn(classes.header, 'flex items-stretch justify-between')}>
      <div className="ph4 pv3 flex flex-column items-start justify-between">
        <Typography variant="caption">{breadcrumbs}</Typography>
        <Typography variant="h5" className="ma0 normal">
          {label}
        </Typography>
      </div>
      <div className="ph3 flex flex-column justify-end">
        <div className="flex flex-row items-center relative">{children}</div>
      </div>
    </header>
  );
}

export default compose(
  withStyles(styles),
  pure
)(PageHeader);
