import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import RelatedCasesTable from './iwp-related-cases-table';

IWPRelatedCases.propTypes = {
  currentCase: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    adjCaseNumber: PropTypes.string.isRequired
  }),
  className: PropTypes.string,
  open: PropTypes.bool,
  onRelatedCaseSelected: PropTypes.func.isRequired,
  selectedRelatedCase: PropTypes.shape({
    adjCaseNumber: PropTypes.string.isRequired
  })
};

export function IWPRelatedCases({
  currentCase,
  className,
  open,
  onRelatedCaseSelected,
  selectedRelatedCase
}) {
  return (
    <div className={cn('related-cases bg-light-blue ph4', className)}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <RelatedCasesTable
          className="pv3"
          currentCase={currentCase}
          selectedRelatedCase={selectedRelatedCase}
          onRelatedCaseSelected={onRelatedCaseSelected}
        />
      </Collapse>
    </div>
  );
}

export default memo(IWPRelatedCases);
