import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  setDisplayName,
  withHandlers,
  withState,
  withProps,
  defaultProps,
  pure
} from 'recompose';
import cn from 'classnames';
import { prop, objOf, path } from 'ramda';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import LineIcon from '../../components/core/line-icon';
import Modal from '../../components/core/modal';
import PageHeader from '../../components/common/page-header';
import { ActionType, withActionHandler } from '../../components/common/page-header-action';
import ResizablePanels from '../../components/common/resizable-panels';
import CasesFiltersForm, { CasesFiltersSelection } from '../../components/cases/cases-filters';
import InjuredWorkerProfile from '../../components/injured-worker-profile/injured-worker-profile';
import { withIWProfileContextConsumer } from '../../components/injured-worker-profile/iwp-context';
import MyCasesTable from './my-cases-table';

MyCasesPage.propTypes = {
  filters: PropTypes.object,
  currentAction: PropTypes.number,
  selectedCase: PropTypes.object,
  panelSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onActionClick: PropTypes.func,
  onFilter: PropTypes.func.isRequired,
  onCaseSelected: PropTypes.func,
  onOutsideClick: PropTypes.func,
  onPanelSizeChanged: PropTypes.func.isRequired
};

/**
 * Width of the drawer when open in pixels.
 * @type {Number}
 */
const DRAWER_WIDTH = 280;

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    width: DRAWER_WIDTH
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '10px 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  'content-right': {
    [theme.breakpoints.up('sm')]: {
      marginRight: -DRAWER_WIDTH
    }
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  'contentShift-right': {
    [theme.breakpoints.up('sm')]: {
      marginRight: 0
    }
  },
  modalCloseIcon: {
    color: theme.palette.grey[200]
  }
});

export function MyCasesPage({
  filters,
  openFilters,
  selectedCase,
  panelSize,
  onActionClick,
  onFilter,
  onCaseSelected,
  onOutsideClick,
  onPanelSizeChanged,
  classes
}) {
  // FIXME: <HistoryAction> button was removed as per request
  // @see https://pragmars.atlassian.net/browse/WEB-240
  return (
    <section className="flex relative w-100 overflow-hidden">
      <div
        className={cn(classes.content, classes['content-right'], {
          [classes.contentShift]: openFilters,
          [classes[`contentShift-right`]]: openFilters
        })}
      >
        <PageHeader breadcrumbs="Home / My Cases" label="My Cases">
          <Hidden smDown>
            <CasesFiltersSelection
              className="mr3"
              filters={filters}
              onEdit={() => onActionClick(ActionType.FILTER)}
            />
          </Hidden>
          <Tooltip enterDelay={1000} title="Open filters">
            <IconButton
              className="mb1"
              color="secondary"
              onClick={() => onActionClick(ActionType.FILTER)}
            >
              <LineIcon icon="filter" />
            </IconButton>
          </Tooltip>
        </PageHeader>
        <div className="flex relative vh-100">
          <ResizablePanels panelSize={panelSize} onPanelSizeChanged={onPanelSizeChanged}>
            <div className="w-100 relative overflow-x-hidden ph3 pb3 pt2">
              <MyCasesTable
                selectedCase={selectedCase}
                onCaseSelected={onCaseSelected}
                filters={filters}
                csvFilename="my-cases.csv"
              />
            </div>
            <InjuredWorkerProfile currentCase={selectedCase} />
          </ResizablePanels>
        </div>
      </div>
      <Hidden xsDown>
        <Drawer
          variant="persistent"
          anchor="right"
          open={openFilters}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={onOutsideClick}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={onOutsideClick}>
              <LineIcon icon="angle-left" />
            </IconButton>
          </div>
          <CasesFiltersForm
            onSubmit={onFilter}
            primary={['adjCaseNumber', 'ssn', 'firstName', 'lastName']}
          />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <Modal
          open={openFilters}
          onClose={onOutsideClick}
          title="Filter cases"
          classes={{
            title: 'pr2',
            closeIcon: classes.modalCloseIcons
          }}
          fullScreen={true}
        >
          <CasesFiltersForm
            onSubmit={onFilter}
            primary={['adjCaseNumber', 'ssn', 'firstName', 'lastName']}
          />
        </Modal>
      </Hidden>
    </section>
  );
}

const enhance = compose(
  setDisplayName(MyCasesPage.name),
  withIWProfileContextConsumer(objOf('context')),
  defaultProps({ filters: {} }),
  withActionHandler(null),
  withState('filters', 'setFilters', prop('filters')),
  withState('selectedCase', 'setSelectedCase', path(['context', 'state', 'selectedCase'])),
  withHandlers({
    onFilter: ({ setFilters, setCurrentAction }) => newFilters => {
      // Update filters passed to cases table
      // (will most likely trigger a new GraphQL query to fetch matching cases)
      setFilters(newFilters);

      // Close filters panel
      return setCurrentAction(null);
    },
    onCaseSelected: ({ setSelectedCase, context: { updateContext } }) => selectedCase => {
      setSelectedCase(selectedCase);
      updateContext(() => ({ selectedCase }));
    },
    onOutsideClick: ({ setCurrentAction }) => () => setCurrentAction(null),
    onPanelSizeChanged: ({ context: { updateContext } }) => size => {
      return updateContext(() => ({ IWPanelSize: size }));
    }
  }),
  withProps(({ currentAction, context: { state: { IWPanelSize } } }) => ({
    panelSize: IWPanelSize,
    openFilters: ActionType.FILTER === currentAction
  })),
  withStyles(styles),
  pure
);

export default enhance(MyCasesPage);
