import { both } from 'ramda';
import isDefined from './is-defined';
import isNotEmpty from './is-not-empty';

/**
 * Checks whether the given value is neither empty,
 * `null` or `undefined`. The definition for "empty"
 * depends on the type of `value`.
 *
 * @function
 * @see https://ramdajs.com/docs/#isEmpty
 * @see https://ramdajs.com/docs/#isNil
 * @param {*} value The value to check
 * @returns {Boolean} `false` if `value` is empty, `null` or
 *  `undefined`; `true`, otherwise.
 */
const isNotBlank = both(isNotEmpty, isDefined);

export default isNotBlank;
