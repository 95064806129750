import { tryCatch, F, compose, equals, not } from 'ramda';
import jwtDecode from 'jwt-decode';
import throwWith from '../../utils/throw-with';

/**
 * Local storage key name for the authentication token.
 * @type {String}
 */
const AUTH_TOKEN_KEY = '532-auth-token';

/**
 * Checks whether the given `token` is a valid JWT or not.
 * This does NOT verify its content.
 * @param {String} token The token to check
 * @returns {Boolean} `true` is `token` is a valid JWT; `false` otherwise
 */
const isTokenValid = compose(
  not,
  equals(false),
  tryCatch(jwtDecode, F)
);

/**
 * Stores the given authentication `token` in Local Storage.
 * @param {String} token JWT token to store
 */
export const setAuthToken = token => localStorage.setItem(AUTH_TOKEN_KEY, token);

/**
 * Fetches the authentication token from Local Storage.
 * @returns {String} The requested token or `undefined` if not set.
 */
export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY) || undefined;

/**
 * Deletes the authentication token from Local Storage.
 * @returns {undefined}
 */
export const removeAuthToken = () => localStorage.removeItem(AUTH_TOKEN_KEY);

/**
 * Checks whether there's a well formed JWT token stored in Local Storage.
 * The existing token might not be valid.
 * @returns {Boolean} `true` is there's a proper JWT stored; `false`, otherwise.
 */
export const isAuthenticated = compose(
  isTokenValid,
  getAuthToken
);

/**
 * Performs authentication against a remote service, returning a JWT
 * token upon success.
 * @param {String} username The user provided username
 * @param {String} password The user provided password
 * @returns {Promise} Resolves to the authentication token.
 */
export function authenticate(username, password) {
  return fetch('/api/login', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ username, password })
  })
    .then(response => response.json())
    .then(({ token, success, status, message }) =>
      success ? token : throwWith(message, { status })
    )
    .then(token => setAuthToken(token));
}

export default authenticate;
