import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { pure } from 'recompose';

BooleanCell.propTypes = {
  value: PropTypes.any
};

function BooleanCell({ value }) {
  return <span className="i">{isNil(value) ? '—' : value ? 'Yes' : 'No'}</span>;
}

export default pure(BooleanCell);
