import { prop, map, join, trim, compose, isEmpty, reject, either, isNil } from 'ramda';

const labelsOf = map(prop('label'));

const fieldsOf = map(compose(prop('field')));

const removeEmptyOrNil = reject(either(isEmpty, isNil));

const onePerLine = compose(
  join('\n'),
  removeEmptyOrNil
);

const fieldFrom = data => field => {
  const fieldValue = typeof field === 'function' ? field(data) : data[field];
  return isNil(fieldValue) ? '' : trim(fieldValue.toString());
};

const joinLabelsWith = separator =>
  compose(
    join(separator),
    labelsOf
  );

const joinFieldsWith = (separator, data) =>
  compose(
    join(separator),
    map(fieldFrom(data))
  );

const generateRowsUsing = (headers, separator) =>
  compose(
    onePerLine,
    map(entry => joinFieldsWith(separator, entry)(headers))
  );

const buildCSVHeaders = (headers, separator) =>
  isEmpty(headers) ? '' : joinLabelsWith(separator)(headers);

const buildCSVRows = (headers, data, separator) =>
  generateRowsUsing(fieldsOf(headers), separator)(data);

/**
 * Builds an object representing the CSV data.
 *
 * @param {Object[]} headers - The CSV file headers.
 * @param {String} headers[].label - The label the header is going to have on the CSV file.
 * @param {(String|Function)} headers[].field - The object key of the data
 *             elements corresponding to this header or a function that
 *             takes the data object and return the corresponging value.
 * @param {Object[]} data - The CSV file data.
 * @param {String} [separator=,] - The separator to be used on the CSV file.
 * @return {String} The contents of the CSV file.
 */
const buildCSVData = (headers = [], data = [], separator = ',') =>
  onePerLine([buildCSVHeaders(headers, separator), buildCSVRows(headers, data, separator)]);

export default buildCSVData;
