import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import compose from 'recompose/compose';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';

const styles = {
  divider: {
    margin: '5px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '1px',
    overflow: 'hidden'
  }
};

MenuDivider.propTypes = {
  /**
   * JSS custom classes.
   * @type {Object}
   */
  classes: PropTypes.object.isRequired
};

function MenuDivider({ classes, ...rest }) {
  return <Divider {...rest} className={classes.divider} />;
}

export default compose(
  withStyles(styles),
  pure
)(MenuDivider);
