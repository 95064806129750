import React, { PureComponent } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import LinearProgress, { Overlay } from '../../components/core/loading';
import Button from '../../components/core/button/mui-button';
import { PasswordInput } from '../../components/core/input';
import resetPassword from '../../components/auth/reset-password';
import AlertDialog from '../../components/core/alert/dialog';
import PageHeader from '../../components/common/page-header';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(5, 'Password is too short')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    .required('Password confirm is required')
});

class ResetPasswordPage extends PureComponent {
  state = {
    alertOpen: false,
    alertType: 'error',
    alertTitle: undefined,
    alertDetails: undefined,
    alertText: undefined
  };

  closeAlertDialog = () => {
    return this.setState({ alertOpen: false });
  };

  openAlertDialog = (type, { title, text, details }) => {
    return this.setState({
      alertOpen: true,
      alertTitle: title,
      alertType: type,
      alertText: text,
      alertDetails: details
    });
  };

  handleAlertDialogAccept = () => {
    return this.state.alertType === 'success'
      ? // Take user to 'My Cases' if the password reset was successful
        this.props.history.push('/mycases')
      : // ... or close alert dialog if the operation failed
        this.closeAlertDialog();
  };

  async handleSubmit({ password, confirmPassword }) {
    try {
      await resetPassword(password, confirmPassword);
      return this.openAlertDialog('success', {
        title: 'Your password was changed',
        text: "Next time you login, you'll need to use your new password.",
        details: undefined
      });
    } catch (err) {
      return this.openAlertDialog('error', {
        title: 'Something went wrong',
        text:
          'There was an error when trying to update your password. If the error persists try contacting the administrator.',
        details: err.message
      });
    }
  }

  render() {
    const { alertOpen, alertType, alertTitle, alertText, alertDetails } = this.state;
    return (
      <Grid container direction="column">
        <Grid item>
          <AlertDialog
            open={alertOpen}
            onClose={this.closeAlertDialog}
            onAccept={this.handleAlertDialogAccept}
            type={alertType}
            title={alertTitle}
            details={alertDetails}
          >
            {alertText}
          </AlertDialog>
          <PageHeader breadcrumbs="Settings / Reset password" label="Reset password" />
        </Grid>
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={11} md={5}>
              <Typography className="pv4" color="textSecondary">
                Choose a strong password and don't reuse it for other accounts.
              </Typography>
            </Grid>
            <Grid item xs={11} md={5}>
              <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                onSubmit={async (values, actions) => {
                  await this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleBlur, handleChange, isSubmitting }) => (
                  <Card square={true}>
                    <LinearProgress className="h1" when={isSubmitting} />
                    <Overlay className="relative" when={isSubmitting}>
                      <Form noValidate>
                        <CardContent>
                          <PasswordInput
                            inputRef={input => input && input.focus()}
                            name="password"
                            label="New password"
                            labelProps={{
                              className: 'f6'
                            }}
                            formControlProps={{
                              required: true,
                              fullWidth: true
                            }}
                            error={touched.password && errors.password}
                            autoComplete="new-password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <PasswordInput
                            name="confirmPassword"
                            label="Confirm password"
                            labelProps={{
                              className: 'f6'
                            }}
                            error={touched.confirmPassword && errors.confirmPassword}
                            formControlProps={{
                              className: 'mv3',
                              required: true,
                              fullWidth: true
                            }}
                            autoComplete="new-password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </CardContent>
                        <CardActions className="flex justify-center">
                          <Button type="submit" color="primary">
                            Change password
                          </Button>
                        </CardActions>
                      </Form>
                    </Overlay>
                  </Card>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ResetPasswordPage;
