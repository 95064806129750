import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  withHandlers,
  withState,
  setPropTypes,
  defaultProps,
  pure,
  toClass
} from 'recompose';
import { T } from 'ramda';
import Checkbox from './table-checkbox';
import selectTableHOC from 'react-table/lib/hoc/selectTable';

const propTypes = {
  keyField: PropTypes.string,
  /**
   * Determines whether a given row is selectable or not. Unselectable rows
   * will appear with disabled checkboxes.
   * Defaults to always returning `true`.
   * @type {Function}
   */
  isSelectable: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  defaultSelectedIds: PropTypes.arrayOf(PropTypes.any)
};

export default compose(
  setPropTypes(propTypes),
  defaultProps({
    keyField: 'id',
    isSelectable: T,
    defaultSelectedIds: []
  }),
  withState('selectAll', 'setSelectAll', false),
  withState(
    'selectedIDs',
    'setSelectedIDs',
    ({ defaultSelectedIds }) => new Set(defaultSelectedIds)
  ),
  withProps(({ isSelectable }) => ({
    selectType: 'checkbox',
    SelectAllInputComponent: props => <Checkbox {...props} className="absolute absolute--fill" />,
    SelectInputComponent: pure(({ row, ...others }) => {
      // Disallow selecting options that are not selectable
      return (
        <Checkbox
          disabled={!isSelectable(row)}
          row={row}
          {...others}
          className="h-100 items-center"
        />
      );
    })
  })),
  withHandlers({
    getID: ({ keyField }) => row => row[keyField],
    onChange: ({ onSelectedIdsChange }) => selectedIDs => {
      return onSelectedIdsChange && onSelectedIdsChange(selectedIDs);
    }
  }),
  withHandlers({
    resetState: ({ setSelectedIDs, setSelectAll, onChange }) => () => {
      const selectedIDs = new Set();
      setSelectedIDs(selectedIDs);
      setSelectAll(false);
      onChange(selectedIDs);
    },
    isSelected: ({ selectedIDs }) => id => selectedIDs.has(id),
    toggleSelection: ({
      keyField,
      isSelectable,
      selectedIDs,
      setSelectedIDs,
      setSelectAll,
      data,
      onChange
    }) => (id, shiftKey, row) => {
      const copy = new Set(selectedIDs);
      selectedIDs.has(row[keyField]) ? copy.delete(row[keyField]) : copy.add(row[keyField]);
      setSelectedIDs(copy);
      onChange(copy);
      return setSelectAll(copy.size === data.filter(isSelectable).length);
    },
    toggleAll: ({
      getID,
      isSelectable,
      selectAll,
      setSelectAll,
      setSelectedIDs,
      data,
      onChange
    }) => () => {
      const selectedIDs = selectAll
        ? new Set()
        : // Only select those options that are considered selectable
          new Set(data.filter(isSelectable).map(getID));
      setSelectedIDs(selectedIDs);
      onChange(selectedIDs);
      setSelectAll(s => !s);
    }
  }),
  selectTableHOC,
  // selectTable HOC internally uses a ref to it's base component. So it won't work
  // with functional stateless components.
  toClass
);
