import Checkbox from '@material-ui/core/Checkbox';
import mapProps from 'recompose/mapProps';

// Filter out the `selectType` prop passed from the ReactTable `selectTable` HOC
export default mapProps(({ selectType, onClick, onChange, row, id, ...rest }) => ({
  // Remap `onClick` to `onChange` due to react-table not providing
  // an explicit `onChange` handler, while setting a `checked` property.
  // Doing so, triggers a React warning: "You provided a `checked` prop to a form field without an `onChange` handler."
  // @see https://github.com/react-tools/react-table/blob/f55ce620411c619855a2fe2f081407e4f82727b9/src/hoc/selectTable/index.js#L32
  onChange: e => {
    const { shiftKey } = e;
    e.stopPropagation();
    const handleEvent = onChange || onClick;
    // Replicate behavior of default react-table component as suggested here:
    // https://github.com/tannerlinsley/react-table/issues/774#issuecomment-360631190
    return handleEvent(id, shiftKey, row, e);
  },
  color: 'primary',
  id,
  ...rest
}))(Checkbox);
