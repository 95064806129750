import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import DataTable from '../../core/table';

IWPEventsTable.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      generalDescription: PropTypes.string,
      detailedDescription: PropTypes.string,
      date: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  className: PropTypes.string
};

const columns = [
  { Header: 'Event Description', accessor: 'generalDescription' },
  { Header: 'Detail Description', accessor: 'detailedDescription' },
  { Header: 'Event Date', accessor: 'date' }
];

export function IWPEventsTable({ events, loading, error, className }) {
  return (
    <DataTable
      data={events}
      loading={loading}
      className={className}
      columns={columns}
      theme="light"
      showPagination={false}
      minRows={5}
      noDataText={
        error
          ? 'There was an error retrieving events from EAMS.'
          : loading
          ? 'Searching events...'
          : isEmpty(events)
          ? 'No events found for selected case.'
          : ''
      }
    />
  );
}

export default memo(IWPEventsTable);
