import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import { pathSatisfies, eqBy, pathEq, toLower, isNil } from 'ramda';
import { compose, setDisplayName, setPropTypes, withProps, withHandlers } from 'recompose';
import DocumentSubmissionStatusCell from './document-submission-status-cell';
import DocumentSubmissionTypeCell from './document-submission-type-cell';
import { withIWProfileContextConsumer } from '../../injured-worker-profile/iwp-context';
import withEFilingModal from '../../e-filing/with-efiling-modal';
import DataTable, { selectTable } from '../../core/table';
import { withEFilingContextConsumer } from '../e-filing-context';

const propTypes = {
  filings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.number,
      lastSavedOn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      filingType: PropTypes.string,
      adjCaseNumber: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      documentTitle: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  className: PropTypes.string
};

const columns = [
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => <DocumentSubmissionStatusCell value={value} />
  },
  {
    Header: 'Form Type',
    accessor: 'filingType',
    Cell: ({ value }) => <DocumentSubmissionTypeCell value={value} />
  },
  { Header: 'Document title', accessor: 'documentTitle' },
  { Header: 'First name', accessor: 'firstName' },
  { Header: 'Last name', accessor: 'lastName' },
  { Header: 'ADJ case Nº', accessor: 'adjCaseNumber' },
  {
    Header: 'Submission date',
    accessor: 'lastSavedOn',
    Cell: ({ value }) => <span>{formatDate(value, 'YYYY/MM/DD HH:mm')}</span>
  }
];

const isDraft = pathSatisfies(eqBy(toLower, 'DRAFT'), ['original', 'status']);
const isCheckbox = pathEq(['target', 'type'], 'checkbox');

const enhance = compose(
  setDisplayName('DocumentSubmissionsTable'),
  setPropTypes(propTypes),
  withEFilingModal,
  withIWProfileContextConsumer(context => ({
    updateIWPContext: context.updateContext
  })),
  withEFilingContextConsumer(context => ({
    updateEFilingContext: context.updateContext
  })),
  withHandlers({
    setDocumentDraftToResume: ({ updateEFilingContext, updateIWPContext }) => draft => {
      const {
        id,
        caseId,
        fileName,
        documentType,
        caseParticipants,
        nonCaseParticipants,
        filingType,
        ...rest
      } = draft;
      updateEFilingContext(() => ({
        ...rest,
        type: filingType,
        id: caseId,
        filingId: id,
        file: fileName ? new File([], fileName) : undefined,
        documentType: documentType ? { value: documentType, label: documentType.name } : undefined,
        // Set as selected "participants" both original case participants and custom
        // non-case document participants, if applicable
        caseParticipantRoles: isNil(nonCaseParticipants)
          ? [...caseParticipants]
          : [...caseParticipants, ...nonCaseParticipants]
      }));
      updateIWPContext(() => ({
        selectedRelatedCase: { ...draft, id: caseId, filingId: id }
      }));
    }
  }),
  withProps(({ submissions }) => ({
    columns,
    data: submissions,
    defaultPageSize: 10,
    defaultSorted: [
      {
        id: 'lastSavedOn',
        desc: true
      }
    ],
    showPagination: true,
    rowsPerPageText: 'Submissions per page',
    getTrProps: ({ setDocumentDraftToResume, showEFilingModal }, row) => ({
      onClick: (e, handleOriginal) => {
        if (isDraft(row) && !isCheckbox(e)) {
          e.preventDefault();
          e.stopPropagation();
          // If a row containing a draft has been clicked,
          // resume filing wizard
          setDocumentDraftToResume(row.original);
          return showEFilingModal();
        }
        return handleOriginal && handleOriginal();
      }
    })
  })),
  selectTable
);

export default enhance(DataTable);
