import { complement, isNil } from 'ramda';

/**
 * Checks if given value if not `null` nor `undefined`. The opposite of `isNil`.
 * @function
 * @see https://ramdajs.com/docs/#isNil
 * @param {*} value The value to check
 * @returns {Boolean} `true` is `value` is not nil; `false` otherwise.
 */
const isDefined = complement(isNil);

export default isDefined;
