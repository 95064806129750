import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { prop } from 'ramda';
import { compose, pure, withHandlers, setPropTypes, setDisplayName, withProps } from 'recompose';
import Hidden from '@material-ui/core/Hidden';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import DialogIcon from '../../core/alert/dialog-icon';
import Input from '../../core/input/input';
import Button from '../../core/button/mui-button';
import Checkbox from '../../core/checkbox/checkbox';
import withEFilingWizardHandlers from '../with-e-filing-wizard-handlers';

ResultStep.propTypes = {
  onTrack: PropTypes.func,
  onDone: PropTypes.func.isRequired,
  message: PropTypes.string
};

const styles = theme => ({
  actions: {
    backgroundColor: theme.palette.blue[100],
    margin: 0
  }
});

function ResultStep({
  onTrack,
  onDone,
  classes,
  adjCaseNumber,
  firstName,
  lastName,
  documentType,
  documentTitle,
  fileName,
  includeProofOfService
}) {
  return (
    <div className="flex flex-column h-100">
      <DialogTitle>
        <DialogIcon type="success" />
        Your filing has been queued
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          It has been accepted and is scheduled for submission. You can track the status of your
          submission in the E-Filing status screen.
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <div className="flex justify-between">
          <Input label="ADJ Number" readOnly className="mr3" value={adjCaseNumber} />
          <Input label="First Name" readOnly className="mr3" value={firstName} />
          <Hidden smDown>
            <Input label="MI" readOnly className="mr3" value="-" />
          </Hidden>
          <Input label="Last Name" readOnly value={lastName} />
        </div>
        <div className="flex justify-around mb3">
          <Input
            readOnly
            className="mr3"
            label="Document Type"
            formControlProps={{
              fullWidth: true
            }}
            value={documentType === undefined ? 'No document type selected' : documentType}
          />
          <Input
            readOnly
            label="Document Title"
            formControlProps={{
              fullWidth: true
            }}
            value={documentTitle === undefined ? 'No document title specified' : documentTitle}
          />
        </div>

        <div className="flex flex-column">
          <Input
            readOnly
            label="Uploaded file"
            className="mb3"
            formControlProps={{
              fullWidth: true
            }}
            value={fileName === undefined ? 'No file selected' : fileName}
          />
          <Checkbox
            readOnly
            checked={includeProofOfService}
            disabled={!includeProofOfService}
            name="includeProofOfService"
            label={
              includeProofOfService
                ? 'Includes a Proof of Service'
                : 'Does not include a Proof of Service'
            }
          />
        </div>
      </DialogContent>
      <DialogActions className={cn(classes.actions, 'pv3')} disableActionSpacing={true}>
        {onTrack && (
          <Button className="mh2" variant="outlined" color="secondary" onClick={onTrack}>
            Track...
          </Button>
        )}
        <Button className="mh2" color="primary" onClick={onDone}>
          Done
        </Button>
      </DialogActions>
    </div>
  );
}

/**
 * Picks and returns the `label` prop from the given object `obj`.
 * If `obj` is `null`, `undefined` or does not have a `label` property, it returns
 * `undefined`
 * @param {Object} obj The object to extract the value from
 * @return {*} The value of `label`.
 */
const extractLabel = prop('label');

/**
 * Picks and returns the `name` prop from the given object `obj`.
 * If `obj` is `null`, `undefined` or does not have a `name` property, it returns
 * `undefined`
 * @param {Object} obj The object to extract the value from
 * @return {*} The value of `name`.
 */
const extractName = prop('name');

/**
 * Picks and returns the `adjCaseNumber` prop from the given object `obj`.
 * If `obj` is `null`, `undefined` or does not have a `name` property, it returns
 * `undefined`
 * @param {Object} obj The object to extract the value from
 * @return {*} The value of `adjCaseNumber`.
 */
const extractAdjCaseNumber = prop('adjCaseNumber');

/**
 * Picks and returns the `firstName` prop from the given object `obj`.
 * If `obj` is `null`, `undefined` or does not have a `name` property, it returns
 * `undefined`
 * @param {Object} obj The object to extract the value from
 * @return {*} The value of `firstName`.
 */
const extractFirstName = prop('firstName');

/**
 * Picks and returns the `lastName` prop from the given object `obj`.
 * If `obj` is `null`, `undefined` or does not have a `name` property, it returns
 * `undefined`
 * @param {Object} obj The object to extract the value from
 * @return {*} The value of `lastName`.
 */
const extractLastName = prop('lastName');

const enhance = compose(
  setDisplayName(ResultStep.name),
  withEFilingWizardHandlers,
  setPropTypes({
    eFilingContext: PropTypes.shape({
      state: PropTypes.shape({
        error: PropTypes.oneOfType([PropTypes.shape({ message: PropTypes.string }), PropTypes.bool])
      }).isRequired
    }).isRequired,
    wizardContext: PropTypes.shape({
      updateContext: PropTypes.func.isRequired
    }).isRequired
  }),
  withProps(
    ({
      wizardContext: {
        state: { currentStep, stepsCount }
      },
      eFilingContext: {
        state: {
          documentType,
          documentTitle,
          file,
          filePageCount,
          includeProofOfService,
          caseParticipantRoles
        }
      },
      IWProfileContext: {
        state: { selectedRelatedCase }
      }
    }) => {
      return {
        currentStep: currentStep + 1,
        stepsCount: stepsCount - 1,
        documentType: extractLabel(documentType),
        documentTitle,
        fileName: extractName(file),
        filePageCount,
        includeProofOfService,
        caseParticipantRoles,
        adjCaseNumber: extractAdjCaseNumber(selectedRelatedCase),
        firstName: extractFirstName(selectedRelatedCase),
        lastName: extractLastName(selectedRelatedCase)
      };
    }
  ),
  withRouter,
  withHandlers({
    onTrack: ({ history }) => () => {
      // Take users to the Filings & Submissions screen
      return history.push('/filings');
    }
  }),
  withStyles(styles),
  pure
);

export default enhance(ResultStep);
