import { useWith, concat, defaultTo } from 'ramda';

/**
 * Concatenates two arrays, defaulting to `[]` if either of them is `null` or `undefined`.
 * @function
 * @param {Array.<*>} l1 A list of items.
 * @param {Array.<*>} l2 A list of items.
 * @returns {Array.<*>} A new list containing all items in `l1` and `l2`.
 */
const safeConcat = useWith(concat, [defaultTo([]), defaultTo([])]);

export default safeConcat;
