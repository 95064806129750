import React from 'react';
import { pure, compose, withHandlers, withState } from 'recompose';
import EFilingModal from './e-filing-modal';
import { withEFilingContextProvider } from './e-filing-context';

const enhance = compose(
  withEFilingContextProvider,
  withState('modalOpen', 'setModalOpen', false),
  withHandlers({
    showEFilingModal: ({ setModalOpen }) => () => setModalOpen(true),
    hideEFilingModal: ({ setModalOpen }) => () => setModalOpen(false)
  }),
  pure
);

const withEFilingModal = BaseComponent =>
  enhance(({ modalOpen, showEFilingModal, hideEFilingModal, ...rest }) => {
    return (
      <>
        <BaseComponent showEFilingModal={showEFilingModal} {...rest} />
        <EFilingModal modalOpen={modalOpen} hideEFilingModal={hideEFilingModal} />
      </>
    );
  });

export default withEFilingModal;
