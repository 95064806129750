import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

ADJNumberFormat.propTypes = {
  /**
   * The `id` of the `input` element.
   * @type {String}
   */
  id: PropTypes.string,

  /**
   * Pass a ref callback to the native input component.
   * @type {Function}
   */
  inputRef: PropTypes.func.isRequired,

  /**
   * Callback fired when the value is changed.
   * @type {Function}
   */
  onChange: PropTypes.func.isRequired
};

function ADJNumberFormat({ id, inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      {...other}
      id={id}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            id,
            value: values.value
          }
        });
      }}
      prefix="ADJ"
    />
  );
}

export default memo(ADJNumberFormat);
