import { compose, defaultTo, find, ifElse, prop, invoker, always } from 'ramda';
import isDefined from './is-defined';

const firstNonNilOrZero = compose(
  defaultTo(0),
  find(isDefined)
);

export const getWindowHeight = () =>
  firstNonNilOrZero([
    window.innerHeight,
    document.documentElement.clientHeight,
    document.getElementsByTagName('body')[0].clientHeight
  ]);

export const getDOMElementHeight = ifElse(
  isDefined,
  compose(
    prop('height'),
    invoker(0, 'getBoundingClientRect')
  ),
  always(0)
);
