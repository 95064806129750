import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, setPropTypes, pure, withState, withHandlers } from 'recompose';
import { always } from 'ramda';
import WizardStepLayout from '../wizard-step-layout';
import withEFilingWizardHandlers from '../with-e-filing-wizard-handlers';
import Checkbox from '../../core/checkbox/checkbox';
import { EAMS_DOC_TYPE } from '../e-filing-context';

const propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired
};

function EAMSDocTypeSelectionStep({
  title,
  eamsDocType,
  selectDORType,
  selectUFType,
  onSaveDraft,
  onPreviousStep,
  nextStep,
  onCancel,
  wizardContext: {
    state: { currentStep, stepsCount }
  },
  IWProfileContext: {
    state: { selectedRelatedCase }
  },
  loading
}) {
  return (
    <WizardStepLayout
      loading={loading}
      title={title}
      showCancelButton={false}
      stepNumber={currentStep + 1}
      stepsCount={stepsCount}
      onCancel={onCancel}
      onBack={onPreviousStep}
      onNext={nextStep}
      onSaveDraft={onSaveDraft}
    >
      <div className="flex flex-column flex-grow-1">
        <div>
          <div className="f4 mb2">Select EAMS document type</div>
          <div className="mb2 light-blue-gray">
            Please click to select the document type you would like file into EAMS
          </div>
          <div className="mv4">
            <div className="f6">EAMS E-Filing Documents:</div>
            <div className="b--light-blue-gray bt br bb bl mt4 pa2">
              <Checkbox
                disabled
                checked={eamsDocType === EAMS_DOC_TYPE.DOR}
                name="eamsDocTypeDOR"
                label="DOR - Declaration of Readiness to Proceed"
                onChange={selectDORType}
              />
            </div>
            <div className="b--light-blue-gray bb bl br pa2">
              <Checkbox
                checked={eamsDocType === EAMS_DOC_TYPE.UF}
                name="eamsDocTypeDOR"
                label="UF - Unstructured Form"
                onChange={selectUFType}
              />
            </div>
          </div>
        </div>
      </div>
    </WizardStepLayout>
  );
}

const enhance = compose(
  setDisplayName(EAMSDocTypeSelectionStep.name),
  setPropTypes(propTypes),
  withState('loading', 'setLoading', false),
  withState('eamsDocType', 'setEAMSDocType', EAMS_DOC_TYPE.UF),
  withEFilingWizardHandlers,
  withHandlers({
    selectDORType: ({ setEAMSDocType }) => () => setEAMSDocType(EAMS_DOC_TYPE.DOR),
    selectUFType: ({ setEAMSDocType }) => () => setEAMSDocType(EAMS_DOC_TYPE.UF),
    onSaveDraft: ({
      saveEFilingDraft,
      isValid,
      validateForm,
      setStatus,
      values: { documentTitle, documentType, includeProofOfService, file }
    }) => async () => {
      validateForm();
      if (isValid) {
        await saveEFilingDraft({
          documentTitle,
          includeProofOfService,
          file,
          documentTypeId: documentType && documentType.value
        });
      } else {
        setStatus({ validated: true });
      }
    },
    nextStep: ({ eamsDocType, nextStep }) => () => nextStep({ eamsDocType }),
    onPreviousStep: ({
      eFilingContext: { updateContext: updateEFilingContext },
      previousStep
    }) => () => {
      // Clean the selected type so the selection step is shown
      updateEFilingContext(always({ type: null }));
    }
  }),
  pure
);

export default enhance(EAMSDocTypeSelectionStep);
