import React, { memo } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import DataTable from '../../core/table';

IWPHearingsTable.propTypes = {
  hearings: PropTypes.arrayOf(
    PropTypes.shape({
      nextHearingDateTime: PropTypes.string,
      hearingType: PropTypes.string,
      venue: PropTypes.string,
      hearingJudge: PropTypes.string,
      driverCase: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string
};

const columns = [
  { Header: 'Next Hearing', accessor: 'nextHearingDateTime' },
  { Header: 'Hearing Type', accessor: 'type' },
  { Header: 'Hearing Venue', accessor: 'venue' },
  { Header: 'Hearing Judge', accessor: 'judge' },
  { Header: 'Driver Case', accessor: 'driverCase' }
];

export function IWPHearingsTable({ hearings, loading, error, className }) {
  return (
    <DataTable
      data={hearings}
      loading={loading}
      className={className}
      columns={columns}
      theme="light"
      showPagination={false}
      minRows={5}
      noDataText={
        error
          ? 'There was an error retrieving hearings from EAMS.'
          : loading
          ? 'Searching hearings...'
          : isEmpty(hearings)
          ? 'No hearings found for selected case.'
          : ''
      }
    />
  );
}

export default memo(IWPHearingsTable);
