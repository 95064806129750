import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import pure from 'recompose/pure';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LineIcon from '../line-icon';

Modal.propTypes = {
  /**
   * Additional CSS classes
   * @type {String}
   */
  className: PropTypes.string,

  /**
   * JSS custom classes
   * @type {Object}
   */
  classes: PropTypes.object,

  /**
   * If true, the Modal is open.
   * @type {Boolean}
   */
  open: PropTypes.bool.isRequired,

  /**
   * Modal children, usually the included sub-components.
   * @type {Element}
   */
  children: PropTypes.element.isRequired,

  /**
   * The text shown on the top.
   * @type {Element}
   */
  title: PropTypes.string,

  /**
   * Modal actions. Will appear at the bottom.
   * @type {Node}
   */
  actions: PropTypes.node,

  /**
   * Determine the max width of the dialog. Set to `false` to disable `maxWidth`.
   * @type {String|Boolean}
   */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', false]),

  /**
   * If `true`, the dialog will be full-screen.
   * @type {Boolean}
   */
  fullScreen: PropTypes.bool,

  /**
   * If `true`, the dialog stretches to `maxWidth`.
   * @type {Boolean}
   */
  fullWidth: PropTypes.bool,

  /**
   * Always keep the children in the DOM.
   * @default true
   * @type {Boolean}
   */
  keepMounted: PropTypes.bool,

  /**
   * Whether to wrap `children` in `DialogContent` or not.
   * @default false
   * @type {Boolean}
   */
  disableContent: PropTypes.bool
};

Modal.defaultProps = {
  open: false,
  disableContent: false,
  keepMounted: false,
  classes: {}
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export function Modal({
  open,
  onClose,
  title,
  children,
  actions,
  classes,
  className,
  fullScreen,
  maxWidth,
  keepMounted,
  fullWidth,
  disableContent
}) {
  return (
    <Dialog
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      classes={{ paper: 'br0' }}
      open={open}
      TransitionComponent={Transition}
      keepMounted={keepMounted}
      fullWidth={fullWidth}
      onClose={onClose}
      className={cn(className, classes.root)}
      aria-labelledby="modal-dialog-title"
      aria-describedby="modal-dialog-description"
    >
      {title && (
        <DialogTitle className={classes.title} id="modal-dialog-title" disableTypography={true}>
          <div className="flex items-center justify-between">
            <Typography variant="h6">{title}</Typography>
            <IconButton onClick={onClose}>
              <LineIcon icon="close" className={classes.closeIcon} />
            </IconButton>
          </div>
        </DialogTitle>
      )}
      {disableContent ? (
        children
      ) : (
        <DialogContent className={classes.content} id="modal-dialog-description">
          {children}
        </DialogContent>
      )}
      {actions && <DialogActions className={classes.actions}>{actions}</DialogActions>}
    </Dialog>
  );
}

export default pure(Modal);
