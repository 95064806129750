import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import LineIcon from '../../components/core/line-icon';
import AppBar from '../../components/common/app-bar';
import PopperMenu, { MenuItem, MenuDivider } from '../../components/core/popper-menu';
import CompdataLogo from './compdata-logo';

const styles = theme => ({
  main: {
    flex: '1 1 0',
    minHeight: 'initial',
    overflow: 'auto'
  },
  lineIcon: {
    color: theme.palette.primary.lightText
  },
  appBarSpacer: theme.mixins.toolbar
});

function DrawerOptions({ onResetPassword, onLogout }) {
  return (
    <List>
      <ListItem button onClick={onResetPassword}>
        <ListItemText primary="Reset password" />
      </ListItem>
      <ListItem button onClick={onLogout}>
        <ListItemIcon>
          <LineIcon icon="sign-out" />
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </ListItem>
    </List>
  );
}

function DropdownMenu({ onResetPassword, onLogout, classes }) {
  return (
    <PopperMenu caret icon={<LineIcon icon="gear" className={cn(classes.lineIcon, 'f4')} />}>
      <MenuList role="menu">
        <MenuItem header>Settings</MenuItem>
        <MenuItem onClick={onResetPassword}>Reset password</MenuItem>
        <MenuDivider />
        <MenuItem onClick={onLogout}>
          <LineIcon icon="sign-out" className="pr1" />
          Logout
        </MenuItem>
      </MenuList>
    </PopperMenu>
  );
}

class AppLayout extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.element.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        path: PropTypes.string
      })
    ).isRequired
  };

  handleResetPassword = () => {
    return this.props.history.push('/password');
  };

  handleLogout = () => {
    return this.props.history.push('/logout');
  };

  render() {
    const { classes, children, links } = this.props;
    return (
      <div className="flex">
        <AppBar
          title="CompData"
          logo={<CompdataLogo className="h2 pr2" />}
          links={links}
          drawerIcon={<LineIcon icon="bars" className={classes.lineIcon} />}
          drawerOptions={
            <DrawerOptions
              onResetPassword={this.handleResetPassword}
              onLogout={this.handleLogout}
            />
          }
          endOptions={
            <>
              <Hidden xsDown>
                <DropdownMenu
                  onResetPassword={this.handleResetPassword}
                  onLogout={this.handleLogout}
                  classes={classes}
                />
              </Hidden>
              <Button className="h-100">
                <LineIcon icon="bell" className={cn(classes.lineIcon, 'f4')} />
              </Button>
            </>
          }
        />
        <main className={cn('min-vh-100', classes.main)}>
          <div className={classes.appBarSpacer} />
          <section className="black-70">{children}</section>
        </main>
      </div>
    );
  }
}

// `withRouter` here is needed to inject the location as a
// prop, thus allowing a pure component to re-render.
// See: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default compose(
  withRouter,
  withStyles(styles)
)(AppLayout);
