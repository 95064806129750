import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { compose, setPropTypes, mapProps, setDisplayName, withProps } from 'recompose';
import Alert from './alert';

const propTypes = {
  messageTitle: PropTypes.node,
  messageText: PropTypes.node,
  showMessage: PropTypes.bool.isRequired,
  messageType: PropTypes.oneOf(['success', 'error', 'warning']),
  onOkClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func
};

// Avoid passing down props specific to an Alert component to avoid overriding
// other possible Alerts props below the composed chain
const omitAlertProps = mapProps(
  omit(['showMessage', 'messageType', 'messageTitle', 'messageText', 'onOkClick'])
);

const withAlert = WrappedComponent => ({
  showMessage,
  onOkClick,
  onCancelClick,
  messageText,
  messageTitle,
  messageType,
  ...otherProps
}) => {
  return (
    <>
      <WrappedComponent {...otherProps} />
      <Alert
        title={messageTitle}
        text={messageText}
        show={showMessage}
        onOkClick={onOkClick}
        onCancelClick={onCancelClick}
        type={messageType}
      />
    </>
  );
};

export default props =>
  compose(
    setDisplayName('withAlert'),
    withProps(props),
    setPropTypes(propTypes),
    withAlert,
    omitAlertProps
  );
