import { join } from 'ramda';
import { createMuiTheme } from '@material-ui/core/styles';

const joinWithComma = join(',');

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: joinWithComma(['Poppins', 'Helvetica', 'Arial', 'sans-serif'])
  },
  palette: {
    success: {
      main: '#00e089'
    },
    warning: {
      main: '#ffae00'
    },
    error: {
      main: '#d91073'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.7)'
    },
    grey: {
      '100': '#f0f0f0',
      '200': '#bac2c9',
      '300': '#5f5f5f'
    },
    blue: {
      '100': '#edf4fa',
      '200': '#dbe7f1'
    },
    common: {
      white: '#f6f9fb'
    },
    primary: {
      light: '#5a6269',
      main: '#31383e',
      dark: '#0a1218',
      contrastText: '#ffffff',
      lightText: '#8ea7bb'
    },
    secondary: {
      light: '#61c3ff',
      main: '#0093e0',
      dark: '#0066ae',
      contrastText: '#31383e'
    }
  }
});

export default theme;
