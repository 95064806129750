import React from 'react';
import PropTypes from 'prop-types';
import { prop, splitAt, length } from 'ramda';
import { compose, setDisplayName, pure, withHandlers, withProps, setPropTypes } from 'recompose';
import Typography from '@material-ui/core/Typography';
import Input from '../../core/input/input';
import Checkbox from '../../core/checkbox/checkbox';
import withEFilingWizardHandlers from '../with-e-filing-wizard-handlers';
import withSubmitDraftMutation from '../with-submit-draft-mutation';
import WizardStepLayout from '../wizard-step-layout';
import {
  Chip,
  InputAdornment,
  IconButton,
  Card,
  CardActions,
  Button,
  CardContent
} from '@material-ui/core';
import LineIcon from '../../core/line-icon';
import isNotEmpty from '../../../utils/is-not-empty';

ReviewStep.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onPreviousStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
  documentType: PropTypes.string,
  documentTitle: PropTypes.string,
  fileName: PropTypes.string,
  includeProofOfService: PropTypes.bool
};

function EditAdornment({ onClick }) {
  return (
    <InputAdornment className="h-100 mb1 mb0-ns" position="end">
      <IconButton aria-label="Edit" onClick={onClick}>
        <LineIcon icon="pencil" />
      </IconButton>
    </InputAdornment>
  );
}

function ReviewStep({
  title,
  loading,
  onPreviousStep,
  onCancel,
  onNextStep,
  onEditGeneralInfo,
  onEditCaseParticipants,
  currentStep,
  stepsCount,
  stepError,
  documentType,
  documentTitle,
  fileName,
  filePageCount,
  includeProofOfService,
  caseParticipantRoles
}) {
  const [displayableCaseParticipantRoles, hiddenCaseParticipantRoles] = splitAt(
    3,
    caseParticipantRoles
  );
  return (
    <WizardStepLayout
      loading={loading}
      title={title}
      stepNumber={currentStep}
      stepsCount={stepsCount}
      showCancelButton={false}
      onCancel={onCancel}
      onBack={onPreviousStep}
      onNext={onNextStep}
      error={stepError}
      defaultActionText="Submit"
    >
      <div className="flex flex-column flex-grow-1">
        <div className="mb4">
          <Typography variant="subtitle1">Final review</Typography>
          <Typography variant="body2" color="textSecondary">
            Please review before submitting into EAMS. Once submitted, the filing cannot be undone.
          </Typography>
        </div>

        <div className="flex flex-column flex-row-ns justify-around mb2">
          <Input
            readOnly
            className="mr3"
            label="Document Type"
            formControlProps={{
              fullWidth: true
            }}
            endAdornment={<EditAdornment onClick={onEditGeneralInfo} />}
            value={documentType === undefined ? 'No document type selected' : documentType}
          />
          <Input
            readOnly
            label="Document Title"
            formControlProps={{
              fullWidth: true
            }}
            endAdornment={<EditAdornment onClick={onEditGeneralInfo} />}
            value={documentTitle === undefined ? 'No document title specified' : documentTitle}
          />
        </div>
        <Input
          readOnly
          label="Uploaded file"
          className="mb3"
          formControlProps={{
            fullWidth: true
          }}
          endAdornment={<EditAdornment onClick={onEditGeneralInfo} />}
          value={fileName === undefined ? 'No file selected' : fileName}
        />

        <Card square={true} className="flex flex-column mb3">
          <CardContent>
            <Typography variant="caption" className="mb1">
              Mail recipients
            </Typography>
            {displayableCaseParticipantRoles.map(data => {
              return <Chip className="ma1" key={data.id} label={data.name} color="primary" />;
            })}
          </CardContent>
          <CardActions className="mb2 mb0-ns flex justify-end">
            {isNotEmpty(hiddenCaseParticipantRoles) && (
              <Typography color="textSecondary" className="mr-auto ml3">
                +{length(hiddenCaseParticipantRoles)} more
              </Typography>
            )}
            <Button size="small" color="secondary" onClick={onEditCaseParticipants}>
              Add or remove
            </Button>
          </CardActions>
        </Card>

        <Checkbox
          readOnly
          checked={includeProofOfService}
          disabled={!includeProofOfService}
          name="includeProofOfService"
          label={
            includeProofOfService
              ? 'Includes a Proof of Service'
              : 'Does not include a Proof of Service'
          }
        />
      </div>
    </WizardStepLayout>
  );
}

/**
 * Picks and returns the `label` prop from the given object `obj`.
 * If `obj` is `null`, `undefined` or does not have a `label` property, it returns
 * `undefined`
 * @param {Object} obj The object to extract the value from
 * @return {*} The value of `label`.
 */
const extractLabel = prop('label');

/**
 * Picks and returns the `name` prop from the given object `obj`.
 * If `obj` is `null`, `undefined` or does not have a `name` property, it returns
 * `undefined`
 * @param {Object} obj The object to extract the value from
 * @return {*} The value of `name`.
 */
const extractName = prop('name');

export default compose(
  setDisplayName(ReviewStep.name),
  withEFilingWizardHandlers,
  withSubmitDraftMutation,
  setPropTypes({
    submitDraft: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    eFilingContext: PropTypes.shape({
      state: PropTypes.shape({
        filingId: PropTypes.string,
        documentType: PropTypes.shape({
          label: PropTypes.string.isRequired
        }),
        documentTitle: PropTypes.string,
        file: PropTypes.shape({
          name: PropTypes.string.isRequired
        }),
        includeProofOfService: PropTypes.bool,
        caseParticipantRoles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired
          })
        )
      }).isRequired
    }).isRequired,
    wizardContext: PropTypes.shape({
      updateContext: PropTypes.func.isRequired
    }).isRequired
  }),
  withProps(
    ({
      wizardContext: {
        state: { currentStep, stepsCount, loading, error }
      },
      eFilingContext: {
        state: {
          documentType,
          documentTitle,
          file,
          filePageCount,
          includeProofOfService,
          caseParticipantRoles
        }
      }
    }) => {
      return {
        currentStep: currentStep + 1,
        stepsCount: stepsCount - 1,
        documentType: extractLabel(documentType),
        documentTitle,
        fileName: extractName(file),
        filePageCount,
        includeProofOfService,
        caseParticipantRoles,
        loading,
        stepError: error
      };
    }
  ),
  withHandlers({
    onEditGeneralInfo: ({ wizardContext: { updateContext: updateWizardContext } }) => () => {
      // Go back to general info form (first step, zero based index)
      return updateWizardContext(({ currentStep }) => ({
        currentStep: 0
      }));
    },
    onEditCaseParticipants: ({ wizardContext: { updateContext: updateWizardContext } }) => () => {
      // Go back to case participants table (second step, zero based index)
      return updateWizardContext(({ currentStep }) => ({
        currentStep: 1
      }));
    },
    onNextStep: ({
      submitDraft,
      eFilingContext: {
        state: { filingId }
      },
      wizardContext: { updateContext }
    }) => async () => {
      try {
        updateContext(() => ({ loading: true }));
        // Submit the draft and update context based on result
        // We only care about the error since the mutation does not
        // return anything that should be displayed in next step
        await submitDraft(filingId);
        updateContext(() => ({ loading: false, error: false }));
      } catch (err) {
        updateContext(() => ({ loading: false, error: err }));
      } finally {
        updateContext(() => ({ loading: false }));
      }

      // Go to next (and final) step to display a success/failure message based on
      // result of above submission. The next step will read the `error` state prop
      // just set
      return updateContext(({ currentStep }) => ({
        currentStep: currentStep + 1
      }));
    },
    onPreviousStep: ({ wizardContext: { updateContext: updateWizardContext } }) => () => {
      // Go back to previous step, without saving draft as the current review
      // step does not allow users to edit or add any information to the filing
      return updateWizardContext(({ currentStep }) => ({
        currentStep: currentStep - 1
      }));
    }
  }),
  pure
)(ReviewStep);
