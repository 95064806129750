import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import cn from 'classnames';

LineIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xs', 'lg', 's', 'm', 'l', 'xl']),
  className: PropTypes.string,
  innerRef: PropTypes.func
};

export function LineIcon({ icon, className, innerRef, size, ...rest }) {
  return (
    <i
      ref={innerRef}
      className={cn(
        `la la-${icon}`,
        {
          'la-lg': size === 'lg',
          'la-2x': size === 's',
          'la-3x': size === 'm',
          'la-4x': size === 'l',
          'la-5x': size === 'xl'
        },
        className
      )}
      {...rest}
    />
  );
}

export default pure(LineIcon);
