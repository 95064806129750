/**
 * Returns a function that throws any error intance of `ErrorInstance`
 * when invoked.
 * @param {Function} Error The error class to instantiate
 * @param {String} message The error message
 * @param {Object} data Additional properties to attach to the thrown error
 */
function throwWith(message, data) {
  throw Object.assign(new Error(message), data);
}

export default throwWith;
