import createContext from '../../core/context/create-context';

const defaultState = {};

const { withContextProvider, withContextConsumer, Provider, Consumer } = createContext(
  defaultState
);

export { withContextConsumer as withFiltersContextConsumer };
export { withContextProvider as withFiltersContextProvider };
export { Provider as FiltersProvider };
export { Consumer as FiltersConsumer };
