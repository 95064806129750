import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { withStyles, InputLabel, Input } from '@material-ui/core';

const styles = theme => ({
  cssLabel: {
    '&$cssFocused': {
      color: theme.palette.secondary.main
    }
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: theme.palette.secondary.main
    }
  }
});

FormControlInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  /**
   * If `true`, the input will be disabled.
   * @type {Boolean}
   */
  disabled: PropTypes.bool,
  classes: PropTypes.object
};

function FormControlInput({
  id,
  label,
  labelProps,
  formControlProps,
  error,
  className,
  classes,
  disabled,
  ...rest
}) {
  return (
    <FormControl
      {...formControlProps}
      error={Boolean(error)}
      className={className}
      disabled={disabled}
    >
      {label === undefined ? null : (
        <InputLabel
          FormLabelClasses={{
            root: classes.cssLabel,
            focused: error ? '' : classes.cssFocused
          }}
          htmlFor={id}
          {...labelProps}
        >
          {label}
        </InputLabel>
      )}
      <Input
        id={id}
        classes={{
          underline: classes.cssUnderline
        }}
        inputProps={{
          'aria-label': label
        }}
        {...rest}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}

export default memo(withStyles(styles)(FormControlInput));
