import { complement, isEmpty } from 'ramda';

/**
 * Checks if the given `value` is not empty.
 * The complement of ramda#isEmpty
 *
 * @function
 * @see https://ramdajs.com/docs/#isEmpty
 * @param {*} value The value to check
 * @returns {Boolean} `true` if the given value is not its type's empty value;
 *  `false` otherwise.
 */
const isNotEmpty = complement(isEmpty);

export default isNotEmpty;
