import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, setPropTypes, setDisplayName } from 'recompose';
import { Wizard } from '../../core/wizard/wizard';
import GeneralInfoStep from './general-info-step';
import CaseParticipantRolesSelectionStep from './case-participant-roles-selection-step';
import ReviewStep from './review-step';
import ResultStep from './result-step';
import { withIWProfileContextConsumer } from '../../injured-worker-profile/iwp-context';

const propTypes = {
  hideEFilingModal: PropTypes.func.isRequired
};

function DTMWizard({ hideEFilingModal, selectedRelatedCase }) {
  return (
    <Wizard onFinished={hideEFilingModal}>
      <GeneralInfoStep
        title={`Direct Mailing - ${selectedRelatedCase && selectedRelatedCase.adjCaseNumber}`}
        onCancel={hideEFilingModal}
      />
      <CaseParticipantRolesSelectionStep
        title={`Direct Mailing - ${selectedRelatedCase && selectedRelatedCase.adjCaseNumber}`}
        onCancel={hideEFilingModal}
      />
      <ReviewStep
        title={`Mailing Review - ${selectedRelatedCase && selectedRelatedCase.adjCaseNumber}`}
        defaultActionText="Submit"
        onCancel={hideEFilingModal}
      />
      <ResultStep onDone={hideEFilingModal} />
    </Wizard>
  );
}

const enhance = compose(
  setDisplayName('DTMWizard'),
  setPropTypes(propTypes),
  withIWProfileContextConsumer(context => ({ IWProfileContext: context })),
  withProps(({ IWProfileContext: { state: { selectedRelatedCase } } }) => ({
    selectedRelatedCase
  }))
);

export default enhance(DTMWizard);
