import { unless, anyPass, match, isEmpty, complement, is, concat, compose } from 'ramda';

/**
 * Checks whether the given `value` is not a String.
 *
 * @function
 * @param {String} value The value to check.
 * @returns {Boolean} `true` is if it's not a String; `false` otherwise.
 */
const isNotString = complement(is(String));

/**
 * Checks whether the given `value` matches `re` or not.
 * @param {RegExp} re Regular expression to check
 */
const matches = re =>
  compose(
    complement(isEmpty),
    match(re)
  );

/**
 * Prefixes a string with 'ADJ' if it doesn't already begin
 * with it (case insensitive). If will return the given value
 * if it's not a string or if it's an empty string (`''`).
 *
 * @function
 * @param {*} value The value to prefix
 * @returns {*} The value prefixed with 'ADJ' or itself if it
 *  wasn't a string or if it was an empty string.
 */
const prefixWithADJ = unless(anyPass([isNotString, isEmpty, matches(/^adj/i)]), concat('ADJ'));

export default prefixWithADJ;
